import { useCallback } from 'react'
import {
  Hashtag_Insert_Input,
  ListPostsDocument,
  Post,
  Post_Insert_Input,
  useCreatePostMutation,
} from '../../generated/graphql'
import { toast } from 'react-toastify'
import { ApolloError, LazyQueryHookOptions } from '@apollo/client'

export function useCreatePost(updateQueryOptions?: LazyQueryHookOptions) {
  const [createPostMutation] = useCreatePostMutation({
    update: (cache, { data }) => {
      if (!data?.insert_post_one?.reply_to_id || !updateQueryOptions) {
        return
      }

      const existingReplies = cache.readQuery({
        query: ListPostsDocument,
        variables: { ...updateQueryOptions.variables },
      }) as { post: Post[] }

      // Update reply list cache
      cache.writeQuery({
        query: ListPostsDocument,
        variables: { ...updateQueryOptions.variables },
        data: {
          post: [data.insert_post_one, ...existingReplies.post],
        },
      })

      //Update reply count cache
      cache.modify({
        id: cache.identify({ __typename: 'post', id: data.insert_post_one.reply_to_id }),
        fields: {
          replies_aggregate(existingAggregate = { aggregate: { count: 0 } }) {
            return {
              ...existingAggregate,
              aggregate: {
                ...existingAggregate.aggregate,
                count: existingAggregate.aggregate.count + 1,
              },
            }
          },
        },
      })
    },
  })

  return useCallback(
    (post: Post_Insert_Input, hashtags: Hashtag_Insert_Input[]) => {
      return createPostMutation({
        variables: { object: post, hashtagObjects: hashtags },
      })
        .then(({ data }) => {
          if (data?.insert_post_one?.id) {
            // TODO: redirect to post page
            toast.success('Post created successfully!')
            return data.insert_post_one.id as string
          }
        })
        .catch((error: ApolloError) => {
          error.graphQLErrors.some(e => {
            switch (e.extensions?.code) {
              case 'constraint-violation':
                if (e.message.includes('hashtaged_posts_pkey')) {
                  toast.error('Duplicate hashtag. Please only use each hashtag once.')
                } else {
                  toast.error('Failed to create post. Please try again.')
                }
                break
              default:
                toast.error('Failed to create post. Please try again.')
            }
          })

          console.log(error)
          return undefined
        })
    },
    [createPostMutation]
  )
}
