import { FirebaseError } from 'firebase/app'

// TODO: Handle more error codes: https://firebase.google.com/docs/reference/js/auth#autherrorcodes
export function handleFirebaseError(e: FirebaseError) {
  let errorCode = e.code

  // For firebase functions errors, the message is more descriptive
  if (e.code.startsWith('functions/')) {
    errorCode = e.message
  }

  switch (errorCode) {
    case 'auth/invalid-action-code':
      return 'Invalid link. Please request a new one and try again.'
    case 'auth/invalid-login-credentials':
      return 'Invalid login credentials.'
    case 'auth/too-many-requests':
      return 'This account has been temporarily disabled due to many failed login attempts. You can restore it by resetting your password or you can try again later.'
    case 'auth/null-user':
      return 'User not found.'
    case 'auth/email-already-exists':
      return 'Email already in use.'
    case 'auth/username-already-exists':
      return 'Username already in use.'
    case 'internal':
    default:
      return "Couldn't complete request. Please try again later."
  }
}
