import { debounce } from 'lodash'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop'
import { useCardinalContext } from '../../../context/cardinal'
import { ActionType, EditorDispatchContext, EditorStateContext } from '../../../context/editorContext'
import { cropPreview } from '../../../utils/cropImagePreview'
import { ProfileAvatar } from '../display/profileAvatar'
import './styles/avatarEditor.css'
import { Button } from '../../uiKit/button'

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

export function AvatarEditor() {
  const { isMobileView, user } = useCardinalContext()
  const state = useContext(EditorStateContext)
  const dispatch = useContext(EditorDispatchContext)

  const [imgSrc, setImgSrc] = useState('')
  const [imageBlob, setImageBlob] = useState<File>()
  const previewCanvasRef = useRef<HTMLCanvasElement | undefined>(undefined)
  const imgRef = useRef<HTMLImageElement>(null)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState<number | undefined>(1 / 1)

  useEffect(() => {
    changePreview()
  }, [completedCrop, scale, rotate])

  useEffect(() => {
    dispatch({
      type: ActionType.updateAvatarBlob,
      payload: imageBlob,
    })
  }, [imageBlob])

  const changePreview = useCallback(
    debounce(
      () => {
        if (completedCrop?.width && completedCrop?.height && imgRef.current) {
          cropPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate).then(newImage => {
            dispatch({
              type: ActionType.setAvatar,
              payload: newImage.previewUrl,
            })
            dispatch({
              type: ActionType.updateAvatarBlob,
              payload: newImage.newBlob,
            })
          })
        }
      },
      50,
      { trailing: true }
    ),
    [completedCrop, scale, rotate]
  )

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined)
      setImageBlob(e.target.files[0])
      const reader = new FileReader()
      reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''))
      reader.readAsDataURL(e.target.files[0])
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  let frames = [
    'https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/322330/beaee5e90d93bfafa5f5f55acb23abfd28ad180c.png',
    'https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1065970/84cbb511c3c7522147f04ee9c3516c6c9cdf06ef.png',
    'https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/2022180/e908117e85663de8404b9ea6d38bde29ce522ec3.png',
    'https://cdn.cloudflare.steamstatic.com/steamcommunity/public/images/items/322330/441c842d0a004472197b448c5dc26e4ab43afe37.png',
    'https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1732740/3fd73db5d33e9b6597e6975eb654e89b89b5db5c.png',
  ]

  return (
    <>
      <h1 className="Tab-Header-Mobile">AVATAR</h1>
      <div className="Avatar-Editor-Container">
        <div className="Avatar-Editor-Flex">
          {state.avatar !== undefined && (
            <div className="Avatar-Preview-Container">
              <div className="Avatar-Preview-Item">
                <ProfileAvatar
                  disableMiniProfile
                  disableLink
                  size="Large"
                  user={undefined}
                  staticUser={{ profileImage: state.avatar, streamProvider: 'Seiso', profileFrame: state.avatarFrame }}
                  profileFrame
                />
              </div>
              <div className="Avatar-Preview-Item">
                <ProfileAvatar
                  disableMiniProfile
                  disableLink
                  size="Medium"
                  user={undefined}
                  staticUser={{ profileImage: state.avatar, streamProvider: 'Twitch', profileFrame: state.avatarFrame }}
                  profileFrame
                />
              </div>
              <div className="Avatar-Preview-Item">
                <ProfileAvatar
                  disableMiniProfile
                  disableLink
                  user={undefined}
                  staticUser={{
                    profileImage: state.avatar,
                    streamProvider: 'Youtube',
                    profileFrame: state.avatarFrame,
                  }}
                  profileFrame
                />
              </div>
            </div>
          )}
          <div className="Upload-Avatar-Info">
            <h1>AVATAR</h1>
            {!isMobileView && <h2>Choose a avatar (or upload a new one)</h2>}
            <div className="Upload-Avatar-Button">
              <Button onClick={() => document.getElementById('Avatar-Upload-Input')?.click()}>
                <span>Upload an image</span>
              </Button>

              <input id="Avatar-Upload-Input" type="file" accept="image/*" onChange={onSelectFile} hidden />
            </div>
          </div>
        </div>

        {Boolean(imgSrc) && (
          <div className="Avatar-Editor-Crop-Image-Container">
            <div className="Avatar-Editor-Crop-Image-Inner-Container">
              <ReactCrop
                ruleOfThirds
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={c => setCompletedCrop(c)}
                aspect={aspect}>
                <img
                  className="Avatar-Editor-Crop-Image-Preview"
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            </div>
          </div>
        )}

        {user?.profile_media?.past_avatars && (
          <div className="Past-Avatar-Container">
            <h2>Your Past Avatars</h2>
            <div className="Past-Avatar-Container-Inner">
              {[...user?.profile_media?.past_avatars].reverse().map((item, key) => (
                <div className="Past-Avatar-Item" key={key}>
                  <div
                    className={`Avatar Past-Avatar-Preview`}
                    onClick={() => {
                      if (item) {
                        dispatch({
                          type: ActionType.setAvatar,
                          payload: item,
                        })
                      }
                    }}>
                    <div
                      className="Avatar-Image Past-Avatar-Preview-Image"
                      style={{
                        backgroundImage: `url(${item})`,
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {frames && (
          <div className="Past-Avatar-Container">
            <h2>Avatar Frames</h2>
            <div className="Past-Avatar-Container-Inner">
              {frames.map((item, key) => (
                <div className="Past-Avatar-Item" key={key}>
                  <div
                    className={`Avatar Past-Avatar-Preview`}
                    onClick={() => {
                      if (item) {
                        dispatch({
                          type: ActionType.setAvatarFrame,
                          payload: item,
                        })
                      }
                    }}>
                    <div
                      className="Avatar-Image Past-Avatar-Preview-Image"
                      style={{
                        backgroundImage: `url(${item})`,
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  )
}
