import { useState } from 'react'
import { useEmojiStyleConfig, useGetEmojiUrlConfig, usePreviewConfig } from '../../config/useConfig'
import { emojiByUnified, emojiName, emojiUnified } from '../../dataUtils/emojiSelectors'
import { useEmojiPreviewEvents } from '../../hooks/useEmojiPreviewEvents'
import { useIsSkinToneInPreview } from '../../hooks/useShouldShowSkinTonePicker'
import Flex from '../Layout/Flex'
import Space from '../Layout/Space'
import { useEmojiVariationPickerState } from '../context/PickerContext'
import { ViewOnlyEmoji, ViewOnlyCustomEmoji } from '../emoji/Emoji'
import './Preview.css'
import { SkinTonePickerMenu } from '../header/SkinTonePicker'
import { useCardinalContext } from '../../../../context/cardinal'

export function Preview() {
  const previewConfig = usePreviewConfig()
  const isSkinToneInPreview = useIsSkinToneInPreview()

  if (!previewConfig.showPreview) {
    return null
  }

  return (
    <Flex className="epr-preview">
      <PreviewBody />
      <Space />
      {isSkinToneInPreview ? <SkinTonePickerMenu /> : null}
    </Flex>
  )
}

export function PreviewBody() {
  const { customEmoteCategories } = useCardinalContext()
  const previewConfig = usePreviewConfig()
  const [previewEmoji, setPreviewEmoji] = useState<PreviewEmoji>(null)
  const emojiStyle = useEmojiStyleConfig()
  const [variationPickerEmoji] = useEmojiVariationPickerState()
  const getEmojiUrl = useGetEmojiUrlConfig()

  useEmojiPreviewEvents(previewConfig.showPreview, setPreviewEmoji)

  const emoji = emojiByUnified(previewEmoji?.originalUnified)
  const customCategory = customEmoteCategories.find(item =>
    item.images.find(item => item.name === previewEmoji?.originalUnified)
  )
  const customEmoji = customCategory?.images.find(item => item?.name === previewEmoji?.originalUnified)
  const show = (emoji != null || customEmoji != null) && previewEmoji != null

  return <PreviewContent />

  function PreviewContent() {
    const defaultEmoji = variationPickerEmoji ?? emojiByUnified(previewConfig.defaultEmoji)
    if (!defaultEmoji) {
      return null
    }
    const defaultText = variationPickerEmoji ? emojiName(variationPickerEmoji) : previewConfig.defaultCaption

    return (
      <>
        <div>
          {show ? (
            emoji ? (
              <ViewOnlyEmoji
                unified={previewEmoji?.unified as string}
                emoji={emoji}
                emojiStyle={emojiStyle}
                size={45}
                getEmojiUrl={getEmojiUrl}
              />
            ) : (
              customEmoji && <ViewOnlyCustomEmoji emoji={customEmoji} size={45} />
            )
          ) : defaultEmoji ? (
            <ViewOnlyEmoji
              unified={emojiUnified(defaultEmoji)}
              emoji={defaultEmoji}
              emojiStyle={emojiStyle}
              size={45}
              getEmojiUrl={getEmojiUrl}
            />
          ) : null}
        </div>
        {show ? (
          <div className="epr-preview-emoji-label">
            :
            {emoji
              ? emojiName(emoji).toLocaleLowerCase().replaceAll(' ', '_')
              : customEmoji?.name.toLocaleLowerCase().replaceAll(' ', '_')}
            :
          </div>
        ) : (
          <div className="epr-preview-emoji-label">{defaultText}</div>
        )}
      </>
    )
  }
}

export type PreviewEmoji = null | {
  unified: string
  originalUnified: string
}
