import BookmarkIcon from '@mui/icons-material/Bookmark'
import BookmarkBorderRoundedIcon from '@mui/icons-material/BookmarkBorderRounded'
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove'
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded'
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded'
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded'
import HeartBrokenIcon from '@mui/icons-material/HeartBroken'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined'
import RepeatIcon from '@mui/icons-material/Repeat'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import moment from 'moment'
import { useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Twemoji from 'react-twemoji'
import sanitizeHTML from 'sanitize-html'
import { PathNames } from '../../constants/pathNames'
import { useCardinalContext } from '../../context/cardinal'
import {
  Post,
  User,
  useCreateBookmarkMutation,
  useCreatePostVoteMutation,
  useDeleteBookmarkMutation,
  useDeletePostMutation,
  useDeletePostVoteMutation,
} from '../../gql/generated/graphql'
import {
  deleteBookmarkOptimisticResponse,
  deletePostVoteOptimisticResponse,
  insertBookmarkOptimisticResponse,
  insertPostVoteOptimisticResponse,
} from '../../gql/optimisticResponses'
import { ProfileAvatar } from '../profile/display/profileAvatar'
import { FeedType } from './feed'
import { PostMenu } from './modal/postMenu'
import { RepostMenu } from './modal/repostMenu'
import { CustomVideoElement } from '../../utils/customVideoElement'
import { sanitizeHtmlConf } from '../../utils/constants'
import './styles/post.css'
import { VerifiedCheck } from '../profile/display/verifiedCheck'
import { QuoteRepostItem } from './utils/quoteRepostItem'
import { SeisoCensor, SeisoMatcher } from '../../utils/profanityFilter/profanityFilter'
import { useScrollRestoration } from '../../context/scrollRestorationContext'
import { NavLink } from '../../navigation/navLink'
import { Image } from '../uiKit/image'
import { Video } from '../uiKit/video'
import { checkMediaType, tags } from '../../utils/converterUtils'
import { useApolloClient } from '@apollo/client'

interface FeedItemProps {
  post: Post
  focused?: boolean
}

export function FeedItem({ post, focused }: FeedItemProps) {
  const location = useLocation()
  const postTextRef = useRef<HTMLDivElement>(null)
  const { user } = useCardinalContext()
  const { navigateToPage } = useScrollRestoration()
  const [postMenuOpen, setPostMenuOpen] = useState<boolean>(false)
  const [repostMenuOpen, setRepostMenuOpen] = useState<boolean>(false)
  const [disableContentWarning, setDisableContentWarning] = useState<boolean>(false)
  const [videoControlOverride, setVideoControlOverride] = useState<boolean>(false)
  const client = useApolloClient()

  const matches = SeisoMatcher.getAllMatches(post.text ?? '')

  moment.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s',
      s: '%ds',
      m: '%dm',
      mm: '%dm',
      h: '%dh',
      hh: '%dh',
      d: '%dd',
      dd: '%dd',
    },
  })

  const voted = post.votes.length > 0 && post.votes[0]?.owner_handle === user?.handle
  const voteCount = post.votes_aggregate.aggregate?.count ?? 0
  const shareCount =
    (post.reposts_aggregate?.aggregate?.count ?? 0) + (post.quote_reposts_aggregate?.aggregate?.count ?? 0)
  const replyCount = post.replies_aggregate?.aggregate?.count ?? 0
  const bookmarked = post.bookmarked_posts.length > 0 && post.bookmarked_posts[0]?.owner_handle === user?.handle
  const sharedBy = post?.repost_post ? (post.owner as User) : undefined
  const replyToHandle = post.reply_to_post?.owner_handle
  const quoteRepostData = post.quote_repost_post as Post

  const [deletePostMutation] = useDeletePostMutation()
  const [createBookmarkMutation] = useCreateBookmarkMutation({
    variables: { post_id: post.id, owner_handle: user?.handle ?? '' },
    optimisticResponse: insertBookmarkOptimisticResponse(post.id, user?.handle ?? ''),
  })
  const [deleteBookmarkMutation] = useDeleteBookmarkMutation({
    variables: { post_id: post.id, owner_handle: user?.handle ?? '' },
    optimisticResponse: deleteBookmarkOptimisticResponse(post.id, user?.handle ?? ''),
  })
  const [createPostVoteMutation] = useCreatePostVoteMutation({
    variables: { post_id: post.id, owner_handle: user?.handle ?? '' },
    optimisticResponse: insertPostVoteOptimisticResponse(post.id, user?.handle ?? '', voteCount ?? 0),
  })
  const [deletePostVoteMutation] = useDeletePostVoteMutation({
    variables: { post_id: post.id, owner_handle: user?.handle ?? '' },
    optimisticResponse: deletePostVoteOptimisticResponse(post.id, voteCount ?? 0),
  })

  function handleVoteOptionClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.stopPropagation()
    if (user) {
      if (!voted) {
        createPostVoteMutation().catch(reason => {
          console.log(reason)
        })
      } else {
        deletePostVoteMutation().catch(reason => {
          console.log(reason)
        })
      }
    } else {
      navigateToPage(PathNames.Authentication)
    }
  }

  function handleBookmarkOptionClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.stopPropagation()
    if (user) {
      if (!bookmarked) {
        createBookmarkMutation().catch(reason => {
          console.log(reason)
        })
      } else {
        deleteBookmarkMutation().catch(reason => {
          console.log(reason)
        })
      }
    } else {
      navigateToPage(PathNames.Authentication)
    }
  }

  function handleRepostOptionClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.stopPropagation()
    if (user) {
      setRepostMenuOpen(true)
    } else {
      navigateToPage(PathNames.Authentication)
    }
  }

  async function handleDeletePost(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation()

    deletePostMutation({ variables: { post_id: post.id } })
      .then(() => {
        client.cache.evict({ id: client.cache.identify({ __typename: post.__typename, id: post.id }) })
        client.cache.gc()
      })
      .catch(reason => {
        console.log(reason)
      })
  }

  // useEffect(() => {
  //   const rects = postTextRef.current?.children[0]?.getClientRects()
  //   //console.log(postTextRef.current?.children[0]?.getClientRects())
  //   console.log(postTextRef.current?.children[0])
  //   //console.log(rects, rects?.length)

  //   if (rects) {
  //     const numberOfLines = (() => {
  //       let lines = 0
  //       for (let i = 0; i < rects.length; i++) {
  //         if (rects[i - 1]) {
  //           if (rects[i - 1].y !== rects[i].y) {
  //             lines++
  //           }
  //         } else {
  //           lines++
  //         }
  //       }
  //       return lines
  //     })()
  //     if (numberOfLines > 1) {
  //       console.log(numberOfLines)
  //     }
  //   }
  // }, [])

  function getHandleFromMention(mentionId: string) {
    return post.post_mentions.find(mention => mention.id === mentionId)?.mentioned_handle
  }

  return (
    <>
      <div className={`Post-Container ${focused ? 'Focused-Post-Container' : ''}`}>
        <div
          className="Post-Container-Inner"
          onClick={(e: any) => {
            e.stopPropagation()
            e.preventDefault()
            let sel = window.getSelection()
            if (sel?.type !== 'Range') {
              if (e.target instanceof HTMLAnchorElement) {
                if (e.target.className === 'Post-Mention') {
                  navigateToPage(`/profile/${getHandleFromMention(e.target.getAttribute('data-seiso-miniprofile'))}`)
                } else if (e.target.className === 'Post-Hashtag') {
                  navigateToPage(`/hashtag/${e.target.innerText.replace('#', '')}`)
                }
              } else if (e.target.parentElement instanceof HTMLAnchorElement) {
                window.open(e.target.parentElement.href, '_blank')
              } else if (!focused) {
                navigateToPage(`/post/${post.owner?.handle}/${post.id}`)
              }
            }
          }}>
          {sharedBy && (
            <NavLink
              data-seiso-miniprofile={`${sharedBy.handle}`}
              to={`/profile/${sharedBy.handle}`}
              className="Post-Shared-By">
              <RepeatIcon style={{ transform: 'rotate(90deg)' }} />
              <Twemoji options={{ className: 'Post-Shared-By-Emoji' }}>
                {user?.handle === sharedBy.handle ? `Shared By You` : `Shared By ${sharedBy.username}`}
              </Twemoji>
            </NavLink>
          )}
          <div className="Post-Body">
            <div className="Post-Left">
              <ProfileAvatar user={post.owner} profileFrame />
            </div>
            <div className="Post-Right">
              <div className="Post-Header">
                <div className="Post-Info">
                  <NavLink to={`/profile/${post.owner?.handle}`} className="Post-User-Info">
                    <span className="Post-Displayname" data-seiso-miniprofile={post.owner?.handle}>
                      <Twemoji options={{ className: 'Post-Displayname-Emoji' }}>{post.owner?.username}</Twemoji>
                      {post.owner?.is_verified && <VerifiedCheck />}
                    </span>

                    <span data-seiso-miniprofile={post.owner?.handle} className="Post-Username">
                      @{post.owner?.handle}
                    </span>
                  </NavLink>
                </div>
                <div className="Post-Timestamp">
                  <time dateTime={`${post.created_at}`}>
                    {moment().diff(moment(post.created_at), 'hours') >= 23
                      ? moment(post.created_at).format('MMM Do')
                      : moment(post.created_at).fromNow()}
                  </time>
                </div>
                {user && (
                  <>
                    <button
                      className="Post-Menu-Button"
                      onClick={e => {
                        e.stopPropagation()
                        setPostMenuOpen(true)
                      }}>
                      <MoreHorizOutlinedIcon />
                    </button>
                    {postMenuOpen && (
                      <PostMenu
                        deletePostFunction={handleDeletePost}
                        postOwner={post.owner?.handle ?? ''}
                        user={user}
                        postId={post.id}
                        closeMenuFunction={() => setPostMenuOpen(false)}
                      />
                    )}
                  </>
                )}
              </div>
              {replyToHandle && (
                <div className="Reply-Indicator">
                  Replying to{' '}
                  <NavLink data-seiso-miniprofile={replyToHandle} to={`/profile/${replyToHandle}`}>
                    @{`${replyToHandle}`}
                  </NavLink>
                </div>
              )}
              <div className={`Post-Content`}>
                <div className="Post-Text">
                  <Twemoji options={{ className: 'Post-Emoji' }}>
                    {/* <div
                      ref={postTextRef}
                      dangerouslySetInnerHTML={{
                        __html: text ? sanitizeHTML(SeisoCensor.applyTo(text, matches), sanitizeHtmlConf) : '',
                      }}
                    /> */}
                    <div
                      ref={postTextRef}
                      dangerouslySetInnerHTML={{
                        __html: post.text ? sanitizeHTML(post.text, sanitizeHtmlConf) : '',
                      }}
                    />
                  </Twemoji>
                </div>
                {((post.image_urls && post.image_urls.length > 0) || post.video_url || post.gif_url) && (
                  <div className="Post-Non-Text">
                    {post.image_urls && post.image_urls?.length > 0 && (
                      <>
                        {post.image_urls.length === 1 ? (
                          post.image_urls?.map((image, key) => (
                            <div className="Post-Image-Single" key={key}>
                              {post.mature_content && !disableContentWarning && (
                                <div className="Post-Content-Content-Warning">
                                  <div>
                                    <VisibilityOffIcon style={{ fontSize: '5rem' }} />
                                    <h1>
                                      Content Warning: The author has marked this post as containing sensitive content.
                                    </h1>
                                    <button
                                      className="Post-Content-Content-Warning-Show-Button"
                                      onClick={e => {
                                        e.stopPropagation()
                                        setDisableContentWarning(true)
                                      }}>
                                      Show
                                    </button>
                                  </div>
                                </div>
                              )}
                              <NavLink
                                to={`/post/${post.owner?.handle}/${post.id}/image/1`}
                                state={{ background: location }}>
                                <Image className="Post-Image" mediaUrl={image} />
                              </NavLink>
                            </div>
                          ))
                        ) : (
                          <div className="Post-Images-Grid">
                            {post.mature_content && !disableContentWarning && (
                              <div className="Post-Content-Content-Warning">
                                <div>
                                  <VisibilityOffIcon style={{ fontSize: '5rem' }} />
                                  <h1>
                                    Content Warning: The author has marked this post as containing sensitive content.
                                  </h1>
                                  <button
                                    className="Post-Content-Content-Warning-Show-Button"
                                    onClick={e => {
                                      e.stopPropagation()
                                      setDisableContentWarning(true)
                                    }}>
                                    Show
                                  </button>
                                </div>
                              </div>
                            )}
                            {post.image_urls?.slice(0, 4).map((image, key) => (
                              <NavLink
                                to={`/post/${post.owner?.handle}/${post.id}/image/${key + 1}`}
                                state={{ background: location }}
                                className={
                                  post.image_urls?.length === 2
                                    ? 'Post-Images-Grid-Item'
                                    : post.image_urls?.length === 3 && key === 0
                                    ? 'Post-Images-Grid-Item'
                                    : post.image_urls?.length === 3 && key > 0
                                    ? 'Post-Images-Grid-Item-Alt'
                                    : 'Post-Images-Grid-Item-Alt'
                                }
                                key={key}>
                                <Image
                                  className="Post-Image-Alt"
                                  mainGridItem={key === 0 && post.image_urls?.length === 3}
                                  altGridItem={key > 0 && post.image_urls?.length === 3}
                                  mediaUrl={image}
                                />
                              </NavLink>
                            ))}
                          </div>
                        )}
                      </>
                    )}

                    {post.video_url && (
                      <div className="Post-Video-Container">
                        <video
                          className="Post-Video"
                          controls
                          autoPlay
                          playsInline
                          muted
                          loop
                          disablePictureInPicture
                          src={post.video_url}
                          onClick={e => {
                            console.log(e)
                            e.stopPropagation()
                          }}
                        />
                      </div>
                    )}
                    {post.gif_url &&
                      (checkMediaType(post.gif_url.url) === tags.video ? (
                        <Video
                          className="Post-Gif Post-Gif-Video"
                          controls={false}
                          autoPlay
                          playsInline
                          muted
                          loop
                          disablePictureInPicture
                          mediaUrl={post.gif_url}
                        />
                      ) : (
                        <Image className="Post-Gif" mediaUrl={post.gif_url} draggable />
                      ))}
                  </div>
                )}
                {quoteRepostData && (
                  <QuoteRepostItem
                    id={quoteRepostData.id}
                    timestamp={quoteRepostData.created_at}
                    text={quoteRepostData.text}
                    imageUrls={quoteRepostData.image_urls}
                    gifUrl={quoteRepostData.gif_url}
                    videoUrl={quoteRepostData.video_url}
                    owner={quoteRepostData.owner}
                    quotePostMentions={quoteRepostData.post_mentions}
                    quotePost
                  />
                )}
                <div className="Post-Options">
                  <button
                    className="Post-Option"
                    onClick={e => {
                      e.stopPropagation()
                      user
                        ? navigateToPage('/create/post', {
                            state: {
                              replyData: {
                                id: post.id,
                                timestamp: post.created_at,
                                owner: post.owner,
                                text: post.text,
                              },
                              feedType: FeedType.Reply,
                              background: location,
                            },
                          })
                        : navigateToPage('/login')
                    }}>
                    <ChatBubbleOutlineRoundedIcon />
                    <span>{replyCount}</span>
                  </button>
                  <button className={`Post-Option ${voted && 'Post-Option-Active'}`} onClick={handleVoteOptionClick}>
                    {voted ? (
                      <>
                        <FavoriteRoundedIcon className="Full-Heart" />
                        <HeartBrokenIcon className="Break-Heart" />
                      </>
                    ) : (
                      <FavoriteBorderRoundedIcon />
                    )}
                    <span>{voteCount}</span>
                    {/* Possiblity of likes and dislikes? */}
                  </button>

                  <button className="Post-Option" onClick={handleRepostOptionClick}>
                    <RepeatIcon style={{ transform: 'rotate(90deg)' }} />

                    <span>{shareCount}</span>
                  </button>
                  {repostMenuOpen && user && (
                    <RepostMenu
                      postId={post.id}
                      openQuotePostModal={() =>
                        navigateToPage('/create/post', {
                          state: {
                            quoteRepostData: {
                              id: post.id,
                              timestamp: post.created_at,
                              owner: post.owner,
                              text: post.text,
                              image_urls: post.image_urls,
                              video_url: post.video_url,
                              gif_url: post.video_url,
                            },
                            background: location,
                          },
                        })
                      }
                      closeMenuFunction={() => setRepostMenuOpen(false)}
                    />
                  )}
                  <button
                    className={`Post-Option ${bookmarked && 'Post-Option-Active'}`}
                    onClick={handleBookmarkOptionClick}>
                    {bookmarked ? (
                      <>
                        <BookmarkIcon className="Full-Bookmark" /> <BookmarkRemoveIcon className="Remove-Bookmark" />
                      </>
                    ) : (
                      <BookmarkBorderRoundedIcon />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FeedItem
