import { useEffect, useState } from 'react'
import { getAuth } from 'firebase/auth'

interface AuthorizationHeader {
  authorization: String
}

const TOKEN_REFRESH_THRESHOLD = 3 * 60 * 1000 // 3 minutes in milliseconds
const MAX_RETRIES = 3
const RETRY_DELAY = 5000 // 5 seconds in milliseconds

export function useAuthHeader() {
  const [header, setHeader] = useState<AuthorizationHeader | null>(null)

  useEffect(() => {
    const auth = getAuth()
    let tokenRefreshTimeout: NodeJS.Timeout
    let retryCount = 0

    const refreshToken = async () => {
      try {
        const user = auth.currentUser
        if (user) {
          const token = await user.getIdToken()
          setHeader({ authorization: `Bearer ${token}` })

          const idTokenResult = await user.getIdTokenResult()
          const currentTime = new Date().getTime()
          const expirationTime = new Date(idTokenResult.expirationTime).getTime()
          const timeDifference = expirationTime - currentTime

          // Clear the previous timeout
          clearTimeout(tokenRefreshTimeout)

          // Schedule token refresh before expiration
          if (timeDifference > TOKEN_REFRESH_THRESHOLD) {
            tokenRefreshTimeout = setTimeout(refreshToken, timeDifference - TOKEN_REFRESH_THRESHOLD)
          }
        } else {
          // No user is authenticated, clear the header
          setHeader(null)
        }
      } catch (error) {
        console.log('Error refreshing token:', error)

        // Retry refreshing the token
        if (retryCount < MAX_RETRIES) {
          retryCount++
          setTimeout(refreshToken, RETRY_DELAY)
        }
      }
    }

    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        refreshToken()
      } else {
        setHeader(null)
      }
    })

    return () => {
      unsubscribe()
      clearTimeout(tokenRefreshTimeout)
    }
  }, [])

  return header
}
