import './styles/browse.css'
import { LazyQueryHookOptions } from '@apollo/client'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { ProfileAvatar } from '../components/profile/display/profileAvatar'
import { sanitizeHtmlConf } from '../utils/constants'
import sanitizeHTML from 'sanitize-html'
import { StreamProvider } from '../gql/scalars'
import {
  Order_By,
  useGetLiveUsersLazyQuery,
  GetLiveUsersQueryVariables,
  GetLiveUsersQuery,
  User,
  Post,
} from '../gql/generated/graphql'
import { useEffect, useMemo, useState } from 'react'
import Twemoji from 'react-twemoji'
import { FeedItem } from '../components/feed/feedItem'
import { Feed, FeedType } from '../components/feed/feed'
import { NavBackButtonHeader } from '../navigation/navBackButtonHeader'
import { useListTrendingHashtags, useListTrendingPosts } from '../gql'
import { useScrollRestoration } from '../context/scrollRestorationContext'
import { numberToLetterNotation } from '../utils/converterUtils'

export function Browse() {
  const { navigateToPage } = useScrollRestoration()
  const [getLiveUsers, { data: liveUserData, loading: loadingLiveUsers }] = useGetLiveUsersLazyQuery()
  const { data: trendingPosts, loading: loadingPosts, fetchMore: fetchMoreTrendingPosts } = useListTrendingPosts()
  const { data: trendingHashtags, loading: loadingHashtags } = useListTrendingHashtags()
  let liveUserQueryOptions: LazyQueryHookOptions<GetLiveUsersQuery, GetLiveUsersQueryVariables> = {
    variables: {
      limit: 6,
      order_by: { followers_aggregate: { count: Order_By.Desc } },
    },
  }

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!loadingPosts && !loadingLiveUsers && !loadingHashtags) {
      setLoading(false)
    }
  }, [loadingPosts, loadingLiveUsers, loadingHashtags])

  useEffect(() => {
    getLiveUsers(liveUserQueryOptions)
  }, [])

  const liveUsers = useMemo(() => {
    return liveUserData?.user.map((user, index) => (
      <div
        className="Browse-Live-Profiles-Preview-Item"
        style={{
          backgroundImage: `url(${
            user.stream_provider === StreamProvider.Twitch
              ? `https://static-cdn.jtvnw.net/previews-ttv/live_user_${user.twitch_connection_id?.toLocaleLowerCase()}-1920x1080.jpg`
              : `http://img.youtube.com/vi/${user.youtube_connection_id}/maxresdefault.jpg`
          })`,
        }}
        onClick={() => navigateToPage(`/profile/${user.handle}`)}
        key={index}>
        <div className="Browse-Live-Profiles-Preview-Item-Fade"></div>
        <div className="Browse-Live-Profiles-Preview-Item-Status">
          <Twemoji options={{ className: 'twemoji' }}>
            <div
              dangerouslySetInnerHTML={{
                __html: user.status_message ? sanitizeHTML(user.status_message, sanitizeHtmlConf) : '',
              }}
            />
          </Twemoji>
        </div>
        <div className="Browse-Live-Profiles-Preview-Item-Info-Displayname">
          <ProfileAvatar size="Small" disableLink disableMiniProfile disableStatus user={user as User} />
          <span>
            <Twemoji options={{ className: 'Browse-Live-Profiles-Preview-Item-Info-Displayname-Emoji' }}>
              {user.username}
            </Twemoji>
          </span>
        </div>
        <div className="Browse-Live-Profiles-Preview-Item-Stream-Status">
          Live on{' '}
          <span className={`Browse-Live-Profiles-Preview-Item-Stream-Status-${user.stream_provider}`}>
            {user.stream_provider}
          </span>
        </div>
      </div>
    ))
  }, [liveUserData])

  let hashtagItems = trendingHashtags?.trending_hashtags.map((hashtag, index) => {
    return (
      <div
        className="Browse-Popular-Hashtags-Item"
        onClick={() => navigateToPage(`/hashtag/${hashtag.hashtag}`)}
        key={index}>
        <div className="Browse-Popular-Hashtags-Item-Top">#{hashtag.hashtag}</div>
        <div className="Browse-Popular-Hashtags-Item-Bottom">
          {`${numberToLetterNotation(hashtag.posts_aggregate.aggregate?.count)} posts - ${numberToLetterNotation(
            hashtag.hashtag_followers_aggregate.aggregate?.count
          )} followers`}
        </div>
      </div>
    )
  })

  const trendingPostItems = useMemo(() => {
    if (trendingPosts) {
      return trendingPosts?.trending_posts.map((trending, index) => {
        const { post } = trending
        const currentPost = post?.repost_post || post

        return <FeedItem post={currentPost as Post} key={currentPost?.id ?? index} />
      })
    }
  }, [trendingPosts])

  const loadingItems = useMemo(() => {
    return Array(10)
      .fill(undefined)
      .map((_value, index) => {
        return (
          <div className="Post-Container" key={index}>
            <div className="Post-Loading-Placeholder" />
          </div>
        )
      })
  }, [])

  return (
    <div className="Browse-Container">
      <div className="Browse-Container-Inner">
        <NavBackButtonHeader title={'Browse'} />
        {/* <div className="Thread-Back-Button">
          <NavLink
            to="#"
            onClick={() => {
              navigate(-1)
            }}>
            <ArrowBackIcon />
          </NavLink>
          <span className="Thread-Title">Browse</span>
        </div> */}
        {loading ? (
          loadingItems
        ) : (
          <>
            {liveUsers && liveUsers?.length > 0 && (
              <>
                <div className="Browse-Preview-Header">
                  <span>Live Users</span>
                </div>
                <div className="Browse-Live-Profiles-Preview">
                  {liveUsers}
                  {liveUsers}
                  {liveUsers}
                </div>
              </>
            )}
            <div className="Browse-Preview-Header">
              <span>Trending Hashtags</span>
            </div>
            <div className="Browse-Popular-Hashtags">{hashtagItems}</div>
            <div className="Browse-Preview-Header">
              <span>Trending Posts</span>
            </div>
            <Feed
              disableCreateFeedItem
              items={trendingPostItems ?? []}
              feedType={FeedType.Post}
              fetchMore={fetchMoreTrendingPosts}
              noItemsMessage="There are no posts yet, be the first!"
              fullScreen
            />
          </>
        )}
      </div>
    </div>
  )
}
