import { HashtagPlugin } from './plugins/HashtagPlugin/HashtagPlugin'
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import { useCardinalContext } from '../../context/cardinal'
import { useEffect, useRef, useState } from 'react'
import { useSharedHistoryContext } from './context/SharedHistoryContext'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import AutoLinkPlugin from './plugins/AutoLinkPlugin'
import EmojiPickerPlugin from './plugins/EmojiPickerPlugin'
import EmojisPlugin from './plugins/EmojisPlugin'
import TwemojiPlugin from './plugins/Twemoji'
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin'
import MentionsPlugin from './plugins/MentionsPlugin'
import TreeViewPlugin from './plugins/TreeViewPlugin'
import ContentEditable from './ui/ContentEditable'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import Placeholder from './ui/Placeholder'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
// import { MaxLengthPlugin } from './plugins/MaxLengthPlugin'
import { $generateHtmlFromNodes } from '@lexical/html'
import { EditorState } from 'lexical'

interface EditorProps {
  treeView?: boolean
  editorOnChange: (change: string) => void
  editorHashtagChange?: (change: string[]) => void
  editorMentionChange?: (change: { [key: string]: string }) => void
  id?: string
  className?: string
  placeHolderText?: string
}

export default function Editor({
  treeView,
  editorOnChange,
  editorHashtagChange,
  editorMentionChange,
  id,
  className,
  placeHolderText = 'Whats going on?',
}: EditorProps): JSX.Element {
  const state = useCardinalContext()
  const { historyState } = useSharedHistoryContext()
  const [editor] = useLexicalComposerContext()
  const placeholder = <Placeholder className="Seiso-Input-Placeholder">{placeHolderText}</Placeholder>
  const scrollRef = useRef(null)

  function onChange(editorState: EditorState) {
    editorState.read(() => {
      let arr = Array.from(editor._keyToDOMMap, ([name, value]) => ({ name, value }))

      let mentions = arr.filter(item => item.value.className && item.value.className === 'Post-Mention')

      let hashtags = arr.filter(item => item.value.className && item.value.className === 'Post-Hashtag')

      if (hashtags.length > 0 && editorHashtagChange) {
        editorHashtagChange(hashtags.map(item => item.value.innerText.replaceAll('#', '')))
      }

      const mentionDict: { [key: string]: string } = {}
      if (mentions.length > 0) {
        mentions.forEach(item => {
          const innerText = item.value.innerText.replaceAll('@', '')
          const dataSeisoMiniprofile = item.value.getAttribute('data-seiso-miniprofile') ?? ''
          mentionDict[innerText] = dataSeisoMiniprofile
        })
      }
      editorMentionChange?.(mentionDict)

      editorOnChange($generateHtmlFromNodes(editor, null))
    })
  }

  const [emoticons, setEmoticons] = useState<any>()
  useEffect(() => {
    //clean up required for follow content
    let emojiArr = []
    if (state.user?.owned_emojis) {
      for (let j = 0; j < state.user?.owned_emojis.length; j++) {
        emojiArr.push(state.user?.owned_emojis[j])
      }
    }
    if (state.user?.following) {
      for (let i = 0; i < state.user?.following.length; i++) {
        if (state.user?.following[i].following_user && state.user?.following[i].following_user.owned_emojis) {
          for (let j = 0; j < state.user?.following[i].following_user.owned_emojis.length; j++) {
            emojiArr.push(state.user?.following[i].following_user.owned_emojis[j])
          }
        }
      }
    }
    setEmoticons(emojiArr.map(item => ({ id: `:${item.key}:`, url: item.url })))
  }, [state.user])

  return (
    <>
      <div
        className="Seiso-Editor-Container"
        id="Seiso-Editor-Container"
        ref={scrollRef}
        style={{ position: 'relative' }}>
        <MentionsPlugin />
        <TwemojiPlugin />
        <EmojiPickerPlugin emojiList={emoticons} />
        <EmojisPlugin emojis={emoticons} />
        <HashtagPlugin />
        <ClearEditorPlugin />
        <AutoLinkPlugin />
        <OnChangePlugin onChange={onChange} />
        <HistoryPlugin externalHistoryState={historyState} />
        <PlainTextPlugin
          contentEditable={<ContentEditable className={className} id={id} />}
          placeholder={placeholder}
          ErrorBoundary={function ErrorBoundary({ children, onError }): JSX.Element {
            return (
              <ReactErrorBoundary fallback={<></>} onError={onError}>
                {children}
              </ReactErrorBoundary>
            )
          }}
        />
      </div>
      {treeView && <TreeViewPlugin />}
    </>
  )
}
