import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Feed, FeedType } from '../components/feed/feed'
import { FeedItem } from '../components/feed/feedItem'
import { Post as GraphqlPost, User } from '../gql/generated/graphql'
import './styles/thread.css'
import { NavBackButtonHeader } from '../navigation/navBackButtonHeader'
import { useGetPost } from '../gql/hooks/queries/useGetPost'
import { Sidebar, SidebarType } from '../components/sidebar/sidebar'
import { useCardinalContext } from '../context/cardinal'
import { PostFilterType, useListPosts } from '../gql'

export function Post() {
  const { post_id } = useParams()
  const { data: postData, loading: loadingPost } = useGetPost(post_id)
  const {
    data: postDataReplies,
    loading: loadingPostReplies,
    fetchMore,
    queryOptions,
  } = useListPosts(PostFilterType.Replies, post_id)
  const { user } = useCardinalContext()

  const feedItems = useMemo(() => {
    return postDataReplies?.post.map(reply => <FeedItem post={reply as GraphqlPost} key={reply.id} />)
  }, [postDataReplies])

  const focusedItem = postData?.post_by_pk && (
    <FeedItem post={postData.post_by_pk as GraphqlPost} key={postData.post_by_pk.id} focused />
  )

  return (
    <>
      <div className="Thread-Container">
        <NavBackButtonHeader title="Post" />

        <Feed
          disableCreateFeedItem={!!!user}
          loading={loadingPost && loadingPostReplies}
          items={feedItems}
          focusedItem={focusedItem}
          fetchMore={fetchMore}
          feedType={FeedType.Reply}
          noItemsMessage="There are no replies yet, be the first!"
          queryOptions={queryOptions}
        />
      </div>

      <Sidebar
        sidebarType={SidebarType.Post}
        postMentions={
          postData?.post_by_pk?.owner && [
            ...(postData?.post_by_pk?.post_mentions.map(item => item.user as User) ?? null),
            (postData?.post_by_pk?.quote_repost_post?.owner as User) ?? null,
            postData?.post_by_pk?.owner as User,
          ]
        }
      />
    </>
  )
}
