import { useLocation, Navigate } from 'react-router-dom'
import { AuthenticationState, useAuthContext } from '../../context/authContext'

export function ConfirmedRoute({ children }: any) {
  const location = useLocation()
  const { authenticationState } = useAuthContext()
  if (authenticationState === AuthenticationState.Unconfirmed) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  if (authenticationState !== AuthenticationState.InitialLoad) {
    return children
  }
}
