import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import './authenticationModal.css'
import {
  AuthenticationAction,
  AuthBody,
  AuthBodyProps,
  AuthenticationFormField,
  AuthenticationTab,
  AuthenticationFlowState,
} from '.'
import { AuthenticationState, useAuthContext } from '../../context/authContext'
import { useCardinalContext } from '../../context/cardinal'
import { applyActionCode, checkActionCode, getAuth } from 'firebase/auth'
import { LoadingModal } from '../uiKit/loading/loadingModal'
import { useScrollRestoration } from '../../context/scrollRestorationContext'
import { handleFirebaseError } from '../../utils/firebase'
import { FirebaseError } from 'firebase/app'

const SETTINGS_PATH = '/settings/general'

export function AuthenticationModal() {
  const { navigateToPage } = useScrollRestoration()
  const location = useLocation()
  const auth = getAuth()

  const { authUser, flowState, authenticationState, setError, setLoading, setFlowState, setAuthenticationState } =
    useAuthContext()
  const { user } = useCardinalContext()

  const [tab, setTab] = useState<AuthenticationTab>(AuthenticationTab.SignIn)
  const [hideTabs, setHideTabs] = useState<boolean>(false)
  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false)

  const { mode, oobCode } = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)
    return {
      mode: searchParams.get('mode'),
      oobCode: searchParams.get('oobCode'),
    }
  }, [location.search])

  useEffect(() => {
    if (oobCode) {
      setLoading(true)
      setError(undefined)

      switch (mode) {
        case 'resetPassword':
          setShouldRedirect(true)
          setFlowState(AuthenticationFlowState.ConfirmingResetPassword)
          setLoading(false)
          break
        case 'recoverEmail':
          setShouldRedirect(true)
          checkActionCode(auth, oobCode)
            .then(() => {
              return applyActionCode(auth, oobCode)
            })
            .then(() => {
              setFlowState(AuthenticationFlowState.EmailRevertSuccessConfirmation)
            })
            .catch(e => setError(handleFirebaseError(e)))
            .finally(() => setLoading(false))
          break
        case 'verifyAndChangeEmail':
          setShouldRedirect(false)
          applyActionCode(auth, oobCode)
            .then(() => {
              setFlowState(AuthenticationFlowState.EmailUpdateSuccessConfirmation)
            })
            .catch(e => setError(handleFirebaseError(e)))
            .finally(() => setLoading(false))
          break
        case 'verifyEmail':
          setShouldRedirect(true)
          applyActionCode(auth, oobCode)
            .then(() => {
              setFlowState(AuthenticationFlowState.EmailVerifySuccessConfirmation)
              setAuthenticationState(AuthenticationState.Authenticated)
            })
            .catch(e => setError(handleFirebaseError(e)))
            .finally(() => setLoading(false))
          break
        default:
          setLoading(false)
          setShouldRedirect(true)
          setError(handleFirebaseError(new FirebaseError('auth/invalid-link', 'Invalid action link')))
      }
    } else {
      setFlowState(AuthenticationFlowState.None)
      setShouldRedirect(true)
    }
  }, [mode, oobCode])

  useEffect(() => {
    switch (flowState) {
      case AuthenticationFlowState.ConfirmingSignUp:
      case AuthenticationFlowState.EmailVerifySuccessConfirmation:
        setTab(AuthenticationTab.SignUp)
        setHideTabs(true)
        break
      case AuthenticationFlowState.RequestingResetPassword:
      case AuthenticationFlowState.SentResetPasswordEmail:
      case AuthenticationFlowState.ConfirmingResetPassword:
      case AuthenticationFlowState.PasswordResetSuccessConfirmation:
      case AuthenticationFlowState.UpdatingEmail:
      case AuthenticationFlowState.ConfirmingEmailUpdate:
      case AuthenticationFlowState.EmailUpdateSuccessConfirmation:
      case AuthenticationFlowState.EmailRevertSuccessConfirmation:
        setHideTabs(true)
        break
      case AuthenticationFlowState.None:
      default:
        setTab(AuthenticationTab.SignIn)
        setHideTabs(false)
        break
    }
  }, [flowState])

  const bodyContent: AuthBodyProps = useMemo(() => {
    switch (tab) {
      case AuthenticationTab.SignUp:
        if (flowState === AuthenticationFlowState.ConfirmingSignUp) {
          return {
            formFields: [],
            headerText: "We've sent you a confirmation email. Please verify your account before continuing.",
            submitAction: {
              displayText: 'Resend Confirmation Email',
              action: AuthenticationAction.SendSignUpConfirmationEmail,
            },
            footerActions: [{ displayText: 'Back to Sign In', action: AuthenticationAction.GoToSignIn }],
          }
        }
        if (flowState === AuthenticationFlowState.EmailVerifySuccessConfirmation) {
          return {
            formFields: [],
            headerText: 'Email verified successfully!',
            submitAction: { displayText: 'Go to home', action: AuthenticationAction.GoToHome },
          }
        }
        return {
          formFields: [
            { formFieldName: AuthenticationFormField.Email, type: 'email', placeholder: 'Email' },
            { formFieldName: AuthenticationFormField.Username, type: 'text', placeholder: 'Username' },
            { formFieldName: AuthenticationFormField.Password, type: 'password', placeholder: 'Password' },
            {
              formFieldName: AuthenticationFormField.ConfirmPassword,
              type: 'password',
              placeholder: 'Confirm Password',
            },
          ],
          submitAction: { displayText: 'Create Account', action: AuthenticationAction.SignUp },
          inputValidators: [
            {
              formFieldName: AuthenticationFormField.Username,
              regexExpression: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              errorMessage: 'Username cannot be an email',
            },
            {
              formFieldName: AuthenticationFormField.Username,
              regexExpression: /^(?![a-zA-Z0-9_-]+([_-]?[a-zA-Z0-9])*$)/,
              errorMessage: 'Username can only be alphanumeric characters, - or _',
            },
            {
              formFieldName: AuthenticationFormField.Username,
              regexExpression: /^(.{0,2}|.{16,})$/,
              errorMessage: 'Username must be between 3-15 characters',
            },
          ],
          headerText: 'REGISTER',
        }
      case AuthenticationTab.SignIn:
      default:
        if (flowState === AuthenticationFlowState.RequestingResetPassword) {
          return {
            formFields: [{ formFieldName: AuthenticationFormField.Email, type: 'email', placeholder: 'Email' }],
            submitAction: { displayText: 'Send Email', action: AuthenticationAction.SendResetPasswordEmail },
            headerText: 'RESET PASSWORD',
            footerActions: [{ displayText: 'Back to Sign In', action: AuthenticationAction.GoToSignIn }],
          }
        }

        if (flowState === AuthenticationFlowState.SentResetPasswordEmail) {
          return {
            formFields: [],
            headerText:
              "We've sent you a password reset email. Please check your inbox and follow the instructions to complete your password reset.",
            submitAction: {
              displayText: 'Resend Email',
              action: AuthenticationAction.SendResetPasswordEmail,
            },
            footerActions: [{ displayText: 'Back to Sign In', action: AuthenticationAction.GoToSignIn }],
          }
        }

        if (flowState === AuthenticationFlowState.ConfirmingResetPassword) {
          return {
            formFields: [
              { formFieldName: AuthenticationFormField.Password, type: 'password', placeholder: 'Password' },
              {
                formFieldName: AuthenticationFormField.ConfirmPassword,
                type: 'password',
                placeholder: 'Confirm Password',
              },
            ],
            submitAction: { displayText: 'Reset Password', action: AuthenticationAction.ConfirmResetPassword },
            footerActions: [{ displayText: 'Back to Sign In', action: AuthenticationAction.GoToSignIn }],
            headerText: 'RESET PASSWORD',
          }
        }

        if (flowState === AuthenticationFlowState.PasswordResetSuccessConfirmation) {
          return {
            formFields: [],
            footerActions: [{ displayText: 'Back to Sign In', action: AuthenticationAction.GoToSignIn }],
            headerText: 'Password Reset Successfully!',
          }
        }

        if (flowState === AuthenticationFlowState.UpdatingEmail) {
          return {
            formFields: [
              { formFieldName: AuthenticationFormField.Password, type: 'password', placeholder: 'Current Password' },
              { formFieldName: AuthenticationFormField.Email, type: 'email', placeholder: 'New Email' },
            ],
            submitAction: { displayText: 'Save Email', action: AuthenticationAction.UpdateEmail },
            headerText: 'UPDATE YOUR EMAIL',
          }
        }

        if (flowState === AuthenticationFlowState.ConfirmingEmailUpdate) {
          return {
            formFields: [],
            headerText:
              "We've sent you a verification email. Please check your inbox and follow the instructions to complete your email update.",
            submitAction: {
              displayText: 'Close',
              action: AuthenticationAction.GoBack,
            },
          }
        }

        if (flowState === AuthenticationFlowState.EmailUpdateSuccessConfirmation) {
          return {
            formFields: [],
            submitAction: {
              displayText: 'Close',
              action: AuthenticationAction.GoBack,
            },
            headerText: 'EMAIL UPDATED SUCCESSFULLY!',
          }
        }

        if (flowState === AuthenticationFlowState.EmailRevertSuccessConfirmation) {
          return {
            formFields: [],
            headerText:
              'Your email has been successfully reverted. For your security, we highly recommend updating your password to prevent unauthorized access to your account.',
            submitAction: {
              displayText: 'Close',
              action: AuthenticationAction.GoBack,
            },
            footerActions: [{ displayText: 'Back to Sign In', action: AuthenticationAction.GoToSignIn }],
          }
        }

        return {
          formFields: [
            { formFieldName: AuthenticationFormField.Email, type: 'email', placeholder: 'Email' },
            { formFieldName: AuthenticationFormField.Password, type: 'password', placeholder: 'Password' },
          ],
          submitAction: { displayText: 'Sign in', action: AuthenticationAction.SignIn },
          secondaryActions: [{ displayText: 'FORGOT YOUR PASSWORD?', action: AuthenticationAction.ResetPassword }],
          headerText: 'WELCOME BACK!',
        }
    }
  }, [tab, flowState])

  const from = useMemo(() => {
    const state = location.state as { background?: { pathname?: string } }

    if (state?.background?.pathname) {
      return state.background.pathname
    }

    return
  }, [location])

  useEffect(() => {
    if (shouldRedirect && from !== SETTINGS_PATH && authUser?.emailVerified && user) {
      navigateToPage(from ?? '/', { replace: true })
    }
  }, [authUser, user, navigateToPage, from, shouldRedirect])

  return (
    <>
      <div
        className="Authentication-Modal-Container-BG"
        onClick={e => {
          if (e.target === e.currentTarget) {
            from && navigateToPage(from)
          }
        }}>
        <div className="Authentication-Modal-Container">
          <div className="Login-Register-Container">
            {authenticationState === AuthenticationState.InitialLoad ? (
              <LoadingModal header="Loading.." />
            ) : (
              <>
                <AuthBody {...bodyContent} hideCloseButton={!!!from} />
                {!hideTabs && (
                  <div>
                    <div
                      onClick={() => setTab(AuthenticationTab.SignIn)}
                      className={tab === AuthenticationTab.SignIn ? 'AuthenticationTab-Hidden' : 'AuthenticationTab'}>
                      Back to Sign In
                    </div>
                    <div
                      onClick={() => setTab(AuthenticationTab.SignUp)}
                      className={tab === AuthenticationTab.SignUp ? 'AuthenticationTab-Hidden' : 'AuthenticationTab'}>
                      Need an account?
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
