import AddIcon from '@mui/icons-material/Add'
import { Masonry } from '@mui/lab'
import imageCompression from 'browser-image-compression'
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { ActionType, EditorDispatchContext, ShowcaseContext } from '../../../../context/editorContext'
import CheckSharpIcon from '@mui/icons-material/CheckSharp'
import CloseSharpIcon from '@mui/icons-material/CloseSharp'
import { SocialLink, SocialProvider, SocialsShowcase } from '../../../../gql/scalars'
import { buildSocialLink } from '../../../../utils/statusUtils'
import './styles/socialsBlock.css'

type PanelBlockProps = {
  showcase: (SocialsShowcase & ShowcaseContext) | null | undefined
  keyProp: number
  tab: string
}

export function SocialsBlock(props: PanelBlockProps) {
  const editorDispatch = useContext(EditorDispatchContext)

  const [editing, setEditing] = useState<boolean>(false)
  const [linkTitle, setLinkTitle] = useState<string>('')
  const [linkUrl, setLinkUrl] = useState<string>('')
  const [socialLink, setSocialLink] = useState<SocialLink>({ url: '', icon: '', provider: SocialProvider.Other })

  useEffect(() => {
    setSocialLink(buildSocialLink(linkUrl))
  }, [linkUrl])

  function AddSocial() {
    const showcase = props.showcase
    let newPanel: any = {
      url: socialLink.url,
      customName: linkTitle.length > 0 ? linkTitle : socialLink.provider,
      icon: socialLink.icon,
      provider: socialLink.provider,
    }

    if (showcase) {
      const showcaseUpdate = Object.assign({}, showcase, {
        items: showcase.items ? [...showcase.items, newPanel] : [newPanel],
      })

      editorDispatch({
        type: ActionType.secondLevelUpdate,
        payload: showcaseUpdate,
        firstLevelKey: props.tab,
        secondLevelKey: props.keyProp,
      })
    }

    Reset()
  }

  function RemoveSocial(key: number) {
    const showcase = props.showcase

    if (showcase && showcase.items && showcase.items[key]) {
      const showcaseUpdate = Object.assign({}, showcase, {
        items: [...showcase.items.slice(0, key), ...showcase.items.slice(key + 1)],
      })

      editorDispatch({
        type: ActionType.secondLevelUpdate,
        payload: showcaseUpdate,
        firstLevelKey: props.tab,
        secondLevelKey: props.keyProp,
      })
    }
  }

  function Reset() {
    setLinkTitle('')
    setLinkUrl('')
  }

  return (
    <>
      <div className="Profile-Custom-Block">
        <div className="Socials-Block-Container">
          {props.showcase?.items &&
            props.showcase?.items.map(
              (item, key) =>
                item && (
                  <div className="Socials-Block-Item" key={key}>
                    <img className="Socials-Block-Item-Img" src={item?.icon} alt="" />
                    <div className="Socials-Block-Item-Info">
                      <h2>{item?.customName}</h2>
                      <p>{item?.url}</p>
                    </div>
                    <div className="Socials-Block-Item-Options">
                      <div className="Socials-Block-Item-Option" onClick={() => RemoveSocial(key)}>
                        <CloseSharpIcon />
                      </div>
                    </div>
                  </div>
                )
            )}

          {editing ? (
            <div className="Socials-Block-Item-Alt">
              <img className="Socials-Block-Item-Img" src={socialLink.icon} alt={socialLink.provider} />
              <div className="Socials-Block-Item-Input-Container">
                <input placeholder="Text to show" value={linkTitle} onChange={e => setLinkTitle(e.target.value)} />
                <input placeholder="Where it leads" value={linkUrl} onChange={e => setLinkUrl(e.target.value)} />
              </div>
              <div className="Socials-Block-Item-Options">
                <div
                  className="Socials-Block-Item-Option"
                  onClick={() => {
                    setEditing(false)
                    AddSocial()
                  }}>
                  <CheckSharpIcon />
                </div>
                <div
                  className="Socials-Block-Item-Option"
                  onClick={() => {
                    setEditing(false)
                    Reset()
                  }}>
                  <CloseSharpIcon />
                </div>
              </div>
            </div>
          ) : (
            <div className="Socials-Block-Item" onClick={() => setEditing(true)}>
              <div className="Socials-Block-Item-Add">
                <AddIcon />
              </div>
              <div className="Socials-Block-Item-Info">
                <h2>Add New Social</h2>
                <p>New Social Link</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
