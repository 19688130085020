import { HTMLInputTypeAttribute } from 'react'

export enum AuthenticationTab {
  SignIn = 'signIn',
  SignUp = 'signUp',
}

export enum AuthenticationFormField {
  Username = 'username',
  Email = 'email',
  Password = 'password',
  ConfirmPassword = 'confirmPassword',
}

export enum AuthenticationAction {
  SignIn = 'signIn',
  SignUp = 'signUp',
  SendSignUpConfirmationEmail = 'sendSignUpConfirmationEmail',
  ResetPassword = 'resetPassword',
  SendResetPasswordEmail = 'sendResetPasswordEmail',
  ConfirmResetPassword = 'confirmResetPassword',
  GoToSignIn = 'goToSignIn', // For returning when in the reset password flow.
  GoToSignUp = 'goToSignUp',
  GoToHome = 'goToHome',
  GoBack = 'goBack',
  UpdateEmail = 'updateEmail',
}

export enum AuthenticationFlowState {
  None = 'none',
  ConfirmingSignUp = 'confirmingSignUp',
  RequestingResetPassword = 'requestingResetPassword',
  SentResetPasswordEmail = 'sentResetPasswordEmail',
  ConfirmingResetPassword = 'confirmingResetPassword',
  PasswordResetSuccessConfirmation = 'passwordResetSuccessConfirmation',
  EmailVerifySuccessConfirmation = 'emailVerifySuccessConfirmation',
  UpdatingEmail = 'updatingEmail',
  ConfirmingEmailUpdate = 'confirmingEmailUpdate',
  EmailUpdateSuccessConfirmation = 'emailUpdateSuccessConfirmation',
  EmailRevertSuccessConfirmation = 'emailRevertSuccessConfirmation',
}

export interface ITabFormField {
  formFieldName: AuthenticationFormField
  type?: HTMLInputTypeAttribute
  placeholder?: string
}

export interface IInputValidator {
  formFieldName: AuthenticationFormField
  regexExpression: RegExp
  errorMessage: string
}

export interface IAuthenticationAction {
  displayText: string
  action: AuthenticationAction
}

export type IAuthenticationFormData = {
  [key in AuthenticationFormField]?: string
}
