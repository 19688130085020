import { useEffect, useMemo } from 'react'
import { GetUserQuery, GetUserQueryVariables, useGetUserLazyQuery } from '../../generated/graphql'
import { BrowsingState, useCardinalContext } from '../../../context/cardinal'
import { LazyQueryHookOptions } from '@apollo/client'

export function useGetUser(handle?: string) {
  const { user, browsingState } = useCardinalContext()
  const [getUser, { data, loading, refetch }] = useGetUserLazyQuery()

  const queryOptions: LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables> = useMemo(
    () => ({
      variables: {
        handle: handle ?? '',
        currentUserHandle: user?.handle ?? '',
      },
      fetchPolicy: 'cache-and-network',
    }),
    [user?.handle, handle]
  )

  useEffect(() => {
    if (browsingState !== BrowsingState.Loading && handle) {
      getUser(queryOptions)
    }
  }, [browsingState, handle, getUser, queryOptions])

  return {
    data,
    loading,
    refetch,
  }
}
