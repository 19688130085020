import React, { useContext, useEffect, useState } from 'react'
import { AuthenticationFlowState } from '../components/authentication'
import { User as FirebaseUser, getAuth } from 'firebase/auth'

export type SignUpRequest = {
  email: string
  password: string
  username: string
}

export enum AuthenticationState {
  Authenticated = 'authenticated',
  Anonymous = 'anonymous',
  Unconfirmed = 'unconfirmed',
  InitialLoad = 'initialLoad',
}

interface AuthContextProps {
  authUser?: FirebaseUser
  setAuthUser: React.Dispatch<React.SetStateAction<FirebaseUser | undefined>>
  flowState: AuthenticationFlowState
  setFlowState: React.Dispatch<React.SetStateAction<AuthenticationFlowState>>
  authenticationState: AuthenticationState
  setAuthenticationState: React.Dispatch<React.SetStateAction<AuthenticationState>>
  error?: string
  setError: React.Dispatch<React.SetStateAction<string | undefined>>
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const AuthContext = React.createContext<AuthContextProps | undefined>(undefined)

export const useAuthContext = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuthContext must be used within a AuthProvider')
  }
  return context
}

type AuthProviderProps = {
  children: JSX.Element
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [authUser, setAuthUser] = useState<FirebaseUser>()
  const [flowState, setFlowState] = useState<AuthenticationFlowState>(AuthenticationFlowState.None)
  const [authenticationState, setAuthenticationState] = useState<AuthenticationState>(AuthenticationState.InitialLoad)
  const [error, setError] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)

  const auth = getAuth()

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setAuthUser(user)
        setAuthenticationState(user.emailVerified ? AuthenticationState.Authenticated : AuthenticationState.Unconfirmed)
        !user.emailVerified && setFlowState(AuthenticationFlowState.ConfirmingSignUp)
      } else {
        setAuthUser(undefined)
        setAuthenticationState(AuthenticationState.Anonymous)
      }
    })

    return () => unsubscribe()
  }, [auth])

  return (
    <AuthContext.Provider
      value={{
        authUser,
        setAuthUser,
        flowState,
        setFlowState,
        authenticationState,
        setAuthenticationState,
        error,
        setError,
        loading,
        setLoading,
      }}>
      {children}
    </AuthContext.Provider>
  )
}
