import { BaseSyntheticEvent, useEffect, useMemo, useState } from 'react'
import {
  AuthenticationAction,
  AuthenticationFlowState,
  AuthenticationFormField,
  IAuthenticationAction,
  IAuthenticationFormData,
  IInputValidator,
  ITabFormField,
} from './auth_interfaces'
import { AuthFormField } from './authFormField'
import { SignUpRequest, useAuthContext } from '../../context/authContext'
import { LoadingModal } from '../uiKit/loading/loadingModal'
import { getFunctions, httpsCallable } from 'firebase/functions'
import {
  EmailAuthProvider,
  User as FirebaseUser,
  confirmPasswordReset,
  getAuth,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  verifyBeforeUpdateEmail,
} from 'firebase/auth'
import { useLocation } from 'react-router-dom'
import { useScrollRestoration } from '../../context/scrollRestorationContext'
import CloseIcon from '@mui/icons-material/Close'
import { Button } from '../uiKit/button'
import { handleFirebaseError } from '../../utils/firebase'

export interface AuthBodyProps {
  formFields: ITabFormField[]
  headerText: string
  submitAction?: IAuthenticationAction
  inputValidators?: IInputValidator[]
  secondaryActions?: IAuthenticationAction[]
  footerActions?: IAuthenticationAction[]
  hideCloseButton?: boolean
}

export function AuthBody({
  formFields,
  headerText,
  submitAction,
  inputValidators,
  secondaryActions,
  footerActions,
  hideCloseButton,
}: AuthBodyProps) {
  const [disabled, setDisabled] = useState<boolean>(false)
  const [user, setUser] = useState<FirebaseUser>()
  const [email, setEmail] = useState<string>()
  const [confirmation, setConfirmation] = useState<string>()
  const { navigateToPage } = useScrollRestoration()
  const location = useLocation()

  const { loading, error, setError, setLoading, setFlowState } = useAuthContext()
  const signUp = httpsCallable(getFunctions(), 'signUp')
  const auth = getAuth()

  const oobCode = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)
    return searchParams.get('oobCode')
  }, [location.search])

  useEffect(() => {
    const passwordField = document.getElementById(AuthenticationFormField.Password) as HTMLInputElement
    const confirmPasswordField = document.getElementById(AuthenticationFormField.ConfirmPassword) as HTMLInputElement

    if (passwordField && confirmPasswordField) {
      if (
        submitAction?.action === AuthenticationAction.SignUp ||
        submitAction?.action === AuthenticationAction.ConfirmResetPassword
      ) {
        function validatePassword() {
          if (passwordField.value.length < 6) {
            setError('Password must be at least 6 characters')
            setDisabled(true)
          } else if (passwordField.value !== confirmPasswordField.value) {
            setError('Password does not match')
            setDisabled(true)
          } else {
            setError(undefined)
            setDisabled(false)
          }
        }

        passwordField.onchange = validatePassword
        confirmPasswordField.onkeyup = validatePassword
      } else {
        passwordField.onchange = null
        confirmPasswordField.onkeyup = null

        setError(undefined)
        setDisabled(false)
      }
    } else {
      setDisabled(false)
    }
  }, [submitAction?.action])

  function hasInputErrors(formData: IAuthenticationFormData) {
    if (inputValidators) {
      for (const validator of inputValidators) {
        if (formData[validator.formFieldName]?.match(validator.regexExpression)) {
          setError(validator.errorMessage)
          return true
        }
      }
    }

    setLoading(true)
    return false
  }

  function handleOnAction(event: BaseSyntheticEvent, action?: AuthenticationAction) {
    event.stopPropagation()
    event.preventDefault()
    setError(undefined)
    setConfirmation(undefined)

    const formData: IAuthenticationFormData = {}
    if (event.type === 'submit') {
      new FormData(event.currentTarget).forEach((value, key) => {
        formData[key as AuthenticationFormField] = value as string
      })
    }

    switch (action) {
      case AuthenticationAction.SignUp:
        if (formData.username && formData.email && formData.password && formData.confirmPassword) {
          if (hasInputErrors(formData)) {
            break
          }

          //TODO: Add a profanity filter
          const request: SignUpRequest = {
            username: formData.username,
            email: formData.email,
            password: formData.password,
          }
          signUp(request)
            .then(success => {
              if (success) {
                signInWithEmailAndPassword(auth, formData.email ?? '', formData.password ?? '').then(credentials => {
                  setUser(credentials.user)
                  sendEmailVerification(credentials.user).then(() => {
                    setFlowState(AuthenticationFlowState.ConfirmingSignUp)
                  })
                })
              }
            })
            .catch(e => setError(handleFirebaseError(e)))
            .finally(() => setLoading(false))
        }
        break
      case AuthenticationAction.SendSignUpConfirmationEmail:
        const currentUser = user ?? auth.currentUser
        if (currentUser) {
          setLoading(true)
          sendEmailVerification(currentUser)
            .then(() => setConfirmation('Email sent!'))
            .catch(e => setError(handleFirebaseError(e)))
            .finally(() => setLoading(false))
        }
        break
      case AuthenticationAction.SignIn:
        if (formData.email && formData.password) {
          if (hasInputErrors(formData)) {
            break
          }

          signInWithEmailAndPassword(auth, formData.email, formData.password)
            .then(credentials => {
              if (credentials.user && !credentials.user.emailVerified && !oobCode) {
                setFlowState(AuthenticationFlowState.ConfirmingSignUp)
              }
            })
            .catch(e => setError(handleFirebaseError(e)))
            .finally(() => setLoading(false))
        }
        break
      case AuthenticationAction.ResetPassword:
        setFlowState(AuthenticationFlowState.RequestingResetPassword)
        break
      case AuthenticationAction.SendResetPasswordEmail:
        const currentEmail = formData.email ?? email
        if (currentEmail) {
          if (hasInputErrors(formData)) {
            break
          }

          sendPasswordResetEmail(auth, currentEmail)
            .then(() => {
              // Only show the confirmation after the second time the user clicks the button
              if (email) {
                setConfirmation('Email sent!')
              }

              setEmail(currentEmail)
              setFlowState(AuthenticationFlowState.SentResetPasswordEmail)
            })
            .catch(e => setError(handleFirebaseError(e)))
            .finally(() => setLoading(false))
        }
        break
      case AuthenticationAction.ConfirmResetPassword:
        if (oobCode && formData.password && formData.confirmPassword) {
          if (hasInputErrors(formData)) {
            break
          }

          confirmPasswordReset(auth, oobCode, formData.password)
            .then(() => setFlowState(AuthenticationFlowState.PasswordResetSuccessConfirmation))
            .catch(e => setError(handleFirebaseError(e)))
            .finally(() => setLoading(false))
        }
        break
      case AuthenticationAction.GoToSignIn:
        setFlowState(AuthenticationFlowState.None)

        //Going back to login means we want to sign in again so sign out first.
        if (auth.currentUser) {
          auth.signOut()
        }
        break
      case AuthenticationAction.GoToHome:
        navigateToPage('/', { replace: true })
        break
      case AuthenticationAction.GoBack:
        let pathname = '/'

        const state = location.state as { background?: { pathname?: string } }
        if (state?.background?.pathname) {
          pathname = state.background.pathname
        }

        navigateToPage(pathname)
        break
      case AuthenticationAction.UpdateEmail:
        if (formData.email && formData.password && auth.currentUser?.email) {
          const credential = EmailAuthProvider.credential(auth.currentUser.email, formData.password)
          setLoading(true)

          reauthenticateWithCredential(auth.currentUser, credential)
            .then(() => {
              if (auth.currentUser && formData.email) {
                verifyBeforeUpdateEmail(auth.currentUser, formData.email)
                  .then(() => {
                    setFlowState(AuthenticationFlowState.ConfirmingEmailUpdate)
                  })
                  .catch(e => setError(handleFirebaseError(e)))
              }
            })
            .catch(e => setError(handleFirebaseError(e)))
            .finally(() => setLoading(false))
        }
        break
      default:
        break
    }
  }

  return (
    <div>
      <div>
        {loading && <LoadingModal header="Loading.." />}

        <form onSubmit={e => handleOnAction(e, submitAction?.action)}>
          <div className="Auth-Header-Container">
            <h1 className="Auth-Header">{headerText}</h1>
            {!hideCloseButton && (
              <Button type="button" appearance="Transparent" onClick={e => navigateToPage(-1)}>
                <CloseIcon fontSize="large" />
              </Button>
            )}
          </div>
          {formFields.map((field, key) => (
            <AuthFormField key={`${field.formFieldName}-${key}`} {...field} />
          ))}
          {error && <div className="Auth-Body-Error">{error}</div>}
          {confirmation && <div className="Auth-Body-Confirmation">{confirmation}</div>}
          {secondaryActions?.map((secondaryAction, key) => (
            <div
              className="Auth-Body-Secondary-Text"
              key={`${secondaryAction.displayText}-${key}`}
              onClick={e => handleOnAction(e, secondaryAction.action)}>
              {secondaryAction.displayText}
            </div>
          ))}
          {submitAction && (
            <input type="submit" className="Auth-Submit" value={submitAction.displayText} disabled={disabled} />
          )}

          {footerActions &&
            footerActions?.map((footerAction, key) => (
              <div
                className="Auth-Body-Footer-Text"
                key={`${footerAction.displayText}-${key}`}
                onClick={e => handleOnAction(e, footerAction.action)}>
                {footerAction.displayText}
              </div>
            ))}
        </form>
      </div>
    </div>
  )
}
