import { LazyQueryHookOptions } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import { Feed, FeedType } from '../components/feed/feed'
import { FeedItem } from '../components/feed/feedItem'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { BrowsingState, useCardinalContext } from '../context/cardinal'
import {
  ListBookmarksQuery,
  ListBookmarksQueryVariables,
  Post,
  useListBookmarksLazyQuery,
  User,
} from '../gql/generated/graphql'
import './styles/bookmarks.css'
import { NavBackButtonHeader } from '../navigation/navBackButtonHeader'

export function Bookmarks() {
  const { user, browsingState } = useCardinalContext()
  const [listBookmarks, { data, loading, fetchMore }] = useListBookmarksLazyQuery()
  let queryOptions: LazyQueryHookOptions<ListBookmarksQuery, ListBookmarksQueryVariables> = {
    variables: {
      limit: 20,
      postFilter: { id: { _eq: 'c2851e42-a926-4f54-ae3e-ce2508aa31ad' } },
      offset: data?.bookmarked_posts.length ?? 0,
      currentUserHandle: browsingState === BrowsingState.Authenticated ? user?.handle : '',
    },
  }

  useEffect(() => {
    if (browsingState !== BrowsingState.Loading) {
      listBookmarks(queryOptions)
    }
  }, [browsingState, listBookmarks])

  const bookmarkItems = useMemo(() => {
    return data?.bookmarked_posts.map(postData => {
      const { post } = postData
      return <FeedItem post={post as Post} key={post.id} />
    })
  }, [data])

  return (
    <div className="Thread-Container">
      <NavBackButtonHeader title="Bookmarks" />
      <Feed
        loading={loading}
        items={bookmarkItems}
        feedType={FeedType.Post}
        fetchMore={() => fetchMore(queryOptions)}
        disableCreateFeedItem
        noItemsMessage="You don't have any bookmarks."
      />
    </div>
  )
}
