import AddIcon from '@mui/icons-material/Add'
import AutoAwesomeMosaicSharpIcon from '@mui/icons-material/AutoAwesomeMosaicSharp'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined'
import GridViewSharpIcon from '@mui/icons-material/GridViewSharp'
import SquareSharpIcon from '@mui/icons-material/SquareSharp'
import ViewAgendaSharpIcon from '@mui/icons-material/ViewAgendaSharp'
import imageCompression from 'browser-image-compression'
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import {
  ActionType,
  BlobType,
  EditorDispatchContext,
  EditorStateContext,
  ShowcaseContext,
} from '../../../../context/editorContext'
import { ImageShowcase } from '../../../../gql/scalars'
import './imageEmbedModal.css'
import { CropImageModal } from '../../../../utils/cropImageModal'
import { imageCompressionOptions } from '../../../../utils/constants'
import { useLocation } from 'react-router-dom'
import { ClickAwayListener } from '../../../modal/clickAwayListener'
import { useScrollRestoration } from '../../../../context/scrollRestorationContext'

export function ImageEditorRouteModal() {
  const location = useLocation()
  const { navigateToPage } = useScrollRestoration()
  const blockKey = (location.state && location.state.blockKey) ?? null
  const itemKey = (location.state && location.state.itemKey) ?? null
  const tab = (location.state && location.state.tab) ?? null
  const showcase = (location.state && (location.state.showcase as ImageShowcase)) ?? null

  const editorState = useContext(EditorStateContext)
  const editorDispatch = useContext(EditorDispatchContext)
  const [link, setLink] = useState('')
  const [images, setImages] = useState<(string | null)[]>([])
  const [imagesOriginal, setImagesOriginal] = useState<(string | null)[]>([])
  const [imageBlobs, setImageBlobs] = useState<BlobType[]>([])
  const [embedTitle, setEmbedTitle] = useState('')
  const [embedDesc, setEmbedDesc] = useState('')
  const [slice, setSlice] = useState<number>(1)
  const [cropping, setCropping] = useState<boolean>(false)
  const [cropImageKey, setCropImageKey] = useState<number>(0)

  useEffect(() => {
    if (location.state === null || location.state.background === null) {
      navigateToPage('/settings/showcases', { replace: true })
    }
  }, [])

  useEffect(() => {
    let item = showcase?.items[itemKey]

    if (item) {
      item.images && setImages(item.images)
      item.images && setImagesOriginal(item.images)
      item.images && setSlice(item.images.length)
      item.link && setLink(item.link)
      item.description && setEmbedDesc(item.description)
      item.link_text && setEmbedTitle(item.link_text)
    }
    // eslint-disable-next-line
  }, [editorState])

  useEffect(() => {
    if (!slice) return
    if (images.length < slice) {
      let difference = slice - images.length
      for (let i = 0; i < difference; i++) {
        setImages(current => [...current, ''])
        setImagesOriginal(current => [...current, ''])
      }
    }
    // eslint-disable-next-line
  }, [slice])

  return (
    <>
      <div className="Image-Embed-Modal-Container-BG">
        <ClickAwayListener onClickAway={() => navigateToPage(-1)} id="Image-Showcase-Editor">
          <div className={'Modal-Container'}>
            {cropping ? (
              <CropImageModal
                image={imagesOriginal[cropImageKey]}
                updateFunction={updateCroppedImage}
                imageKey={cropImageKey}
                closeModal={() => setCropping(false)}
              />
            ) : (
              <div className="Image-Embed-Modal-Container Active-Scrollbar" id="Modal-Inner">
                <div className="Image-Embed-Modal-Container-Inner">
                  <h1>Image Showcase Editor</h1>

                  <section
                    className={`Image-Modal-Editor-Section`}
                    id={`Editor-Block-Section-Image-Editor-Modal-Title`}>
                    <div
                      className="Selection-Header-Alt"
                      onClick={() => ToggleSection(`Editor-Block-Section-Image-Editor-Modal-Title`)}>
                      <ChevronRightIcon className="Selection-Header-Chevron" />
                      <span>{`Title`}</span>
                    </div>
                    <input
                      placeholder="Add a title for your showcase"
                      className="Video-Embed-Modal-Video-Link"
                      value={embedTitle}
                      onChange={onTitleChange}
                    />
                  </section>

                  <section
                    className={`Image-Modal-Editor-Section`}
                    id={`Editor-Block-Section-Image-Editor-Modal-Title-Link`}>
                    <div
                      className="Selection-Header-Alt"
                      onClick={() => ToggleSection(`Editor-Block-Section-Image-Editor-Modal-Title-Link`)}>
                      <ChevronRightIcon className="Selection-Header-Chevron" />
                      <span>{`Title Link`}</span>
                    </div>
                    <input
                      className="Video-Embed-Modal-Video-Link"
                      type="text"
                      placeholder="Set a link for the title text"
                      value={link}
                      onChange={onLinkChange}
                    />
                  </section>

                  <section
                    className={`Image-Modal-Editor-Section`}
                    id={`Editor-Block-Section-Image-Editor-Modal-Description`}>
                    <div
                      className="Selection-Header-Alt"
                      onClick={() => ToggleSection(`Editor-Block-Section-Image-Editor-Modal-Description`)}>
                      <ChevronRightIcon className="Selection-Header-Chevron" />
                      <span>{`Description`}</span>
                    </div>
                    <input
                      className="Video-Embed-Modal-Video-Link"
                      type="text"
                      placeholder="Put a link here to embed a youtube or twitch video"
                      value={embedDesc}
                      onChange={onDescChange}
                    />
                  </section>

                  <div className="Display-Count-Options">
                    <button
                      className={`Display-Count-Option ${slice === 1 ? 'Display-Count-Option-Active' : ''}`}
                      onClick={() => setSlice(1)}>
                      <div>
                        <SquareSharpIcon />
                      </div>
                    </button>
                    <button
                      className={`Display-Count-Option ${slice === 2 ? 'Display-Count-Option-Active' : ''}`}
                      onClick={() => setSlice(2)}>
                      <div>
                        <ViewAgendaSharpIcon style={{ transform: 'rotate(90deg)' }} />
                      </div>
                    </button>
                    <button
                      className={`Display-Count-Option ${slice === 3 ? 'Display-Count-Option-Active' : ''}`}
                      onClick={() => setSlice(3)}>
                      <div>
                        <AutoAwesomeMosaicSharpIcon />
                      </div>
                    </button>
                    <button
                      className={`Display-Count-Option ${slice === 4 ? 'Display-Count-Option-Active' : ''}`}
                      onClick={() => setSlice(4)}>
                      <div>
                        <GridViewSharpIcon />
                      </div>
                    </button>
                  </div>

                  <div className="Image-Block-Item">
                    {embedTitle && (
                      <a className="Image-Block-Link" href={link ? link : '#'} rel="noreferrer" target="_blank">
                        {embedTitle}
                      </a>
                    )}
                    {embedDesc && <div className="Image-Block-Desc">{embedDesc}</div>}
                    <div className={embedTitle || embedDesc ? 'Image-Block-Margin' : 'Image-Block-Margin-Alt'}>
                      <div className={slice === 1 ? 'Image-Block-Item-Image-NoGrid' : 'Image-Block-Item-Image-Grid'}>
                        {images?.slice(0, slice).map((image, key) => (
                          <div
                            className={
                              (slice === 3 && key > 0) || slice === 4
                                ? 'Image-Block-Item-Image-Container-Alt'
                                : 'Image-Block-Item-Image-Container'
                            }
                            key={key}>
                            <input
                              id={`Image-Modal-Upload-Input-${key}`}
                              type="file"
                              onChange={e => ChangeImage(e, key)}
                              hidden
                            />
                            {image ? (
                              <>
                                <DeleteIcon
                                  className="Image-Modal-Editor-Delete-Icon"
                                  onClick={() => removeImageUpload(key)}
                                />

                                <EditIcon className="Image-Modal-Editor-Edit-Icon" onClick={() => cropImage(key)} />

                                <img
                                  className="Image-Embed-Item-Image"
                                  alt=""
                                  src={image ? image : ''}
                                  draggable
                                  onClick={() => document.getElementById(`Image-Modal-Upload-Input-${key}`)?.click()}
                                />
                              </>
                            ) : (
                              <div
                                className="Image-Modal-Editor-Item-Add"
                                onClick={() => document.getElementById(`Image-Modal-Upload-Input-${key}`)?.click()}
                                key={key}>
                                <AddIcon />
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Quick-Options-Button-Container">
                  <div className="Quick-Options-Buttons">
                    <button className="Quick-Options-Button-1" onClick={() => navigateToPage(-1)}>
                      Cancel
                    </button>
                    <button className="Quick-Options-Button-2" onClick={SaveShowcase}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </ClickAwayListener>
      </div>
    </>
  )

  function ToggleSection(sectionId: string) {
    let section = document.getElementById(sectionId)
    if (section) {
      section.classList.toggle('Editor-Block-Section-Open')
    }
  }

  function onLinkChange(e: ChangeEvent<HTMLInputElement>) {
    setLink(e.target.value)
  }
  function onTitleChange(e: ChangeEvent<HTMLInputElement>) {
    setEmbedTitle(e.target.value)
  }
  function onDescChange(e: ChangeEvent<HTMLInputElement>) {
    setEmbedDesc(e.target.value)
  }

  async function ChangeImage(e: ChangeEvent<HTMLInputElement>, key: number) {
    if (e.target.files != null) {
      let output = URL.createObjectURL(e.target.files[0])
      let blob = e.target.files[0]
      const compressedFile = await imageCompression(blob, imageCompressionOptions)
      let item = imageBlobs.filter(blob => blob.imagePosition === key)
      let newBlob: BlobType = {
        itemKey: itemKey,
        imagePosition: key,
        blob: compressedFile,
      }

      let targetBlob = (item: BlobType) => {
        if (item.imagePosition === key) {
          item = newBlob
        }
        return item
      }

      if (item.length > 0) {
        setImageBlobs(imageBlobs.map(targetBlob))
      } else {
        setImageBlobs(current => [...current, newBlob])
      }

      let targetItem = (item: string | null, itemKey: number) => {
        if (itemKey === key) {
          item = output
        }
        return item
      }
      setImages(images.map(targetItem))
      setImagesOriginal(images.map(targetItem))
    }
    return
  }

  function SaveShowcase() {
    console.log('trying to save...')
    let newShowcaseItem: any = {
      ...showcase,

      images: images.slice(0, slice),
      link_text: embedTitle,
      link: link,
      description: embedDesc,
    }
    let newBlobs = imageBlobs

    const showcaseBlock: (ImageShowcase & ShowcaseContext) | undefined | null = showcase

    if (showcaseBlock?.blobs) {
      newBlobs = [
        ...showcaseBlock.blobs.filter(
          blob => !imageBlobs.some(el => el.imagePosition === blob.imagePosition && el.itemKey === blob.itemKey)
        ),
        ...newBlobs,
      ]
    }

    if (showcaseBlock && showcaseBlock.items) {
      console.log(showcase)
      const showcaseUpdate = Object.assign({}, showcase, {
        items: [...showcase.items.slice(0, itemKey), newShowcaseItem, ...showcase.items.slice(itemKey + 1)],
        blobs: newBlobs,
      })

      editorDispatch({
        type: ActionType.secondLevelUpdate,
        payload: showcaseUpdate,
        firstLevelKey: tab,
        secondLevelKey: blockKey,
      })
    }

    navigateToPage(-1)
  }

  function removeImageUpload(keyToRemove: number) {
    let targetItem = (item: string | null, itemKey: number) => {
      if (itemKey === keyToRemove) {
        item = ''
      }
      return item
    }

    setImages(images.map(targetItem))
    setImagesOriginal(images.map(targetItem))

    return
  }

  async function cropImage(key: number) {
    setCropImageKey(key)
    setCropping(true)
  }

  function updateCroppedImage(image: string, key: number, blob: File) {
    let targetItem = (item: string | null, itemKey: number) => {
      if (itemKey === key) {
        item = image
      }
      return item
    }

    let item = imageBlobs.filter(blob => blob.imagePosition === key)
    let newBlob: BlobType = {
      itemKey: itemKey,
      imagePosition: key,
      blob: blob,
    }

    let targetBlob = (item: BlobType) => {
      if (item.imagePosition === key) {
        item = newBlob
      }
      return item
    }

    if (item.length > 0) {
      setImageBlobs(imageBlobs.map(targetBlob))
    } else {
      setImageBlobs(current => [...current, newBlob])
    }

    setImages(images.map(targetItem))
    setCropping(false)
  }
}
