import './styles/profileMenu.css'
import { useState } from 'react'
import { ReportModal } from '../reports/reportModal'
import { User } from '../../gql/generated/graphql'
import { ClickAwayListener } from '../modal/clickAwayListener'
import { useScrollRestoration } from '../../context/scrollRestorationContext'

interface ProfileMenuProps {
  closeMenuFunction: () => void
  user: User
  profileId: string
}

export function ProfileMenu({ closeMenuFunction, user, profileId }: ProfileMenuProps) {
  const { navigateToPage } = useScrollRestoration()

  const [reportModalOpen, setReportModalOpen] = useState(false)

  return (
    <ClickAwayListener onClickAway={() => closeMenuFunction()} id="Profile-Menu">
      <div className="Profile-Menu">
        {reportModalOpen && <ReportModal isUser userHandle={profileId} closeModal={closeMenuFunction} />}
        <div className="Profile-Menu-Options">
          {profileId === user.handle ? (
            <div onClick={() => navigateToPage('/settings/general')} className="Profile-Menu-Option">
              Edit Profile
            </div>
          ) : (
            <>
              <div className="Profile-Menu-Option" onClick={() => closeMenuFunction()}>
                Block (@{profileId})
              </div>
              <div className="Profile-Menu-Option Profile-Menu-Option-Red" onClick={() => setReportModalOpen(true)}>
                Report User
              </div>
            </>
          )}
        </div>
      </div>
    </ClickAwayListener>
  )
}
