import React from 'react'
import { NavLink as ReactNavLink, NavLinkProps as ReactNavLinkProps } from 'react-router-dom'
import { useScrollRestoration } from '../context/scrollRestorationContext'

export const NavLink: React.FC<ReactNavLinkProps> = ({ onClick, ...props }) => {
  const { saveScrollPosition } = useScrollRestoration()

  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = event => {
    event.stopPropagation()

    // Save the position to use for scroll restoration.
    saveScrollPosition()
    onClick?.(event)
  }
  return <ReactNavLink {...props} onClick={handleClick} />
}
