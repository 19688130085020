import React, { useReducer } from 'react'
import {
  GalleryShowcase,
  ImageShowcase,
  InfoShowcase,
  ProfileTab,
  Showcase,
  SocialLink,
  VideoShowcase,
} from '../gql/scalars'

type EditorProps = {
  children: JSX.Element
}

export type BlobType = {
  blob: File
  itemKey: number | undefined
  imagePosition: number | undefined
}
export type ShowcaseContext = {
  blobs?: BlobType[]
}

export interface EditorState {
  displayname: string
  handle: string
  profileDesc: string
  avatar: string
  avatarFrame: string
  banner: string
  profileBackground: string
  appBackground: string
  miniProfileBackground: string
  profileTabs: (ProfileTab | null)[] | null | undefined
  socialLinks: (SocialLink | null)[] | null | undefined
  profileAvatarBlob: File | undefined
  bannerBlob: File | undefined
  miniProfileBackgroundBlob: File | undefined
  appBackgroundBlob: File | undefined
  profileBackgroundBlob: File | undefined
}

const initialState: EditorState = {
  displayname: '',
  handle: '',
  profileDesc: '',
  avatar: '',
  avatarFrame: '',
  banner: '',
  profileBackground: '',
  appBackground: '',
  miniProfileBackground: '',
  profileTabs: [{ name: 'home', showcases: [] }],
  socialLinks: [],
  profileAvatarBlob: undefined,
  bannerBlob: undefined,
  miniProfileBackgroundBlob: undefined,
  appBackgroundBlob: undefined,
  profileBackgroundBlob: undefined,
}

type Action =
  | { type: ActionType.setDisplayname; payload: string }
  | { type: ActionType.setHandle; payload: string }
  | { type: ActionType.setProfileDesc; payload: string }
  | { type: ActionType.setAvatar; payload: string }
  | { type: ActionType.setAvatarFrame; payload: string }
  | { type: ActionType.setBanner; payload: string }
  | { type: ActionType.setAppBackground; payload: string }
  | { type: ActionType.setProfileBackground; payload: string }
  | { type: ActionType.setMiniProfileBackground; payload: string }
  | {
      type: ActionType.setBlockTitle
      payload: string
      blockKey: number
      tab: string
    }
  | {
      type: ActionType.setBlockDesc
      payload: string
      blockKey: number
      tab: string
    }
  | {
      type: ActionType.changeShowcaseType
      payload: Showcase
      blockKey: number
      tab: string
    }
  | {
      type: ActionType.updateState
      payload: EditorState
    }
  | {
      type: ActionType.updateAvatarBlob
      payload: File | undefined
    }
  | {
      type: ActionType.updateBannerBlob
      payload: File | undefined
    }
  | {
      type: ActionType.updateMiniProfileBackgroundBlob
      payload: File | undefined
    }
  | {
      type: ActionType.updateProfileBackgroundBlob
      payload: File | undefined
    }
  | {
      type: ActionType.updateAppBackgroundBlob
      payload: File | undefined
    }
  | {
      type: ActionType.clearBlobs
    }
  | {
      type: ActionType.addSocialLink
      payload: SocialLink
    }
  | {
      type: ActionType.deleteSocialLink
      key: number
    }
  | {
      type: ActionType.firstLevelUpdate
      payload: ProfileTab | undefined
      firstLevelKey: number
    }
  | {
      type: ActionType.secondLevelUpdate
      payload: Showcase & ShowcaseContext
      firstLevelKey: string
      secondLevelKey: number
    }
  | {
      type: ActionType.firstLevelDelete
      firstLevelKey: number
    }
  | {
      type: ActionType.secondLevelDelete
      firstLevelKey: string
      secondLevelKey: number
    }
  | {
      type: ActionType.firstLevelAdd
      payload: ProfileTab | undefined
    }
  | {
      type: ActionType.secondLevelAdd
      payload: Showcase | undefined
      firstLevelKey: string
    }
  | {
      type: ActionType.firstLevelUpdatePosition
      payload: ProfileTab | undefined
      keyToReplace: number
    }
  | {
      type: ActionType.secondLevelUpdatePosition
      payload: InfoShowcase | VideoShowcase | GalleryShowcase | ImageShowcase | Showcase | undefined
      keyToReplace: number
      firstLevelKey: string
      secondLevelKey: number
    }

/*
FirstLevelKey = Tab
SecondLevelKey = Showcase
*/

export const EditorStateContext = React.createContext({} as EditorState)
export const EditorDispatchContext = React.createContext((payload: Action) => {})

export enum ActionType {
  /*Update*/
  firstLevelUpdate,
  secondLevelUpdate,

  /*Delete*/
  firstLevelDelete,
  secondLevelDelete,

  /*Add*/
  firstLevelAdd,
  secondLevelAdd,

  /*Update Position*/
  firstLevelUpdatePosition,
  secondLevelUpdatePosition,

  /*Refactor Divider*/

  setDisplayname,
  setHandle,
  setProfileDesc,
  setAvatar,
  setAvatarFrame,
  setBanner,
  setProfileBackground,
  setAppBackground,
  setMiniProfileBackground,
  setBlockTitle,
  setBlockDesc,
  changeShowcaseType,
  updateState,
  updateAvatarBlob,
  updateBannerBlob,
  updateMiniProfileBackgroundBlob,
  updateProfileBackgroundBlob,
  updateAppBackgroundBlob,
  clearBlobs,
  addSocialLink,
  deleteSocialLink,
}

export const EditorReducer = (state: EditorState, action: Action) => {
  switch (action.type) {
    case ActionType.firstLevelAdd:
      if (state.profileTabs) {
        return {
          ...state,
          profileTabs: [...state.profileTabs, action.payload],
        }
      } else return { ...state }

    case ActionType.firstLevelDelete:
      if (state.profileTabs) {
        return {
          ...state,
          profileTabs: [
            ...state.profileTabs.slice(0, action.firstLevelKey),
            ...state.profileTabs.slice(action.firstLevelKey + 1),
          ],
        }
      } else return { ...state }
    case ActionType.firstLevelUpdate:
      if (state.profileTabs) {
        return {
          ...state,
          profileTabs: [
            ...state.profileTabs.slice(0, action.firstLevelKey),
            action.payload,
            ...state.profileTabs.slice(action.firstLevelKey + 1),
          ],
        }
      } else return { ...state }
    case ActionType.firstLevelUpdatePosition:
      if (state.profileTabs) {
        return {
          ...state,
          profileTabs: [
            ...state.profileTabs.slice(0, action.keyToReplace),
            action.payload,
            ...state.profileTabs.slice(action.keyToReplace + 1),
          ],
        }
      } else return { ...state }

    case ActionType.secondLevelAdd:
      return {
        ...state,
        profileTabs: state.profileTabs?.map((tab: any) =>
          tab?.name.toLowerCase() === action.firstLevelKey.toLowerCase()
            ? {
                ...tab,
                showcases: [...tab.showcases, action.payload],
              }
            : { ...tab }
        ),
      }
    case ActionType.secondLevelDelete:
      return {
        ...state,
        profileTabs: state.profileTabs?.map((tab: ProfileTab | null) =>
          tab?.name && tab.name.toLowerCase() === action.firstLevelKey.toLowerCase()
            ? {
                ...tab,
                showcases: tab.showcases
                  ? [
                      ...tab.showcases.slice(0, action.secondLevelKey),
                      ...tab.showcases.slice(action.secondLevelKey + 1),
                    ]
                  : [],
              }
            : { ...tab }
        ),
      }
    case ActionType.secondLevelUpdatePosition:
      return {
        ...state,
        profileTabs: state.profileTabs?.map((tab: ProfileTab | null) =>
          tab?.name && tab.name.toLowerCase() === action.firstLevelKey.toLowerCase()
            ? {
                ...tab,
                showcases: tab.showcases
                  ? [
                      ...tab.showcases.slice(0, action.keyToReplace),
                      action.payload,
                      ...tab.showcases.slice(action.keyToReplace + 1),
                    ]
                  : [],
              }
            : { ...tab }
        ),
      }
    case ActionType.secondLevelUpdate:
      return {
        ...state,
        profileTabs: state.profileTabs?.map((tab: ProfileTab | null) =>
          tab?.name && tab.name.toLowerCase() === action.firstLevelKey.toLowerCase()
            ? {
                ...tab,
                showcases: tab.showcases?.map((showcase: Showcase | null, key: number) =>
                  key === action.secondLevelKey ? action.payload : { ...showcase }
                ),
              }
            : { ...tab }
        ),
      }

    /*Refactor Divider*/

    case ActionType.updateState:
      return {
        ...state,
        displayname: action.payload.displayname,
        handle: action.payload.handle,
        profileDesc: action.payload.profileDesc,
        avatar: action.payload.avatar,
        avatarFrame: action.payload.avatarFrame,
        banner: action.payload.banner,
        appBackground: action.payload.appBackground,
        profileBackground: action.payload.profileBackground,
        miniProfileBackground: action.payload.miniProfileBackground,
        profileTabs: action.payload.profileTabs,
        socialLinks: action.payload.socialLinks,
        profileAvatarBlob: action.payload.profileAvatarBlob,
        bannerBlob: action.payload.bannerBlob,
        miniProfileBackgroundBlob: action.payload.miniProfileBackgroundBlob,
        appBackgroundBlob: action.payload.appBackgroundBlob,
        profileBackgroundBlob: action.payload.profileBackgroundBlob,
      }
    case ActionType.setDisplayname:
      return {
        ...state,
        displayname: action.payload,
      }
    case ActionType.setHandle:
      return {
        ...state,
        handle: action.payload,
      }
    case ActionType.setProfileDesc:
      return {
        ...state,
        profileDesc: action.payload,
      }
    case ActionType.setAvatar:
      return {
        ...state,
        avatar: action.payload,
      }
    case ActionType.setAvatarFrame:
      return {
        ...state,
        avatarFrame: action.payload,
      }
    case ActionType.setBanner:
      return {
        ...state,
        banner: action.payload,
      }
    case ActionType.setAppBackground:
      return {
        ...state,
        appBackground: action.payload,
      }
    case ActionType.setProfileBackground:
      return {
        ...state,
        profileBackground: action.payload,
      }
    case ActionType.addSocialLink:
      return {
        ...state,
        socialLinks:
          state.socialLinks && state.socialLinks?.length > 0
            ? [...state.socialLinks!, action.payload]
            : [action.payload],
      }

    case ActionType.deleteSocialLink:
      return {
        ...state,
        socialLinks:
          state.socialLinks && state.socialLinks?.length > 0
            ? [...state.socialLinks.slice(0, action.key), ...state.socialLinks.slice(action.key + 1)]
            : [...state.socialLinks!],
      }
    case ActionType.setMiniProfileBackground:
      return {
        ...state,
        miniProfileBackground: action.payload,
      }
    case ActionType.setBlockTitle:
      return {
        ...state,
        profileTabs: state.profileTabs?.map((tab: any) =>
          tab?.name.toLowerCase() === action.tab.toLowerCase()
            ? {
                ...tab,
                showcases: tab!.showcases?.map((showcase: Showcase | null, key: number) =>
                  key === action.blockKey ? { ...showcase, title: action.payload } : { ...showcase }
                ),
              }
            : { ...tab }
        ),
      }
    case ActionType.setBlockDesc:
      return {
        ...state,
        profileTabs: state.profileTabs?.map((tab: any) =>
          tab?.name.toLowerCase() === action.tab.toLowerCase()
            ? {
                ...tab,
                showcases: tab!.showcases?.map((showcase: Showcase | null, key: number) =>
                  key === action.blockKey ? { ...showcase, description: action.payload } : { ...showcase }
                ),
              }
            : { ...tab }
        ),
      }
    case ActionType.changeShowcaseType:
      return {
        ...state,
        profileTabs: state.profileTabs?.map((tab: any) =>
          tab?.name.toLowerCase() === action.tab.toLowerCase()
            ? {
                ...tab,
                showcases: tab!.showcases?.map((showcase: Showcase | null, key: number) =>
                  key === action.blockKey ? action.payload : { ...showcase }
                ),
              }
            : { ...tab }
        ),
      }
    case ActionType.updateAvatarBlob:
      return {
        ...state,
        profileAvatarBlob: action.payload,
      }
    case ActionType.updateBannerBlob:
      return {
        ...state,
        bannerBlob: action.payload,
      }
    case ActionType.updateMiniProfileBackgroundBlob:
      return {
        ...state,
        miniProfileBackgroundBlob: action.payload,
      }
    case ActionType.updateProfileBackgroundBlob:
      return {
        ...state,
        profileBackgroundBlob: action.payload,
      }
    case ActionType.updateAppBackgroundBlob:
      return {
        ...state,
        appBackgroundBlob: action.payload,
      }
    case ActionType.clearBlobs:
      return {
        ...state,
        profileAvatarBlob: undefined,
        bannerBlob: undefined,
        miniProfileBackgroundBlob: undefined,
        appBackgroundBlob: undefined,
        profileBackgroundBlob: undefined,
      }

    default:
      console.log(`Notice: Unhandled action: ${action}`)
      return state
  }
}

export const Editor = ({ children }: EditorProps) => {
  const [state, dispatch] = useReducer(EditorReducer, initialState)

  return (
    <EditorStateContext.Provider value={state}>
      <EditorDispatchContext.Provider value={dispatch}>{children}</EditorDispatchContext.Provider>
    </EditorStateContext.Provider>
  )
}
