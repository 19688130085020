import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Feed, FeedType } from '../components/feed/feed'
import { FeedItem } from '../components/feed/feedItem'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useCardinalContext } from '../context/cardinal'
import {
  useGetHashtagLazyQuery,
  useFollowHashtagMutation,
  useUnFollowHashtagMutation,
  User,
  Post,
  Hashtag as HashtagType,
} from '../gql/generated/graphql'
import './styles/hashtag.css'
import { PostFilterType, useListPosts } from '../gql'
import { NavBackButtonHeader } from '../navigation/navBackButtonHeader'
import { Sidebar, SidebarType } from '../components/sidebar/sidebar'

export function Hashtag() {
  const { hashtag_id } = useParams()
  const { user, browsingState } = useCardinalContext()
  const [followHashtag] = useFollowHashtagMutation()
  const [unFollowHashtag] = useUnFollowHashtagMutation()
  const [followingHashtag, setFollowingHashtag] = useState(false)
  const [getHashtag, { data: hashtagData }] = useGetHashtagLazyQuery()
  const { data, loading, fetchMore } = useListPosts(PostFilterType.ByHashtag, hashtag_id)

  useEffect(() => {
    if (hashtag_id && user?.handle) {
      getHashtag({
        variables: {
          currentUserHandle: user.handle,
          hashtagName: hashtag_id,
        },
      })
    }
  }, [browsingState, getHashtag, user?.handle])

  useEffect(() => {
    if (hashtagData)
      setFollowingHashtag(
        hashtagData.hashtag.length > 0 &&
          hashtagData?.hashtag[0]?.hashtag_followers[0]?.follower_handle === user?.handle
      )
  }, [hashtagData])

  async function toggleFollowHashtag() {
    if (followingHashtag) {
      await unFollowHashtag({
        variables: {
          currentUserHandle: user?.handle,
          tagToUnFollow: hashtag_id,
        },
      })
      setFollowingHashtag(false)
    } else {
      await followHashtag({
        variables: {
          currentUserHandle: user?.handle,
          tagToFollow: hashtag_id,
        },
      })
      setFollowingHashtag(true)
    }
  }

  const hashtagItems = useMemo(() => {
    return data?.post.map(post => {
      const currentPost = post?.repost_post || post
      return <FeedItem post={currentPost as Post} key={post.id} />
    })
  }, [data])

  return (
    <>
      <div className="Thread-Container">
        {/* <div className={`Hashtag-Follow-Button`} onClick={toggleFollowHashtag}>{`${
          followingHashtag ? 'Unfollow Hashtag' : 'Follow Hashtag'
        }`}</div> */}
        <NavBackButtonHeader title={`#${hashtag_id}`} />
        <Feed
          loading={loading}
          items={hashtagItems}
          feedType={FeedType.Post}
          fetchMore={fetchMore}
          disableCreateFeedItem
          noItemsMessage="There are no posts under this hashtag yet, be the first!"
        />
      </div>
      <Sidebar sidebarType={SidebarType.Hashtag} hashtag={hashtagData?.hashtag[0] as HashtagType} />
    </>
  )
}
