import { numberToLetterNotation } from '../../utils/converterUtils'
import { ProfileAvatar } from './display/profileAvatar'
import { VerifiedCheck } from './display/verifiedCheck'
import { ProfileMenu } from './profileMenu'
import { StreamContainer } from './streamContainer/streamContainer'
import { sanitizeHtmlConf } from '../../utils/constants'
import sanitizeHTML from 'sanitize-html'
import { useEffect, useState } from 'react'
import Twemoji from 'react-twemoji'
import { getProviderIcon } from '../../utils/statusUtils'
import { useParams } from 'react-router-dom'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined'
import HeartBrokenOutlinedIcon from '@mui/icons-material/HeartBrokenOutlined'
import { StreamChat } from './streamContainer/streamChat'
import { User } from '../../gql/generated/graphql'
import { SocialLink, StreamProvider } from '../../gql/scalars'
import i18n from 'i18next'
import './styles/profileHeader.css'
import { ProfileQuickSocials } from './display/profileQuickSocials'
import { useScrollRestoration } from '../../context/scrollRestorationContext'
import { NavLink } from '../../navigation/navLink'

interface ProfileHeaderProps {
  profile: User | undefined
  isMobileView: boolean
  user: User | undefined
  following: boolean
  follow: () => void
  unFollow: () => void
}

export function ProfileHeader({ profile, isMobileView, user, following, follow, unFollow }: ProfileHeaderProps) {
  const [profileMenuOpen, setProfileMenuOpen] = useState(false)
  const { navigateToPage } = useScrollRestoration()
  const { profile_id, profile_tab } = useParams()

  const isStreaming = !!(profile?.stream_provider && profile.stream_provider !== StreamProvider.None)

  useEffect(() => {
    const handleParallax = () => {
      let parallax: HTMLElement | null = document.getElementById('Profile-Banner-Container')
      let speed = 0.5

      if (parallax) {
        parallax.style.top = `${window.scrollY * speed}px`
      }
    }

    document.addEventListener('scroll', handleParallax)

    return () => {
      document.removeEventListener('scroll', handleParallax)
    }
  }, [])

  return (
    <>
      {profile && (
        <>
          <div className={`Profile-Header ${isStreaming ? 'Profile-Header-Streamer-Mode' : ''}`}>
            <div className={`Profile-Header-Inner ${isStreaming ? 'Profile-Header-Inner-Streamer-Mode' : ''}`}>
              {isStreaming ? (
                <div className="Profile-Stream-Elements-Container">
                  <div className="Profile-Stream-Container">
                    <StreamContainer
                      streamProvider={profile?.stream_provider}
                      streamSettings={{
                        twitchID: profile?.twitch_connection_id,
                        youtubeID: profile?.youtube_connection_id,
                      }}
                    />
                  </div>
                </div>
              ) : (
                <>
                  {profile?.banner && (
                    <div className="Profile-Banner-Container" id="Profile-Banner-Container">
                      <img alt="" src={profile.banner} />
                    </div>
                  )}
                  <div className="Profile-Banner-Spacer" />
                </>
              )}
              <div className={`Profile-Info ${isStreaming ? 'Profile-Info-Streaming-Active' : ''}`}>
                <div className={`Profile-Info-Inner ${isStreaming ? 'Profile-Info-Inner-Streaming-Active' : ''}`}>
                  <div className="Profile-Info-L">
                    <ProfileAvatar
                      customClass={isStreaming ? '' : 'Profile-Header-Avatar-Negative-Margin'}
                      size={isStreaming || isMobileView ? 'Medium' : !isStreaming ? 'Large' : ''}
                      disableMiniProfile
                      disableLink
                      user={profile}
                      profileFrame
                    />
                  </div>

                  <div className="Profile-Info-C">
                    <div className="Profile-Info-Stack">
                      {isStreaming && (
                        <span className="Profile-Stream-Status">
                          Streaming on {profile?.stream_provider}!
                          <a
                            href={
                              profile?.stream_provider === StreamProvider.Twitch
                                ? `https://www.twitch.tv/${profile?.twitch_connection_id}`
                                : `https://www.youtube.com/watch?v=${profile?.youtube_connection_id}`
                            }
                            target="_blank"
                            rel="noreferrer"
                            className={`Profile-${profile?.stream_provider}-Link`}>
                            Move to {profile?.stream_provider}.
                          </a>
                        </span>
                      )}
                      <div className="Profile-Username">
                        <Twemoji options={{ className: 'Profile-Username-Emoji' }}>{profile?.username}</Twemoji>
                        {profile.is_verified && <VerifiedCheck />}{' '}
                        <span className="Profile-Handle">[@{profile?.handle}]</span>
                        {user && (
                          <div className="Profile-Options">
                            {profileMenuOpen && (
                              <ProfileMenu
                                closeMenuFunction={() => setProfileMenuOpen(false)}
                                profileId={profile.handle}
                                user={user}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>

                    {profile?.social_links.length > 0 && !isStreaming ? (
                      <ProfileQuickSocials socialLinks={profile?.social_links as SocialLink[]} size={30} />
                    ) : (
                      <div className="Profile-Status-Bio Profile-Status-Clamp">
                        <Twemoji options={{ className: 'twemoji' }}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: profile?.status_message
                                ? sanitizeHTML(profile?.status_message, sanitizeHtmlConf)
                                : '',
                            }}
                          />
                        </Twemoji>
                      </div>
                    )}
                  </div>

                  {/* <div className="Profile-Option" onClick={() => setProfileMenuOpen(true)}>
                  <MoreHorizOutlinedIcon />
                </div> */}
                  <div className="Profile-Info-R">
                    {profile?.handle !== user?.handle && (
                      <div
                        onClick={() =>
                          navigateToPage(`/messages`, {
                            state: { openMessageId: profile?.handle ? profile?.handle : '' },
                          })
                        }
                        className="Profile-Message-Button">
                        <EmailOutlinedIcon />
                      </div>
                    )}
                    <div
                      onClick={() => {
                        if (profile?.handle === user?.handle) navigateToPage('/settings/general')
                        else if (!following) follow()
                        else if (following) unFollow()
                      }}
                      className={`Profile-Follow-Button ${following && 'Profile-Follow-Button-Active'}`}>
                      {profile?.handle === user?.handle ? (
                        'Edit Profile'
                      ) : following ? (
                        <>
                          <FavoriteOutlinedIcon className="Full-Heart" />
                          <HeartBrokenOutlinedIcon className="Break-Heart" />
                        </>
                      ) : (
                        'Follow'
                      )}
                    </div>
                  </div>
                </div>
                {!isStreaming && (
                  <>
                    <div className="Profile-Counts">
                      <NavLink to={`/profile/${profile.handle}/activity`}>
                        <h3>{i18n.t('posts')}</h3> {numberToLetterNotation(profile?.posts_aggregate?.aggregate?.count)}
                      </NavLink>
                      <NavLink to={`/following/${profile.handle}`} className="Profile-Counts-Alt">
                        <h3>{i18n.t('following')}</h3>{' '}
                        {numberToLetterNotation(profile?.following_aggregate?.aggregate?.count)}
                      </NavLink>
                      <NavLink to={`/followers/${profile.handle}`}>
                        <h3>{i18n.t('followers')}</h3>{' '}
                        {numberToLetterNotation(profile?.followers_aggregate?.aggregate?.count)}
                      </NavLink>
                    </div>

                    <div className="Profile-Status-Bio">
                      <Twemoji options={{ className: 'twemoji' }}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: profile?.bio ? sanitizeHTML(profile?.bio, sanitizeHtmlConf) : '',
                          }}
                        />
                      </Twemoji>
                    </div>
                    <div className="Profile-Tab-Container">
                      <div className="Profile-Tabs">
                        <NavLink
                          preventScrollReset
                          to={`/profile/${profile?.handle}/activity`}
                          className={profile_tab === 'activity' ? 'Profile-Tab Profile-Tab-Active' : 'Profile-Tab'}>
                          <span>{i18n.t('activity')}</span>
                        </NavLink>
                        <NavLink
                          preventScrollReset
                          to={`/profile/${profile?.handle}`}
                          className={!profile_tab ? 'Profile-Tab Profile-Tab-Active' : 'Profile-Tab'}>
                          <span>{i18n.t('home')}</span>
                        </NavLink>
                        {profile &&
                          profile.profile_tabs
                            ?.filter(tab => tab?.name !== 'Home')
                            .map((item, key) => (
                              <NavLink
                                preventScrollReset
                                to={`/profile/${profile?.handle}/${item?.name?.toLocaleLowerCase()}`}
                                className={
                                  profile_tab === item?.name?.toLocaleLowerCase()
                                    ? 'Profile-Tab Profile-Tab-Active'
                                    : 'Profile-Tab'
                                }
                                key={`${item?.name}-${key}`}>
                                <span>{item?.name}</span>
                              </NavLink>
                            ))}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {isStreaming && !isMobileView && (
              <div className="Profile-Chat-Container">
                <StreamChat
                  streamProvider={profile?.stream_provider}
                  streamSettings={{
                    twitchID: profile?.twitch_connection_id,
                    youtubeID: profile?.youtube_connection_id,
                  }}
                />
              </div>
            )}
          </div>
          {isStreaming && (
            <>
              <div className="Profile-Header-Lower">
                <div className="Profile-Counts">
                  <NavLink to={`/profile/${profile.handle}/activity`}>
                    <h3>Posts</h3> {numberToLetterNotation(profile?.posts_aggregate?.aggregate?.count)}
                  </NavLink>
                  <NavLink to={`/following/${profile.handle}`} className="Profile-Counts-Alt">
                    <h3>Following</h3> {numberToLetterNotation(profile?.following_aggregate?.aggregate?.count)}
                  </NavLink>
                  <NavLink to={`/followers/${profile.handle}`}>
                    <h3>Followers</h3> {numberToLetterNotation(profile?.followers_aggregate?.aggregate?.count)}
                  </NavLink>
                  {profile?.social_links.length > 0 && (
                    <ProfileQuickSocials socialLinks={profile?.social_links as SocialLink[]} size={30} />
                  )}
                </div>

                <div className="Profile-Status-Bio">
                  <Twemoji options={{ className: 'twemoji' }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: profile?.bio ? sanitizeHTML(profile?.bio, sanitizeHtmlConf) : '',
                      }}
                    />
                  </Twemoji>
                </div>
                <div className="Profile-Tab-Container">
                  <div className="Profile-Tabs">
                    <NavLink
                      preventScrollReset
                      to={`/profile/${profile?.handle}/activity`}
                      className={profile_tab === 'activity' ? 'Profile-Tab Profile-Tab-Active' : 'Profile-Tab'}>
                      <span>Activity</span>
                    </NavLink>
                    <NavLink
                      preventScrollReset
                      to={`/profile/${profile?.handle}`}
                      className={!profile_tab ? 'Profile-Tab Profile-Tab-Active' : 'Profile-Tab'}>
                      <span>Home</span>
                    </NavLink>
                    {profile &&
                      profile.profile_tabs
                        ?.filter(tab => tab?.name !== 'Home')
                        .map((item, key) => (
                          <NavLink
                            preventScrollReset
                            to={`/profile/${profile?.handle}/${item?.name?.toLocaleLowerCase()}`}
                            className={
                              profile_tab === item?.name?.toLocaleLowerCase()
                                ? 'Profile-Tab Profile-Tab-Active'
                                : 'Profile-Tab'
                            }
                            key={`${item?.name}-${key}`}>
                            <span>{item?.name}</span>
                          </NavLink>
                        ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}
