import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useCardinalContext } from '../context/cardinal'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ExploreIcon from '@mui/icons-material/Explore'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import LoginIcon from '@mui/icons-material/Login'
import './styles/nav.css'
import { NavProfileMenu } from './navProfileMenu'
import { NavNotificationMenu } from './navNotificationMenu'
import Twemoji from 'react-twemoji'
import {
  useGetNotificationsLazyQuery,
  GetNotificationsQuery,
  GetNotificationsQueryVariables,
} from '../gql/generated/graphql'
import { LazyQueryHookOptions } from '@apollo/client'
import i18n from 'i18next'
import { useScrollRestoration } from '../context/scrollRestorationContext'
import { NavLink } from './navLink'

export function Nav() {
  useEffect(() => {
    i18n.changeLanguage('en')
  }, [])

  const location = useLocation()
  const { navigateToPage } = useScrollRestoration()
  const { user, isMobileView } = useCardinalContext()
  const [getNotifications, { data, loading }] = useGetNotificationsLazyQuery()
  const [navProfileMenuOpen, setNavProfileMenuOpen] = useState<boolean>(false)
  const [navNotificationMenuOpen, setNavNotificationMenuOpen] = useState<boolean>(false)

  let queryOptions: LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables> = {
    variables: {
      notificationFilter: {
        owner_handle: { _eq: user?.handle },
        seen: { _eq: false },
      },
      currentUserHandle: user?.handle ?? '',
    },
  }

  useEffect(() => {
    if (user) getNotifications(queryOptions)
  }, [user])

  function openNofiticationsMenu(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setNavNotificationMenuOpen(true)
  }

  return (
    <>
      <div className="Navbar">
        <div className="Navbar-Inner-Wrapper">
          <div className="Navbar-Inner-L">
            <NavLink to="/" className={`Header-Name-Title `}>
              <span>SaySo</span>
              <span className="Title-Alt">SS</span>
            </NavLink>
            <NavLink to="/browse" className="Nav-Option Nav-Option-Mobile">
              <span>{i18n.t('browse')}</span>
              <ExploreIcon style={{ fontSize: '2.3rem' }} />
            </NavLink>
            <NavLink to="/roadmap" className="Nav-Option Nav-Option-Mobile">
              <span>{i18n.t('about')}</span>
              <MoreVertIcon style={{ fontSize: '2.3rem' }} />
            </NavLink>
          </div>
          {/* <div className="Navbar-Inner-C">
            <input placeholder="Search" className="Navbar-Searchbar" />
          </div> */}
          <div className="Navbar-Inner-R">
            <button className="Nav-Option Nav-Option-Mobile" onClick={() => navigateToPage('/search')} title="Search">
              <SearchOutlinedIcon style={{ transform: 'scaleX(-1)', fontSize: '2.1rem' }} />
            </button>
            {user ? (
              <>
                <button
                  className="Nav-Option Nav-Option-Mobile"
                  onClick={() => navigateToPage('/create/post', { state: { background: location } })}
                  title="Create Post">
                  <AddOutlinedIcon style={{ fontSize: '2.3rem' }} />
                </button>
                <button
                  className={`Nav-Option  ${navNotificationMenuOpen && 'Nav-Option-Active'} ${
                    data &&
                    data.user_notifications_aggregate &&
                    data.user_notifications_aggregate.aggregate &&
                    data.user_notifications_aggregate.aggregate?.count > 0 &&
                    'Notifications-Active'
                  }`}
                  onClick={openNofiticationsMenu}
                  title="Notifications">
                  <ChatOutlinedIcon />
                  {data &&
                    data.user_notifications_aggregate &&
                    data.user_notifications_aggregate.aggregate &&
                    data.user_notifications_aggregate.aggregate?.count > 0 && (
                      <span className="Notfications-Count">{data.user_notifications_aggregate.aggregate?.count}</span>
                    )}
                </button>
                <button
                  className={`Nav-Option Nav-Option-Avatar ${navProfileMenuOpen && 'Nav-Option-Active'}`}
                  onClick={() => {
                    if (isMobileView) {
                      navigateToPage('/nav/mobile-view', { state: { background: location } })
                    } else {
                      setNavProfileMenuOpen(!navProfileMenuOpen)
                    }
                  }}>
                  {user?.username && (
                    <>
                      <ArrowDropDownOutlinedIcon style={{ marginRight: '5px' }} />
                      <span className="Nav-Displayname">
                        <Twemoji options={{ className: 'Nav-Displayname-Emojis' }}>{user?.username}</Twemoji>
                      </span>
                    </>
                  )}
                  <div
                    className={`Avatar Navbar-Avatar ${
                      user?.stream_provider !== 'None' && `Navbar-Avatar-Live-${user?.stream_provider}`
                    }`}
                    draggable>
                    <div
                      className="Avatar-Image"
                      style={{
                        backgroundImage: `url(${user?.profile_image})`,
                      }}
                    />
                  </div>
                </button>
                {navProfileMenuOpen && <NavProfileMenu closeMenuFunction={() => setNavProfileMenuOpen(false)} />}
                {navNotificationMenuOpen && (
                  <NavNotificationMenu
                    notificationCount={data?.user_notifications_aggregate.aggregate?.count}
                    data={data}
                    closeMenuFunction={() => setNavNotificationMenuOpen(false)}
                  />
                )}
              </>
            ) : (
              <NavLink className="Nav-Option Nav-Option-Mobile" to="/login" state={{ background: location }}>
                <LoginIcon style={{ marginRight: '5px' }} />
                LOGIN
              </NavLink>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
