import moment from 'moment'
import Twemoji from 'react-twemoji'
import sanitizeHTML from 'sanitize-html'
import { ProfileAvatar } from '../../profile/display/profileAvatar'
import { User, User_Mentions } from '../../../gql/generated/graphql'
import { VerifiedCheck } from '../../profile/display/verifiedCheck'
import { useScrollRestoration } from '../../../context/scrollRestorationContext'
import { NavLink } from '../../../navigation/navLink'
import { MediaUrl } from '../../../gql'
import { Image } from '../../uiKit/image'

interface QuoteRepostItemProps {
  id: string
  timestamp: string
  owner?: User
  text?: string | null
  gifUrl?: MediaUrl | null
  imageUrls?: (MediaUrl | null)[]
  videoUrl?: string | null
  replyToHandle?: string
  quotePostMentions?: User_Mentions[]
  quotePost?: boolean
}

export function QuoteRepostItem({
  id,
  timestamp,
  owner,
  text,
  gifUrl,
  imageUrls,
  videoUrl,
  replyToHandle,
  quotePostMentions,
  quotePost,
}: QuoteRepostItemProps) {
  moment.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s',
      s: '%ds',
      m: '%dm',
      mm: '%dm',
      h: '%dh',
      hh: '%dh',
      d: '%dd',
      dd: '%dd',
    },
  })
  const sanitizeConf = {
    allowedTags: ['img', 'p', 'span', 'br', 'a'],
    allowedAttributes: {
      img: ['src', 'alt', 'class', 'title'],
      span: ['class'],
      a: ['class', 'href', 'data-seiso-miniprofile'],
    },
  }
  const { navigateToPage } = useScrollRestoration()

  function getHandleFromMention(mentionId: string) {
    return quotePostMentions?.find(mention => mention.id === mentionId)?.mentioned_handle
  }

  return (
    <div className={`Post-Container ${quotePost ? 'Quote-Post-Container' : 'Focused-Post-Container'}`}>
      <div
        className="Post-Container-Inner"
        onClick={(e: any) => {
          e.stopPropagation()
          e.preventDefault()
          let sel = window.getSelection()
          if (sel?.type !== 'Range') {
            if (e.target instanceof HTMLAnchorElement) {
              if (e.target.className === 'Post-Mention') {
                navigateToPage(`/profile/${getHandleFromMention(e.target.getAttribute('data-seiso-miniprofile'))}`)
              } else if (e.target.className === 'Post-Hashtag') {
                navigateToPage(`/hashtag/${e.target.innerText.replace('#', '')}`)
              }
            } else if (e.target.parentElement instanceof HTMLAnchorElement) {
              window.open(e.target.parentElement.href, '_blank')
            } else {
              navigateToPage(`/post/${owner?.handle}/${id}`)
            }
          }
        }}>
        <div className="Post-Body">
          <div className="Post-Left">
            <ProfileAvatar size="Small" user={owner} />
          </div>
          <div className="Post-Right">
            <div className="Post-Header">
              <div className="Post-Info">
                <div className="Post-User-Info">
                  <NavLink to={`/profile/${owner?.handle}`} className="Post-User-Info">
                    <span className="Post-Displayname">
                      <Twemoji options={{ className: 'Post-Displayname-Emoji' }}>
                        {owner?.username}
                        {owner?.is_verified && <VerifiedCheck />}
                      </Twemoji>
                    </span>
                    <span data-seiso-miniprofile={owner?.handle} className="Post-Username">
                      @{owner?.handle}
                    </span>
                  </NavLink>
                </div>
              </div>
              <div className="Post-Timestamp">
                <time dateTime={`${timestamp}`}>
                  {moment().diff(moment(timestamp), 'hours') >= 23
                    ? moment(timestamp).format('MMM Do')
                    : moment(timestamp).fromNow()}
                </time>
              </div>
            </div>
            {replyToHandle && (
              <div className="Reply-Indicator">
                Replying to{' '}
                <NavLink data-seiso-miniprofile={replyToHandle} to={`/profile/${replyToHandle}`}>
                  @{`${replyToHandle}`}
                </NavLink>
              </div>
            )}
            <div className="Post-Content">
              <div className="Post-Text">
                <Twemoji options={{ className: 'Post-Emoji' }}>
                  <div dangerouslySetInnerHTML={{ __html: text ? sanitizeHTML(text, sanitizeConf) : '' }} />
                </Twemoji>
              </div>
              {((imageUrls && imageUrls.length > 0) || videoUrl || gifUrl) && (
                <div className="Post-Non-Text">
                  {imageUrls && imageUrls?.length > 0 && (
                    <>
                      {imageUrls.length === 1 ? (
                        imageUrls?.map((image, key) => <Image key={key} className="Post-Image" mediaUrl={image} />)
                      ) : (
                        <div className="Post-Images-Grid">
                          {imageUrls?.slice(0, 4).map((image, key) => (
                            <div
                              className={
                                imageUrls?.length === 2
                                  ? 'Post-Images-Grid-Item'
                                  : imageUrls?.length === 3 && key === 0
                                  ? 'Post-Images-Grid-Item'
                                  : imageUrls?.length === 3 && key > 0
                                  ? 'Post-Images-Grid-Item-Alt'
                                  : 'Post-Images-Grid-Item-Alt'
                              }
                              key={key}>
                              <Image className="Post-Image-Alt" mediaUrl={image} />
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  )}

                  {videoUrl && (
                    <div className="Post-Video-Container">
                      <video
                        className="Post-Video"
                        controls
                        autoPlay
                        playsInline
                        muted
                        loop
                        src={videoUrl}
                        onClick={e => {
                          e.stopPropagation()
                        }}
                      />
                    </div>
                  )}
                  {gifUrl && <Image className="Post-Image" mediaUrl={gifUrl} draggable />}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
