import { useEffect } from 'react'
import { BrowsingState, useCardinalContext } from '../../../context/cardinal'
import { useListTrendingHashtagsLazyQuery } from '../../generated/graphql'

export function useListTrendingHashtags() {
  const { browsingState } = useCardinalContext()
  const [listTrendingHashtags, { data, loading }] = useListTrendingHashtagsLazyQuery()

  useEffect(() => {
    if (browsingState !== BrowsingState.Loading) {
      listTrendingHashtags({ variables: { limit: 10 }, fetchPolicy: 'cache-and-network' })
    }
  }, [browsingState, listTrendingHashtags])

  return { data, loading }
}
