import { useEffect, useMemo } from 'react'
import {
  GetLiveUsersQuery,
  GetLiveUsersQueryVariables,
  Order_By,
  User,
  useGetLiveUsersLazyQuery,
} from '../../../gql/generated/graphql'
import { StreamProvider } from '../../../gql'
import Twemoji from 'react-twemoji'
import { ProfileAvatar } from '../../profile/display/profileAvatar'
import { sanitizeHtmlConf } from '../../../utils/constants'
import sanitizeHTML from 'sanitize-html'
import { LazyQueryHookOptions } from '@apollo/client'
import { useScrollRestoration } from '../../../context/scrollRestorationContext'
import { NavLink } from '../../../navigation/navLink'

import i18n from 'i18next'

export function HashtagLiveLego() {
  const { navigateToPage } = useScrollRestoration()
  const [getLiveUsers, { data: liveUserData }] = useGetLiveUsersLazyQuery()

  let liveUserQueryOptions: LazyQueryHookOptions<GetLiveUsersQuery, GetLiveUsersQueryVariables> = {
    variables: {
      limit: 6,
      order_by: { followers_aggregate: { count: Order_By.Desc } },
    },
  }

  useEffect(() => {
    getLiveUsers(liveUserQueryOptions)
  }, [])

  const liveUsers = useMemo(() => {
    return liveUserData?.user.map((user, key) => (
      <div
        className="Lego-Live-Profiles-Preview-Item"
        style={{
          backgroundImage: `url(${
            user.stream_provider === StreamProvider.Twitch
              ? `https://static-cdn.jtvnw.net/previews-ttv/live_user_${user.twitch_connection_id?.toLocaleLowerCase()}-1920x1080.jpg`
              : `http://img.youtube.com/vi/${user.youtube_connection_id}/maxresdefault.jpg`
          })`,
        }}
        onClick={() => navigateToPage(`/profile/${user.handle}`)}
        key={`${user.handle}-${key}`}>
        <div className="Lego-Live-Profiles-Preview-Item-Fade"></div>
        <div className="Lego-Live-Profiles-Preview-Item-Status">
          <Twemoji options={{ className: 'twemoji' }}>
            <div
              dangerouslySetInnerHTML={{
                __html: user.status_message ? sanitizeHTML(user.status_message, sanitizeHtmlConf) : '',
              }}
            />
          </Twemoji>
        </div>
        <div className="Lego-Live-Profiles-Preview-Item-Info-Displayname">
          <ProfileAvatar size="Small" disableLink disableMiniProfile disableStatus user={user as User} />
          <span>
            <Twemoji options={{ className: 'Lego-Live-Profiles-Preview-Item-Info-Displayname-Emoji' }}>
              {user.username}
            </Twemoji>
          </span>
        </div>
        <div className="Lego-Live-Profiles-Preview-Item-Stream-Status">
          Live on{' '}
          <span className={`Lego-Live-Profiles-Preview-Item-Stream-Status-${user.stream_provider}`}>
            {user.stream_provider}
          </span>
        </div>
      </div>
    ))
  }, [liveUserData])

  return (
    <>
      {liveUsers && liveUsers?.length > 0 && (
        <div className="Sidebar-Container-Item">
          <h2 className="Sidebar-Container-Item-Header">Live On Tag</h2>

          <div className="Sidebar-Trending-Container">
            <div className="Lego-Live-Profiles-Preview">{liveUsers}</div>
          </div>
          <NavLink to="/browse" className="Sidebar-Showmore-Link">
            {i18n.t('see-more')}
          </NavLink>
        </div>
      )}
    </>
  )
}
