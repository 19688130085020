import { useEffect, useMemo } from 'react'
import { GetPostQuery, GetPostQueryVariables, useGetPostLazyQuery } from '../../generated/graphql'
import { BrowsingState, useCardinalContext } from '../../../context/cardinal'
import { LazyQueryHookOptions } from '@apollo/client'

export function useGetPost(id?: string) {
  const { user, browsingState } = useCardinalContext()
  const [getPost, { data, loading }] = useGetPostLazyQuery()

  const queryOptions: LazyQueryHookOptions<GetPostQuery, GetPostQueryVariables> = useMemo(
    () => ({
      variables: {
        id: id,
        currentUserHandle: user?.handle ?? '',
      },
      fetchPolicy: 'cache-and-network',
    }),
    [user?.handle, id]
  )

  useEffect(() => {
    if (browsingState !== BrowsingState.Loading && id) {
      getPost(queryOptions)
    }
  }, [browsingState, id, getPost, queryOptions])

  return {
    data,
    loading,
  }
}
