import { useEffect, useRef, useState } from 'react'
import { MediaUrl } from '../../gql'
import './styles/video.css'
import { Loading } from './loading/loading'

interface VideoProps extends React.VideoHTMLAttributes<HTMLVideoElement> {
  mediaUrl: MediaUrl | null
}

const MAX_HEIGHT = 600

export function Video({ mediaUrl, ...props }: VideoProps) {
  const [videoLoaded, setVideoLoaded] = useState(false)
  const [videoDimensions, setVideoDimensions] = useState({ width: 0, height: 0 })
  const containerRef = useRef<HTMLDivElement | null>(null)
  const videoRef = useRef<HTMLVideoElement | null>(null)

  // Define dimensions of loading container
  useEffect(() => {
    const containerWidth = containerRef.current?.offsetWidth

    if (mediaUrl?.width && mediaUrl?.height && containerWidth) {
      const aspectRatio = mediaUrl.width / mediaUrl.height
      const maxWidth = Math.min(containerWidth, mediaUrl.width)
      const calculatedWidth = Math.min(containerWidth, mediaUrl.width)
      const calculatedHeight = Math.min(maxWidth / aspectRatio, MAX_HEIGHT)

      setVideoDimensions({ width: calculatedWidth, height: calculatedHeight })
    }
  }, [mediaUrl])

  return (
    <div ref={containerRef} className={'Video-Element-Container'}>
      {!videoLoaded && (
        <>
          <video
            width={videoDimensions.width}
            height={videoDimensions.height}
            className={`Image-Placeholder ${props.className}`}
          />
          <Loading disableText className="Video-Placeholder-Loading" style={{ width: videoDimensions.width }} />
        </>
      )}
      <video
        {...props}
        ref={videoRef}
        src={mediaUrl?.url}
        style={videoLoaded ? undefined : { width: 0, height: 0, border: 0 }}
        onLoadedData={() => setVideoLoaded(true)}
      />
    </div>
  )
}
