import './styles/button.css'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  appearance?: 'Primary' | 'Secondary' | 'Reverse' | 'Transparent'
}

export function Button({ appearance = 'Primary', children, ...props }: ButtonProps) {
  return (
    <button {...props} className={`Button ${appearance} ${props.className ?? ''}`}>
      {children}
    </button>
  )
}
