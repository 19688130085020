import { User, useFollowMutation, useUnFollowMutation } from '../../../gql/generated/graphql'
import { ProfileAvatar } from './profileAvatar'
import { VerifiedCheck } from './verifiedCheck'
import Twemoji from 'react-twemoji'
import { sanitizeHtmlConf } from '../../../utils/constants'
import sanitizeHTML from 'sanitize-html'
import { getProviderIcon } from '../../../utils/statusUtils'
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined'
import HeartBrokenOutlinedIcon from '@mui/icons-material/HeartBrokenOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import './styles/listProfileItem.css'
import { useEffect, useState } from 'react'
import { useCardinalContext } from '../../../context/cardinal'
import { useScrollRestoration } from '../../../context/scrollRestorationContext'

interface ListProfileItemProps {
  user: User
  index: number
  disableSocialLinks?: boolean
  disableFollowButton?: boolean
  disableMessageButton?: boolean
  avatarSize?: string
  profileFrame?: boolean
}

export function ListProfileItem({
  user,
  index,
  disableSocialLinks,
  disableFollowButton,
  disableMessageButton,
  avatarSize,
  profileFrame,
}: ListProfileItemProps) {
  const { user: currentUser } = useCardinalContext()
  const { navigateToPage } = useScrollRestoration()
  const [following, setFollowing] = useState(false)
  const [unFollowUser] = useUnFollowMutation()
  const [followUser] = useFollowMutation()

  async function toggleFollow(handle: string) {
    if (currentUser) {
      if (following) {
        await unFollowUser({
          variables: {
            currentUserHandle: currentUser.handle,
            handleToUnFollow: handle,
          },
        })
        setFollowing(false)
      } else if (!following) {
        await followUser({
          variables: {
            currentUserHandle: currentUser.handle,
            handleToFollow: handle,
          },
        })
        setFollowing(true)
      }
    }
  }

  useEffect(() => {
    setFollowing(user?.followers && user.followers.length > 0)
  }, [currentUser])

  return (
    <div
      className="Profile-List-Item"
      id="Profile-List-Item"
      onClick={e => {
        let sel = window.getSelection()
        if (sel?.type === 'Caret') navigateToPage(`/profile/${user.handle}`)
      }}
      key={index}>
      <ProfileAvatar size={avatarSize} user={user} profileFrame={profileFrame} />
      <div className="Profile-List-Info-Container">
        <div className="Profile-List-Info-Top">
          <div className="Profile-List-Info-Left">
            <span className="Profile-List-Username">
              <Twemoji options={{ className: 'Post-Displayname-Emoji' }}>
                {user?.username}
                {user?.is_verified && <VerifiedCheck />}
              </Twemoji>
            </span>
            <span className="Profile-List-Handle">@{user?.handle}</span>
          </div>
          <div className="Profile-Socials Fade_In Profile-List-Socials">
            {!disableSocialLinks &&
              user?.social_links?.slice(0, 5).map((socialLink, key) => (
                <a
                  href={socialLink?.url}
                  target="_blank"
                  rel="noreferrer"
                  className="Social-Icon-Container Profile-List-Social-Icon-Container"
                  key={`${socialLink?.url}-${key}`}>
                  <div className={`Social-Icon ${socialLink?.provider}-Icon`}>
                    <img alt="" src={getProviderIcon(socialLink?.provider) ?? socialLink?.icon} />
                  </div>
                </a>
              ))}
            {!disableMessageButton && user?.handle !== currentUser?.handle && (
              <div
                onClick={e => {
                  e.stopPropagation()
                  let sel = window.getSelection()
                  const id = [user?.handle, currentUser?.handle].sort().join('-')
                  if (sel?.type === 'Caret') {
                    navigateToPage(`/messages/${id.toLowerCase()}`)
                  }
                }}
                className="Mini-Profile-Message-Button">
                <EmailOutlinedIcon />
              </div>
            )}
            {!disableFollowButton && (
              <div
                className={` Mini-Profile-Follow-Button ${following && 'Profile-Follow-Button-Active'}`}
                onClick={e => {
                  e.stopPropagation()
                  if (currentUser?.handle === user?.handle) {
                    navigateToPage('/settings/general')
                  } else {
                    toggleFollow(user.handle)
                  }
                }}>
                {user?.handle === currentUser?.handle ? (
                  'Edit Profile'
                ) : following ? (
                  <>
                    <FavoriteOutlinedIcon className="Full-Heart" />
                    <HeartBrokenOutlinedIcon className="Break-Heart" />
                  </>
                ) : (
                  'Follow'
                )}
              </div>
            )}
          </div>
        </div>
        <div className="Profile-List-Status">
          <Twemoji options={{ className: 'Post-Emoji' }}>
            <div
              dangerouslySetInnerHTML={{
                __html: user?.status_message ? sanitizeHTML(user?.status_message, sanitizeHtmlConf) : '',
              }}
            />
          </Twemoji>
        </div>
      </div>
    </div>
  )
}
