/*Components*/
import { ThemeManager } from './managers/themeManager/themeManager'

/*Cardinal*/
import { CardinalProvider } from './context/cardinal'
import { Editor } from './context/editorContext'
/*Styles*/
import './globalstyles/App.css'
import './globalstyles/Crop.css'
import './globalstyles/Themes.css'

import { AppRouter } from './AppRouter'
import { RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { CardinalApolloProvider } from './apollo/cardinalApolloProvider'
import { AuthProvider } from './context/authContext'
import { initializeApp } from 'firebase/app'
import { browserLocalPersistence, getAuth } from 'firebase/auth'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyB93gQ2aNLlTyG17aefkQ5TTiPURGy5EfM',
  authDomain: 'hasura-project113.firebaseapp.com',
  projectId: 'hasura-project113',
  storageBucket: 'hasura-project113.appspot.com',
  messagingSenderId: '647980309590',
  appId: '1:647980309590:web:58c7894a4af32086f4aff7',
}

function App() {
  const isDev = process.env.NODE_ENV === 'development' ? true : false

  // Initilize firebase.
  const firebaseApp = initializeApp(firebaseConfig)
  getAuth(firebaseApp).setPersistence(browserLocalPersistence)

  if (isDev) {
    const functions = getFunctions()
    connectFunctionsEmulator(functions, '127.0.0.1', 5001)
  }

  //The prodigal son has finally returned kek...

  return (
    <AuthProvider>
      <CardinalApolloProvider>
        <CardinalProvider>
          <Editor>
            <ThemeManager>
              <div className="App">
                <ToastContainer theme="dark" position="bottom-left" limit={5} closeOnClick={false} />
                <RouterProvider router={AppRouter} />
              </div>
            </ThemeManager>
          </Editor>
        </CardinalProvider>
      </CardinalApolloProvider>
    </AuthProvider>
  )
}

export default App
