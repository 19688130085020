import { useEffect, useState } from 'react'
import { Hashtag, useFollowHashtagMutation, useUnFollowHashtagMutation } from '../../../gql/generated/graphql'
import { numberToLetterNotation } from '../../../utils/converterUtils'
import './hashtagLego.css'
import { HashtagLiveLego } from './hashtagLiveLego'
import { useCardinalContext } from '../../../context/cardinal'

interface HashtagLegoProps {
  hashtag: Hashtag
}

export function HashtagLego({ hashtag }: HashtagLegoProps) {
  const { user, browsingState } = useCardinalContext()
  const [followingHashtag, setFollowingHashtag] = useState(false)
  const [followHashtag] = useFollowHashtagMutation()
  const [unFollowHashtag] = useUnFollowHashtagMutation()

  useEffect(() => {
    if (hashtag) setFollowingHashtag(hashtag.hashtag_followers[0]?.follower_handle === user?.handle)
  }, [hashtag, user?.handle, browsingState])

  async function toggleFollowHashtag() {
    if (user?.handle)
      if (followingHashtag) {
        await unFollowHashtag({
          variables: {
            currentUserHandle: user?.handle,
            tagToUnFollow: hashtag.name,
          },
        })
        setFollowingHashtag(false)
      } else {
        await followHashtag({
          variables: {
            currentUserHandle: user?.handle,
            tagToFollow: hashtag.name,
          },
        })
        setFollowingHashtag(true)
      }
  }

  return (
    <>
      <div className="Sidebar-Container-Item">
        <h2 className="Sidebar-Container-Item-Header">#{hashtag?.name?.toLocaleUpperCase()}</h2>
        <div className="Hashtag-Stats">
          <div>
            <h3>Followers:</h3>
            <span>{numberToLetterNotation(hashtag.hashtag_followers_aggregate.aggregate?.count)}</span>
          </div>
          <div>
            <h3>Posts:</h3> <span>{numberToLetterNotation(hashtag.hashtaged_posts_aggregate.aggregate?.count)}</span>
          </div>
          <div>
            <h3>Live Users:</h3>
            <span>{numberToLetterNotation(130000)}</span>
          </div>
        </div>
        <button className="Hashtag-Follow-Button" onClick={() => toggleFollowHashtag()}>
          {followingHashtag ? 'UnFollow' : 'Follow'}
        </button>
      </div>

      <HashtagLiveLego />
    </>
  )
}
