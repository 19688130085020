import type { Spread } from 'lexical'

import {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  LexicalNode,
  NodeKey,
  SerializedTextNode,
  TextNode,
} from 'lexical'

export type SerializedMentionNode = Spread<
  {
    mentionName: string
    type: 'mention'
    version: 1
  },
  SerializedTextNode
>

const mentionStyle = ''
export class MentionNode extends TextNode {
  __mention: string
  __id: string

  static getType(): string {
    return 'mention'
  }

  static clone(node: MentionNode): MentionNode {
    return new MentionNode(node.__mention, node.__text, node.__key)
  }
  static importJSON(serializedNode: SerializedMentionNode): MentionNode {
    const node = $createMentionNode(serializedNode.mentionName)
    node.setTextContent(serializedNode.text)
    node.setFormat(serializedNode.format)
    node.setDetail(serializedNode.detail)
    node.setMode(serializedNode.mode)
    node.setStyle(serializedNode.style)
    return node
  }

  constructor(mentionName: string, text?: string, key?: NodeKey) {
    super(text ?? mentionName, key)
    this.__mention = mentionName
    this.__id = crypto.randomUUID()
  }

  setId(id: string): void {
    const self = this.getWritable()
    self.__id = id
  }

  exportJSON(): SerializedMentionNode {
    return {
      ...super.exportJSON(),
      mentionName: this.__mention,
      type: 'mention',
      version: 1,
    }
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config)
    dom.style.cssText = mentionStyle
    dom.className = 'Post-Mention'
    dom.setAttribute('data-seiso-mention', 'true')
    dom.setAttribute('data-seiso-miniprofile', this.__id)

    return dom
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('a')
    element.setAttribute('data-seiso-mention', 'true')
    element.setAttribute('data-seiso-miniprofile', this.__id)
    element.className = 'Post-Mention'

    element.textContent = this.__text

    return { element }
  }

  static importDOM(): DOMConversionMap | null {
    return {
      a: (node: Node) => {
        if (node instanceof HTMLAnchorElement && node.className === 'Post-Mention') {
          return { conversion: convertMentionElement, priority: 3 }
        }
        return null
      },
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute('data-seiso-mention')) {
          return null
        }
        return {
          conversion: convertMentionElement,
          priority: 1,
        }
      },
    }
  }

  isTextEntity(): true {
    return true
  }
}

function convertMentionElement(domNode: HTMLElement): DOMConversionOutput | null {
  const textContent = domNode.textContent

  if (textContent !== null) {
    const node = $createMentionNode(textContent.replaceAll('@', ''))
    return {
      node,
    }
  }

  return null
}

export function $createMentionNode(mentionName: string): MentionNode {
  const mentionNode = new MentionNode('@' + mentionName)
  mentionNode.setMode('token').toggleDirectionless()
  return mentionNode
}

export function $isMentionNode(node: LexicalNode | null | undefined): node is MentionNode {
  return node instanceof MentionNode
}
