import { useEffect, useRef, useState } from 'react'
import { MediaUrl } from '../../gql'
import './styles/image.css'
import { Loading } from './loading/loading'

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  mediaUrl: MediaUrl | null
  mainGridItem?: boolean
  altGridItem?: boolean
}

const MAX_HEIGHT = 600

export function Image({ mediaUrl, mainGridItem, altGridItem, ...props }: ImageProps) {
  const [imageLoaded, setImageLoaded] = useState(false)
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 })
  const containerRef = useRef<HTMLDivElement | null>(null)

  // Define dimensions of loading container
  useEffect(() => {
    const containerWidth = containerRef.current?.offsetWidth

    if (mediaUrl?.width && mediaUrl?.height && containerWidth) {
      const aspectRatio = mediaUrl.width / mediaUrl.height
      const maxWidth = Math.min(containerWidth, mediaUrl.width)
      const calculatedWidth = Math.min(containerWidth, mediaUrl.width)
      const calculatedHeight = Math.min(maxWidth / aspectRatio, MAX_HEIGHT)

      setImageDimensions({ width: calculatedWidth, height: calculatedHeight })
    }
  }, [mediaUrl])

  return (
    <div ref={containerRef} className={`Image-Container`}>
      {!imageLoaded && (
        <>
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
            width={imageDimensions.width}
            height={imageDimensions.height}
            className={`Image-Placeholder ${mainGridItem ? 'Main-Grid-Image' : ''}${
              !mainGridItem && !altGridItem ? props.className : ''
            }`}
            alt=""
          />
          <Loading disableText className="Image-Placeholder-Loading" style={{ width: imageDimensions.width }} />
        </>
      )}
      <img
        {...props}
        src={mediaUrl?.url}
        alt={props.alt ?? ''}
        style={imageLoaded ? undefined : { width: 0, height: 0, border: 0 }}
        onLoad={() => setImageLoaded(true)}
      />
    </div>
  )
}
