import { createContext, useContext, useEffect, useState } from 'react'
import {
  GetUserDocument,
  User,
  useGetSignInUserLazyQuery,
  useGetUserFollowerContentLazyQuery,
  Follower_Content,
} from '../gql/generated/graphql'
import { AuthenticationState, useAuthContext } from './authContext'
import { CustomEmoteCategoryType, mergeUserFollowerEmojis } from './cardinalHelper'
import { useApolloClient } from '@apollo/client'
import { getAuth } from 'firebase/auth'

export enum BrowsingState {
  Authenticated = 'authenticated',
  Anonymous = 'anonymous',
  Loading = 'loading',
  Unconfirmed = 'unconfirmed',
}

interface CardinalContextProps {
  user?: User
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>
  currentProfile?: User
  setCurrentProfile: React.Dispatch<React.SetStateAction<User | undefined>>
  lastDirectChat: string
  setLastDirectChat: React.Dispatch<React.SetStateAction<string>>
  isMobileView: boolean
  setIsMobileView: React.Dispatch<React.SetStateAction<boolean>>
  browsingState: BrowsingState
  setBrowsingState: React.Dispatch<React.SetStateAction<BrowsingState>>
  customEmoteCategories: CustomEmoteCategoryType[]
  setCustomEmoteCategories: React.Dispatch<React.SetStateAction<CustomEmoteCategoryType[]>>
}

export const CardinalContext = createContext<CardinalContextProps | undefined>(undefined)

export const useCardinalContext = () => {
  const context = useContext(CardinalContext)
  if (!context) {
    throw new Error('useCardinal must be used within a CardinalProvider')
  }
  return context
}

type CardinalProps = {
  children: JSX.Element
}

export const CardinalProvider = ({ children }: CardinalProps) => {
  const [user, setUser] = useState<User>()
  const [currentProfile, setCurrentProfile] = useState<User>()
  const [lastDirectChat, setLastDirectChat] = useState<string>('')
  const [isMobileView, setIsMobileView] = useState<boolean>(false)
  const [browsingState, setBrowsingState] = useState<BrowsingState>(BrowsingState.Loading)
  const [customEmoteCategories, setCustomEmoteCategories] = useState<CustomEmoteCategoryType[]>([])

  const { authUser, authenticationState } = useAuthContext()
  const [getSignInUser] = useGetSignInUserLazyQuery()
  const [getUserFollowerContent] = useGetUserFollowerContentLazyQuery()
  const client = useApolloClient()

  useEffect(() => {
    const checkWindowSize = () => {
      if (window.innerWidth < 751) {
        setIsMobileView(true)
      } else {
        setIsMobileView(false)
      }
    }

    checkWindowSize()
    window.addEventListener('resize', checkWindowSize)
  }, [])

  useEffect(() => {
    if (authenticationState === AuthenticationState.InitialLoad) {
      setBrowsingState(BrowsingState.Loading)
      return
    }

    if (authenticationState === AuthenticationState.Unconfirmed) {
      setBrowsingState(BrowsingState.Unconfirmed)
      return
    }

    if (authenticationState === AuthenticationState.Anonymous) {
      setBrowsingState(BrowsingState.Anonymous)
      setUser(undefined)
      return
    }
  }, [authenticationState])

  //Add an observable to update the user if there are any changes.
  useEffect(() => {
    if (user?.handle) {
      const observable = client
        .watchQuery({
          query: GetUserDocument,
          variables: { handle: user?.handle, currentUserHandle: '' },
          fetchPolicy: 'cache-only',
        })
        .subscribe({
          next: value => {
            if (value.data?.user_by_pk) {
              setUser(value.data.user_by_pk as User)
            }
          },
        })

      return () => {
        observable.unsubscribe()
      }
    }
  }, [client, user?.handle])

  useEffect(() => {
    if (authUser?.uid && authenticationState === AuthenticationState.Authenticated) {
      console.log('Getting user information...')

      getSignInUser({ variables: { auth_id: authUser.uid } })
        .then(response => {
          if (response.data) {
            console.log('Successfully retrieved user information...')
            setUser(response.data.user[0] as User)
            setBrowsingState(BrowsingState.Authenticated)

            getUserFollowerContent({ variables: { currentUserHandle: response.data.user[0].handle } })
              .then(res => {
                setCustomEmoteCategories(
                  mergeUserFollowerEmojis(
                    res.data?.follower_content as Follower_Content[],
                    response?.data?.user[0] as User
                  )
                )
              })
              .catch(err => {
                console.log(err)
              })
          } else {
            throw new Error('Failed to retrieve user information. Signing user out...')
          }
        })
        .catch(error => {
          getAuth().signOut()
          console.log(error.message)
        })
    }
  }, [authUser, authenticationState, getSignInUser])

  return (
    <CardinalContext.Provider
      value={{
        user,
        setUser,
        currentProfile,
        setCurrentProfile,
        lastDirectChat,
        setLastDirectChat,
        isMobileView,
        setIsMobileView,
        browsingState,
        setBrowsingState,
        customEmoteCategories,
        setCustomEmoteCategories,
      }}>
      {children}
    </CardinalContext.Provider>
  )
}
