import { LazyQueryHookOptions } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { BrowsingState, useCardinalContext } from '../../../context/cardinal'
import sanitizeHTML from 'sanitize-html'
import {
  GetPostQuery,
  GetPostQueryVariables,
  Order_By,
  useCreateBookmarkMutation,
  useCreatePostVoteMutation,
  useDeleteBookmarkMutation,
  useDeletePostVoteMutation,
  useGetPostLazyQuery,
  User,
} from '../../../gql/generated/graphql'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CloseIcon from '@mui/icons-material/Close'
import './postImagePreviewModal.css'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import BookmarkBorderRoundedIcon from '@mui/icons-material/BookmarkBorderRounded'
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded'
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded'
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded'
import RepeatIcon from '@mui/icons-material/Repeat'
import HeartBrokenIcon from '@mui/icons-material/HeartBroken'
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove'
import { RepostMenu } from './repostMenu'
import { deletePostVoteOptimisticResponse, insertPostVoteOptimisticResponse } from '../../../gql/optimisticResponses'
import { PathNames } from '../../../constants/pathNames'
import { ProfileAvatar } from '../../profile/display/profileAvatar'
import Twemoji from 'react-twemoji'
import moment from 'moment'
import { FeedType } from '../feed'
import { sanitizeHtmlConf } from '../../../utils/constants'
import { VerifiedCheck } from '../../profile/display/verifiedCheck'
import { useScrollRestoration } from '../../../context/scrollRestorationContext'
import { NavLink } from '../../../navigation/navLink'
import { PostFilterType, useListPosts } from '../../../gql'
import { useGetPost } from '../../../gql/hooks/queries/useGetPost'

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: '%ds',
    m: '%dm',
    mm: '%dm',
    h: '%dh',
    hh: '%dh',
    d: '%dd',
    dd: '%dd',
  },
})

export function PostImagePreviewModal() {
  const [repostMenuOpen, setRepostMenuOpen] = useState<boolean>(false)
  const [voteCount, setVoteCount] = useState(0)
  const [shareCount, setShareCount] = useState(0)
  const [replyCount, setReplyCount] = useState(0)
  const [bookmarked, setBookmarked] = useState(false)
  const [voted, setVoted] = useState(false)
  const location = useLocation()
  const { navigateToPage } = useScrollRestoration()
  const { owner_id, post_id, image_key } = useParams()
  const { user, browsingState } = useCardinalContext()

  const [createBookmarkMutation] = useCreateBookmarkMutation({
    variables: { post_id: post_id, owner_handle: user?.handle ?? '' },
  })
  const [deleteBookmarkMutation] = useDeleteBookmarkMutation({
    variables: { post_id: post_id, owner_handle: user?.handle ?? '' },
  })
  const [createPostVoteMutation] = useCreatePostVoteMutation({
    variables: { post_id: post_id, owner_handle: user?.handle ?? '' },
    optimisticResponse: insertPostVoteOptimisticResponse(post_id ?? '', user?.handle ?? '', voteCount ?? 0),
  })
  const [deletePostVoteMutation] = useDeletePostVoteMutation({
    variables: { post_id: post_id, owner_handle: user?.handle ?? '' },
    optimisticResponse: deletePostVoteOptimisticResponse(post_id ?? '', voteCount ?? 0),
  })
  const { data, loading } = useGetPost(post_id)

  function handleVoteOptionClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation()
    if (user) {
      if (!voted) {
        createPostVoteMutation().catch(reason => {
          console.log(reason)
        })
      } else {
        deletePostVoteMutation().catch(reason => {
          console.log(reason)
        })
      }
    } else {
      navigateToPage(PathNames.Authentication)
    }
  }

  function handleBookmarkOptionClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation()
    if (user) {
      if (!bookmarked) {
        createBookmarkMutation().catch(reason => {
          console.log(reason)
        })
      } else {
        deleteBookmarkMutation().catch(reason => {
          console.log(reason)
        })
      }
    } else {
      navigateToPage(PathNames.Authentication)
    }
  }

  function handleRepostOptionClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation()
    if (user) {
      setRepostMenuOpen(true)
    } else {
      navigateToPage(PathNames.Authentication)
    }
  }

  useEffect(() => {
    if (data?.post_by_pk?.image_urls && data.post_by_pk?.image_urls.length < 1) {
      navigateToPage(`/post/${owner_id}/${post_id}`, { replace: true })
    }

    if (data?.post_by_pk) {
      setVoted(data.post_by_pk?.votes?.length > 0 && data?.post_by_pk?.votes[0]?.owner_handle === user?.handle)
      setBookmarked(
        data.post_by_pk.bookmarked_posts.length > 0 &&
          data.post_by_pk.bookmarked_posts[0]?.owner_handle === user?.handle
      )
      setReplyCount(data.post_by_pk?.replies_aggregate.aggregate?.count ?? 0)
      setVoteCount(data.post_by_pk?.votes_aggregate.aggregate?.count ?? 0)
      setShareCount(
        (data.post_by_pk?.quote_reposts_aggregate.aggregate?.count ?? 0) +
          (data.post_by_pk?.reposts_aggregate.aggregate?.count ?? 0)
      )
    }
  }, [data])

  useEffect(() => {
    if (location.state === null || location.state.background === null) {
      navigateToPage(`/post/${owner_id}/${post_id}`, { replace: true })
    }
  }, [])

  return (
    <>
      <div
        className="Post-Image-Preview-Modal-Container-BG"
        onClick={() => {
          navigateToPage(-1)
        }}>
        <div className="Post-Image-Preview-Modal-Left">
          {image_key && Number(image_key) > 1 && (
            <div
              className="Post-Image-Preview-Modal-Option Post-Image-Preview-Modal-Arrow-Back"
              onClick={e => {
                e.stopPropagation()
                navigateToPage(`/post/${owner_id}/${post_id}/image/${Number(image_key) - 1}`, {
                  replace: true,
                  state: { background: location.state.background ?? null },
                })
              }}>
              <ArrowBackIcon />
            </div>
          )}
          {image_key &&
            data?.post_by_pk?.image_urls.length &&
            Number(image_key) < data?.post_by_pk?.image_urls.length && (
              <div
                className="Post-Image-Preview-Modal-Option Post-Image-Preview-Modal-Arrow-Forward"
                onClick={e => {
                  e.stopPropagation()
                  navigateToPage(`/post/${owner_id}/${post_id}/image/${Number(image_key) + 1}`, {
                    replace: true,
                    state: { background: location.state.background ?? null },
                  })
                }}>
                <ArrowForwardIcon />
              </div>
            )}
          <div
            className="Post-Image-Preview-Modal-Option Post-Image-Preview-Modal-Close"
            onClick={e => {
              e.stopPropagation()
              navigateToPage(-1)
            }}>
            <CloseIcon />
          </div>

          {image_key && (
            <img
              onClick={e => e.stopPropagation()}
              className="Post-Image-Preview-Modal-Img"
              src={data?.post_by_pk?.image_urls[Number(image_key) - 1]?.url ?? ''}
              alt=""
            />
          )}

          <div className="Post-Image-Bottom-Options">
            <div
              className="Post-Option"
              onClick={e => {
                e.stopPropagation()
                navigateToPage(`/post/${owner_id}/${post_id}`, { replace: true })
              }}>
              <ChatBubbleOutlineRoundedIcon />
              <span>{replyCount}</span>
            </div>
            <div className={`Post-Option ${voted && 'Post-Option-Active'}`} onClick={handleVoteOptionClick}>
              {voted ? (
                <>
                  <FavoriteRoundedIcon className="Full-Heart" />
                  <HeartBrokenIcon className="Break-Heart" />
                </>
              ) : (
                <FavoriteBorderRoundedIcon />
              )}
              <span>{voteCount}</span>
            </div>
            {repostMenuOpen && user && (
              <RepostMenu
                postId={post_id ?? ''}
                openQuotePostModal={() =>
                  navigateToPage('/create/post', {
                    state: {
                      quoteRepostData: {
                        id: data?.post_by_pk?.id,
                        timestamp: data?.post_by_pk?.created_at,
                        owner: data?.post_by_pk?.owner,
                        text: data?.post_by_pk?.text,
                        image_urls: data?.post_by_pk?.image_urls,
                        video_url: data?.post_by_pk?.video_url,
                        gif_url: data?.post_by_pk?.gif_url,
                      },
                      background: location,
                    },
                  })
                }
                closeMenuFunction={() => {
                  setRepostMenuOpen(false)
                }}
              />
            )}
            <div className="Post-Option" onClick={handleRepostOptionClick}>
              <RepeatIcon style={{ transform: 'rotate(90deg)' }} />

              <span>{shareCount}</span>
            </div>
            <div className={`Post-Option ${bookmarked && 'Post-Option-Active'}`} onClick={handleBookmarkOptionClick}>
              {bookmarked ? (
                <>
                  <BookmarkIcon className="Full-Bookmark" /> <BookmarkRemoveIcon className="Remove-Bookmark" />
                </>
              ) : (
                <BookmarkBorderRoundedIcon />
              )}
            </div>
          </div>
        </div>
        <div className="Post-Image-Preview-Modal-Right" onClick={e => e.stopPropagation()}>
          {data?.post_by_pk && (
            <>
              <div className="Post-Image-Preview-Focused-Item-Post-Container">
                <div className="Post-Image-Preview-Focused-Item-Post-Container-Top">
                  <div className="Post-Left Reply-Post-Left">
                    <ProfileAvatar size="Small" disableStatus disableLink user={data.post_by_pk.owner as User} />
                  </div>
                  <div className="Post-Right">
                    <div className="Post-Image-Preview-Focused-Item-Header">
                      <div className="Post-Image-Preview-Focused-Item-Info">
                        <span className="Post-Displayname">
                          <Twemoji options={{ className: 'Post-Displayname-Emoji' }}>
                            {data.post_by_pk?.owner?.username} <VerifiedCheck />
                          </Twemoji>
                        </span>
                        <span className="Post-Username">@{data.post_by_pk?.owner?.handle}</span>
                      </div>
                      <div className="Post-Timestamp">
                        <time dateTime={`${data.post_by_pk?.created_at}`}>
                          {moment().diff(moment(data.post_by_pk?.created_at), 'days') >= 1
                            ? moment(data.post_by_pk?.created_at).format('MMM Do')
                            : moment(data.post_by_pk?.created_at).fromNow()}
                        </time>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="Post-Content">
                  <div className="Post-Text">
                    <Twemoji options={{ className: 'Post-Emoji' }}>
                      {data.post_by_pk?.text && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data.post_by_pk?.text ? sanitizeHTML(data.post_by_pk?.text, sanitizeHtmlConf) : '',
                          }}
                        />
                      )}
                    </Twemoji>
                  </div>
                </div>
              </div>
              <NavLink
                to="/create/post"
                state={{
                  replyData: {
                    id: data.post_by_pk.id,
                    timestamp: data.post_by_pk.created_at,
                    owner: data.post_by_pk.owner,
                    text: data.post_by_pk.text,
                  },
                  feedType: FeedType.Reply,
                  background: location.state.background,
                }}
                className="Post-Image-Preview-Focused-Item-Reply-Button">
                Reply
              </NavLink>
            </>
          )}
        </div>
      </div>
    </>
  )
}
