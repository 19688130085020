import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { $generateNodesFromDOM } from '@lexical/html'
import { ChangeEvent, useContext, useEffect } from 'react'
import { ActionType, EditorDispatchContext, EditorStateContext } from '../../../context/editorContext'
import Editor from '../../../utils/lexical/Editor'
import SeisoNodes from '../../../utils/lexical/nodes/SeisoNodes'
import SeisoEditorTheme from '../../../utils/lexical/themes/SeisoEditorTheme'
import './styles/generalEditor.css'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $getRoot, $isDecoratorNode, $isElementNode } from 'lexical'
import { Button } from '../../uiKit/button'
import { NavLink } from '../../../navigation/navLink'
import { useLocation } from 'react-router-dom'
import { AuthenticationFlowState } from '../../authentication'
import { useAuthContext } from '../../../context/authContext'

export function GeneralEditor() {
  const initialConfig = {
    namespace: 'Seiso-Status-Input',
    nodes: [...SeisoNodes],
    onError: (error: Error) => {
      throw error
    },
    theme: SeisoEditorTheme,
    editable: true,
  }

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <GeneralEditorInner />
    </LexicalComposer>
  )
}

export function GeneralEditorInner() {
  const state = useContext(EditorStateContext)
  const dispatch = useContext(EditorDispatchContext)
  const { setFlowState } = useAuthContext()
  const location = useLocation()
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    editor.update(() => {
      const parser = new DOMParser()
      const dom = parser.parseFromString(state.profileDesc, 'text/html')

      const nodes = $generateNodesFromDOM(editor, dom)

      nodes.forEach(n => {
        if ($isElementNode(n) || $isDecoratorNode(n)) {
          $getRoot().append(n)
        }
      })
      $getRoot().getFirstDescendant()?.remove()
    })
  }, [])

  return (
    <>
      <h1 className="Tab-Header-Mobile">GENERAL</h1>
      <div className="General-Editor-Container">
        <h1>GENERAL</h1>
        <h2>HANDLE</h2>
        <input
          value={state.handle}
          onChange={onHandleChange}
          className="General-Editor-Textarea"
          placeholder="Enter a handle"
          maxLength={32}
        />
        <h2>DISPLAY NAME</h2>
        <input
          value={state.displayname}
          onChange={onDisplaynameChange}
          className="General-Editor-Textarea"
          placeholder="Enter a display name"
          maxLength={32}
        />
        <h2>BIO</h2>
        <Editor
          className="General-Editor-Textarea"
          id="Bio-Input"
          placeHolderText={`Tell us about yourself`}
          editorOnChange={change => onChangeDescription(change)}
        />

        <NavLink to="/login" state={{ background: location }} onClick={onUpdateEmailAddressClick}>
          <Button className="General-Editor-Button">Update Email Address</Button>
        </NavLink>
      </div>
    </>
  )

  function onUpdateEmailAddressClick() {
    setFlowState(AuthenticationFlowState.UpdatingEmail)
  }

  function onDisplaynameChange(e: ChangeEvent<HTMLInputElement>) {
    dispatch({
      type: ActionType.setDisplayname,
      payload: e.target.value,
    })
  }

  function onHandleChange(e: ChangeEvent<HTMLInputElement>) {
    dispatch({
      type: ActionType.setHandle,
      payload: e.target.value,
    })
  }

  function onChangeDescription(value: string) {
    dispatch({
      type: ActionType.setProfileDesc,
      payload: value,
    })
  }
}
