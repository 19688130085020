import { useEffect, useState } from 'react'
import './styles/navNotificationMenu.css'
import { ClickAwayListener } from '../components/modal/clickAwayListener'
import i18n from 'i18next'
import { NavLink } from './navLink'

interface NavNotificationMenuProps {
  closeMenuFunction: () => void
  data?: any
  notificationCount?: number
}

export function NavNotificationMenu({ closeMenuFunction, data, notificationCount }: NavNotificationMenuProps) {
  const [messageCount, setMessageCount] = useState(0)

  useEffect(() => {
    setMessageCount(data.user_notifications.filter((item: any) => item.type === 'message').length)
  }, [data])

  return (
    <ClickAwayListener onClickAway={() => closeMenuFunction()} id="Nav-Notification-Menu">
      <div className="Nav-Notification-Menu">
        <div className="Nav-Notification-Menu-Options">
          <NavLink
            to={`/notifications/all`}
            className="Nav-Notification-Menu-Option"
            onClick={() => closeMenuFunction()}>
            {i18n.t('view-all-notifications')}{' '}
            {notificationCount ? notificationCount > 0 && `(${notificationCount})` : ''}
          </NavLink>
          <NavLink to={`/messages`} className="Nav-Notification-Menu-Option" onClick={() => closeMenuFunction()}>
            {i18n.t('messages')} {messageCount > 0 && `(${messageCount})`}
          </NavLink>
          <NavLink
            to="/notifications/overwatch"
            className="Nav-Notification-Menu-Option"
            onClick={() => closeMenuFunction()}>
            Overwatch
          </NavLink>
        </div>
      </div>
    </ClickAwayListener>
  )
}
