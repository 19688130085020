import { useEffect, useState } from 'react'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import { StreamProvider } from '../gql/scalars'
import twitch from '../svg/twitch.svg'
import youtube from '../svg/youtube.svg'
import { linkToYoutubeId } from '../utils/converterUtils'
import { useCardinalContext } from '../context/cardinal'
import { User_Set_Input, useUpdateUserMutation } from '../gql/generated/graphql'
import { $generateNodesFromDOM } from '@lexical/html'
import './styles/setStatusRouteModal.css'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import SeisoEditorTheme from '../utils/lexical/themes/SeisoEditorTheme'
import SeisoNodes from '../utils/lexical/nodes/SeisoNodes'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $getRoot, $isDecoratorNode, $isElementNode, LexicalEditor } from 'lexical'
import Editor from '../utils/lexical/Editor'
import { ClickAwayListener } from '../components/modal/clickAwayListener'
import { useScrollRestoration } from '../context/scrollRestorationContext'

enum StatusSwitch {
  live = 'Live',
  offline = 'Offline',
}

export function SetStatusRouteModal() {
  const initialConfig = {
    namespace: 'Seiso-Status-Input',
    nodes: [...SeisoNodes],
    onError: (error: Error) => {
      throw error
    },
    theme: SeisoEditorTheme,
    editable: true,
  }

  return (
    <LexicalComposer initialConfig={initialConfig}>
      {/* <LoadInitialContent initialContent={state.profileDesc} /> */}
      <SetStatusRouteModalInner />
    </LexicalComposer>
  )
}

export function SetStatusRouteModalInner() {
  const { navigateToPage } = useScrollRestoration()
  const globalState = useCardinalContext()
  const [statusMessage, setStatusMessage] = useState<string>('')
  const [statusSwitchOpen, setStatusSwitchOpen] = useState<boolean>(false)
  const [statusSwitch, setStatusSwitch] = useState<StatusSwitch>(StatusSwitch.offline)
  const [streamProvider, setStreamProvider] = useState<StreamProvider>()
  const [updateUserMutation] = useUpdateUserMutation()
  const [twitchName, setTwitchName] = useState<string>('')
  const [youtubeStream, setYoutubeStream] = useState<string>('')
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    editor.update(() => {
      const parser = new DOMParser()
      const dom = parser.parseFromString(globalState.user?.status_message ?? '', 'text/html')

      const nodes = $generateNodesFromDOM(editor, dom)

      nodes.forEach(n => {
        if ($isElementNode(n) || $isDecoratorNode(n)) {
          $getRoot().append(n)
        }
      })
      $getRoot().getFirstDescendant()?.remove()
    })
  }, [])

  useEffect(() => {
    if (!globalState.user) navigateToPage('/', { replace: true })
    if (globalState.user && globalState.user.twitch_connection_id) {
      setTwitchName(globalState.user.twitch_connection_id)
    }
    if (globalState.user && globalState.user.status_message) {
      setStatusMessage(globalState.user.status_message)
    }
    if (
      globalState.user &&
      (globalState.user.stream_provider === StreamProvider.Twitch ||
        globalState.user.stream_provider === StreamProvider.Youtube)
    ) {
      setStatusSwitch(StatusSwitch.live)
    }
  }, [])

  async function updateStatus() {
    const input: User_Set_Input = {
      stream_provider: streamProvider ? streamProvider : StreamProvider.None,
      twitch_connection_id: twitchName,
      youtube_connection_id: linkToYoutubeId(youtubeStream),
      status_message: statusMessage,
    }
    await updateUserMutation({ variables: { handle: globalState.user!.handle, object: input } })

    navigateToPage(-1)
  }

  return (
    <>
      <div className="Set-Status-Modal-Container-BG">
        <ClickAwayListener onClickAway={() => navigateToPage(-1)} id="Set-Status-Menu">
          <div className="Set-Status-Container">
            <div className="Set-Status-Container-Inner">
              <h1>Set a custom status!</h1>

              <span className="Status-Settings-Sub-Header">Whats going on?</span>
              <div className="Status-Text-Modal-Input-Wrapper">
                <Editor
                  className="Status-Text-Modal-Input General-Editor-Textarea"
                  id="Status-Input"
                  editorOnChange={change => setStatusMessage(change)}
                />
              </div>
              <span className="Status-Settings-Sub-Header">Status</span>
              <div className="Status-Switch-Container">
                <ClickAwayListener onClickAway={() => setStatusSwitchOpen(false)} id="Status-Switch-Menu">
                  <>
                    <div className="Status-Switch-Main" onClick={() => setStatusSwitchOpen(!statusSwitchOpen)}>
                      {statusSwitch}
                      <KeyboardArrowDownOutlinedIcon
                        style={{ transition: 'all 0.25s', transform: `${statusSwitchOpen ? 'rotate(-180deg)' : ''} ` }}
                      />
                    </div>
                    {statusSwitchOpen && (
                      <div className="Status-Switch-Menu">
                        <div
                          className="Status-Switch-Menu-Item"
                          onClick={() => {
                            setStatusSwitchOpen(false)
                            setStatusSwitch(StatusSwitch.live)
                          }}>
                          Live
                        </div>
                        <div
                          className="Status-Switch-Menu-Item"
                          onClick={() => {
                            setStatusSwitchOpen(false)
                            setStatusSwitch(StatusSwitch.offline)
                            setStreamProvider(StreamProvider.None)
                          }}>
                          Offline
                        </div>
                      </div>
                    )}
                  </>
                </ClickAwayListener>
              </div>

              {statusSwitch === StatusSwitch.live && (
                <>
                  <span className="Status-Settings-Sub-Header">Which streaming service are you using?</span>
                  <form className="radio-with-Icon" onChange={(e: any) => setStreamProvider(e.target.id)}>
                    <p className="radioOption-Item">
                      <input type="radio" name="BannerTypes" id={StreamProvider.Youtube} />
                      <label htmlFor={StreamProvider.Youtube}>
                        <img src={youtube} />
                        Youtube
                      </label>
                    </p>
                    <p className="radioOption-Item">
                      <input type="radio" name="BannerTypes" id={StreamProvider.Twitch} />
                      <label htmlFor={StreamProvider.Twitch}>
                        <img src={twitch} />
                        Twitch
                      </label>
                    </p>
                  </form>

                  {streamProvider === StreamProvider.Twitch && (
                    <>
                      <span className="Status-Settings-Sub-Header">Confirm channel name</span>
                      <input
                        value={twitchName}
                        onChange={e => setTwitchName(e.target.value)}
                        placeholder="Input Twitch channel name"
                        className="Status-Text-Modal-Input General-Editor-Textarea"
                        type="text"
                      />
                    </>
                  )}

                  {streamProvider === StreamProvider.Youtube && (
                    <>
                      <span className="Status-Settings-Sub-Header">Please provide stream link</span>
                      <input
                        value={youtubeStream}
                        onChange={e => setYoutubeStream(e.target.value)}
                        placeholder="Input stream link"
                        className="Status-Text-Modal-Input General-Editor-Textarea"
                        type="text"
                      />
                    </>
                  )}
                </>
              )}
            </div>
            <div className="Set-Status-Button-Container">
              <div className="Set-Status-Buttons">
                <button className="Set-Status-Button-1" onClick={() => navigateToPage(-1)}>
                  Cancel
                </button>
                <button className="Set-Status-Button-2" onClick={() => updateStatus()}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      </div>
    </>
  )
}
