import { Suspense, useEffect, useMemo, useState } from 'react'
import { Feed, FeedType } from '../components/feed/feed'
import { FeedItem } from '../components/feed/feedItem'
import { useCardinalContext } from '../context/cardinal'
import { Post, User } from '../gql/generated/graphql'
import { PostFilterType, useListPosts, useNewPostNotification } from '../gql'
import React from 'react'
import { Sidebar, SidebarType } from '../components/sidebar/sidebar'

export function Home() {
  const LazyFeed = React.lazy(() => import('../components/feed/feed'))
  const LazyFeedItem = React.lazy(() => import('../components/feed/feedItem'))
  const { user } = useCardinalContext()
  const [showNotification, setShowNotification] = useState(false)
  const { data: postData, loading, fetchMore, refetch, queryOptions } = useListPosts(PostFilterType.Following)
  const { data: notificationData } = useNewPostNotification(PostFilterType.Following)

  useEffect(() => {
    if (notificationData?.post.length && notificationData.post[0].id) {
      setShowNotification(notificationData.post[0].id !== postData?.post[0]?.id)
    }
  }, [notificationData, postData])

  const feedItems = useMemo(() => {
    return postData?.post.map(post => {
      const currentPost = post?.repost_post || post
      return <FeedItem post={currentPost as Post} key={post.id} />
    })
  }, [postData])

  // useEffect(() => {
  //   console.log(location.state)
  //   if (location.state && location.state.from)
  //     navigate(location.state.from, { state: { background: location, from: null } })
  // }, [])

  useEffect(() => {
    // Intersection Observer callback
    const callback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // This post is now visible in the viewport
          // You can handle this event as needed (e.g., mark it as read)
          // console.log('Post is visible:', entry.target)
        }
      })
    }

    // Create the Intersection Observer
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: '0px', // No margin
      threshold: 0.1, // Trigger when 50% of the element is visible
    }

    const observer = new IntersectionObserver(callback, options)

    // Find and observe all posts in the feed
    const posts = document.querySelectorAll('.Post-Container')
    posts.forEach(post => {
      if (!post.className.includes('Quote-Post-Container')) observer.observe(post)
    })

    // Clean up the observer when the component unmounts
    return () => {
      observer.disconnect()
    }
  }, [feedItems])

  return (
    <>
      <Feed
        disableCreateFeedItem={!!!user}
        loading={loading}
        newPostsNotification={
          showNotification ? (
            <div onClick={() => refetch()} className="New-Posts-Notification">
              Show New Posts
            </div>
          ) : null
        }
        items={feedItems}
        feedType={FeedType.Post}
        fetchMore={fetchMore}
        noItemsMessage="There are no posts yet, be the first!"
        queryOptions={queryOptions}
      />
      <Sidebar sidebarType={SidebarType.Home} />
    </>
  )
}
