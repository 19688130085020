import { MutableRefObject, createContext, useContext, useEffect, useRef } from 'react'
import { NavigateFunction, NavigateOptions, To, useLocation, useNavigate } from 'react-router-dom'
import { PathNames } from '../constants/pathNames'

interface ScrollRestorationContextProps {
  navigateToPage: NavigateFunction
  saveScrollPosition: () => void
  saveRestoredPostListOffset: (offset: number) => void
  postListOffsetRef: MutableRefObject<{
    [key: string]: {
      offset: number
    }
  }>
}

const ScrollRestorationContext = createContext<ScrollRestorationContextProps | undefined>(undefined)

export const useScrollRestoration = () => {
  const context = useContext(ScrollRestorationContext)
  if (!context) {
    throw new Error('useScrollRestoration must be used within a ScrollRestorationProvider')
  }
  return context
}

const allowedPathNames = [PathNames.Home, PathNames.Bookmarks, PathNames.Browse]
const partialPathNames = [PathNames.Thread, PathNames.Post, PathNames.Explore, PathNames.MessagesId, PathNames.Hashtag]
const maxPartialSavedPositions = 100

interface ScrollRestorationProviderProps {
  children: JSX.Element
}

const ScrollRestorationProvider: React.FC<ScrollRestorationProviderProps> = ({ children }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const allowedScrollPositionRef = useRef<{ [key: string]: { position: number; height: number } }>({})
  const partialScrollPositionRef = useRef<{ [key: string]: { position: number; height: number } }>({})
  const postListOffsetRef = useRef<{ [key: string]: { offset: number } }>({})

  useEffect(() => {
    const isAllowedPathname = allowedPathNames.includes(location.pathname as PathNames)
    const scrollPositionRef = isAllowedPathname ? allowedScrollPositionRef : partialScrollPositionRef

    const savedScrollPosition = scrollPositionRef.current[location.pathname]?.position || 0
    const savedComponentHeight = scrollPositionRef.current[location.pathname]?.height || 0

    document.body.style.height = `${savedComponentHeight}px`
    window.scrollTo(0, savedScrollPosition)
  }, [location.pathname])

  const navigateToPage: NavigateFunction = (path: To | number, options?: NavigateOptions) => {
    saveScrollPosition()

    if (typeof path === 'number') {
      navigate(path)
    } else {
      navigate(path, options)
    }
  }

  const saveScrollPosition = () => {
    const isAllowedPathname = allowedPathNames.includes(location.pathname as PathNames)
    if (isAllowedPathname) {
      allowedScrollPositionRef.current[location.pathname] = {
        position: window.scrollY,
        height: document.body.scrollHeight,
      }
    } else {
      const pathPrefix = location.pathname.split('/')[1]

      if (partialPathNames.some(path => path.startsWith(`/${pathPrefix}`))) {
        partialScrollPositionRef.current[location.pathname] = {
          position: window.scrollY,
          height: document.body.scrollHeight,
        }

        // Remove the oldest scroll position from the history
        const keys = Object.keys(partialScrollPositionRef.current)
        if (keys.length > maxPartialSavedPositions) {
          const oldestKey = keys[0]
          delete partialScrollPositionRef.current[oldestKey]
        }
      }
    }
  }

  const saveRestoredPostListOffset = (offset: number) => {
    postListOffsetRef.current[location.pathname] = { offset }
  }

  const getRestoredPostListOffset = () => {
    return postListOffsetRef.current[location.pathname]?.offset || undefined
  }

  return (
    <ScrollRestorationContext.Provider
      value={{
        navigateToPage,
        saveScrollPosition,
        saveRestoredPostListOffset,
        postListOffsetRef,
      }}>
      {children}
    </ScrollRestorationContext.Provider>
  )
}

export default ScrollRestorationProvider
