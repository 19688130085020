import { useMemo, useState } from 'react'
import { Post, User } from '../../gql/generated/graphql'
import { Feed, FeedType } from '../feed/feed'
import { FeedItem } from '../feed/feedItem'
import './styles/profileActivity.css'
import { PostFilterType, useListPosts } from '../../gql'
import MenuIcon from '@mui/icons-material/Menu'
import { ClickAwayListener } from '../modal/clickAwayListener'
import { NavLink, useParams } from 'react-router-dom'

interface ProfileActivityProps {
  profile: User | undefined
}

export enum ActivityType {
  likes = 'likes',
  replies = 'replies',
  media = 'media',
}

export function ProfileActivity({ profile }: ProfileActivityProps) {
  const { activity_tab } = useParams()
  const { data, loading, fetchMore } = useListPosts(getFilterByActivityType(activity_tab), profile?.handle)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  function getFilterByActivityType(activity_tab?: string | null) {
    if (activity_tab === ActivityType.likes) {
      return PostFilterType.LikedByUser
    }

    if (activity_tab === ActivityType.replies) {
      return PostFilterType.ReplyCreatedByUser
    }

    if (activity_tab === ActivityType.media) {
      return PostFilterType.MediaPostCreatedByUser
    }

    return PostFilterType.PostCreatedByUser
  }

  const activityPostItems = useMemo(() => {
    return data?.post.map(post => {
      const currentPost = post?.repost_post || post
      return <FeedItem post={currentPost as Post} key={currentPost.id} />
    })
  }, [data])

  return (
    <>
      <div className="Profile-Activity-Container">
        <div onClick={() => setMobileMenuOpen(true)} className="Profile-Activity-Mobile-Menu-Button">
          <MenuIcon />
        </div>
        <ClickAwayListener
          onClickAway={() => setMobileMenuOpen(false)}
          id="Profile-Activity-Left-Mobile-Menu"
          className="Profile-Activity-Left-Mobile-Menu">
          <div className={`Profile-Activity-Left ${mobileMenuOpen ? 'Profile-Activity-Left-Mobile-Menu-Open' : ''}`}>
            <NavLink
              to={`/profile/${profile?.handle}/activity`}
              onClick={() => {
                setMobileMenuOpen(false)
              }}
              className={`Profile-Activity-Tab ${!activity_tab && 'Profile-Activity-Link-Active'}`}>
              Posts
            </NavLink>
            <NavLink
              to={`/profile/${profile?.handle}/activity/${ActivityType.likes}`}
              onClick={() => {
                setMobileMenuOpen(false)
              }}
              className={`Profile-Activity-Tab ${
                activity_tab === ActivityType.likes && 'Profile-Activity-Link-Active'
              }`}>
              Likes
            </NavLink>
            <NavLink
              to={`/profile/${profile?.handle}/activity/${ActivityType.replies}`}
              onClick={() => {
                setMobileMenuOpen(false)
              }}
              className={`Profile-Activity-Tab ${
                activity_tab === ActivityType.replies && 'Profile-Activity-Link-Active'
              }`}>
              Replies
            </NavLink>
            <NavLink
              to={`/profile/${profile?.handle}/activity/${ActivityType.media}`}
              onClick={() => {
                setMobileMenuOpen(false)
              }}
              className={`Profile-Activity-Tab ${
                activity_tab === ActivityType.media && 'Profile-Activity-Link-Active'
              }`}>
              Media
            </NavLink>
          </div>
        </ClickAwayListener>
        <div className="Profile-Activity-Right">
          <Feed
            disableCreateFeedItem
            loading={loading}
            items={activityPostItems}
            feedType={FeedType.Post}
            fetchMore={fetchMore}
            noItemsMessage="There's nothing here yet."
          />
        </div>
      </div>
    </>
  )
}
