import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ProfileActivity } from '../components/profile/profileActivity'
import { GalleryShowcase } from '../components/profile/showcases/galleryShowcase'
import { ImageShowcase } from '../components/profile/showcases/imageShowcase'
import { InfoShowcase } from '../components/profile/showcases/infoShowcase'
import { PanelShowcase } from '../components/profile/showcases/panelShowcase'
import { VideoShowcase } from '../components/profile/showcases/videoShowcase'
import { SocialsShowcase } from '../components/profile/showcases/socialsShowcase'
import { BrowsingState, useCardinalContext } from '../context/cardinal'
import { User, useFollowMutation, useUnFollowMutation } from '../gql/generated/graphql'
import { ProfileHeader } from '../components/profile/profileHeader'
import {
  GalleryShowcase as GraphqlGalleryShowcase,
  ImageShowcase as GraphqlImageShowcase,
  InfoShowcase as GraphqlInfoShowcase,
  PanelShowcase as GraphqlPanelShowcase,
  SocialsShowcase as GraphqlSocialsShowcase,
  ShowcaseType,
  VideoShowcase as GraphqlVideoShowcase,
  ProfileTab,
} from '../gql/scalars'
import './styles/profile.css'
import { Loading } from '../components/uiKit/loading/loadingExports'
import { useGetUser } from '../gql'

export function Profile() {
  const { user, browsingState, isMobileView, setCurrentProfile } = useCardinalContext()
  const { profile_id, profile_tab } = useParams()
  const [profile, setProfile] = useState<User | undefined>(undefined)
  const { data, loading, refetch } = useGetUser(profile_id)
  const [unFollowUser] = useUnFollowMutation()
  const [followUser] = useFollowMutation()
  const [following, setFollowing] = useState(false)
  const [tab, setTab] = useState<ProfileTab>()

  useEffect(() => {
    if (data) {
      setCurrentProfile(data.user_by_pk as User)
      setProfile(data.user_by_pk as User)
      setFollowing(data?.user_by_pk?.followers && data?.user_by_pk?.followers.length > 0 ? true : false)
    }
  }, [data])

  async function follow() {
    if (profile?.handle && user) {
      await followUser({
        variables: {
          currentUserHandle: user.handle,
          handleToFollow: profile.handle,
        },
      })
      setFollowing(true)
    }
  }
  async function unFollow() {
    if (profile?.handle && user) {
      await unFollowUser({
        variables: {
          currentUserHandle: user.handle,
          handleToUnFollow: profile.handle,
        },
      })
      setFollowing(false)
    }
  }

  useEffect(() => {
    if (profile?.profile_tabs) {
      setTab(profile.profile_tabs.find(tab => tab?.name.toLocaleLowerCase() === (profile_tab || 'home')) ?? undefined)
    }
  }, [profile, profile_tab])

  return (
    <>
      {loading || browsingState === BrowsingState.Loading ? (
        <Loading />
      ) : (
        <>
          {profile ? (
            <div className="Profile-Page">
              <div className="Profile-Page-Center-Content">
                <ProfileHeader
                  profile={profile}
                  isMobileView={isMobileView}
                  user={user}
                  following={following}
                  follow={follow}
                  unFollow={unFollow}
                />

                <div className="Profile-Body">
                  {profile_tab?.toLocaleLowerCase() === 'activity' ? (
                    <ProfileActivity profile={profile} />
                  ) : (tab?.showcases.length ?? 0) > 0 ? (
                    tab?.showcases.map((showcase, key) => (
                      <div className="Profile-Content-Item" key={key}>
                        {showcase?.title && showcase.description && (
                          <div className="Profile-Custom-Header">
                            {showcase?.title && <div className="Profile-Custom-Header-Top">{showcase?.title}</div>}
                            {showcase?.description && (
                              <div className="Profile-Custom-Header-Desc">{showcase?.description}</div>
                            )}
                          </div>
                        )}

                        {showcase?.type === ShowcaseType.Images && (
                          <ImageShowcase imageShowcase={showcase as GraphqlImageShowcase} />
                        )}
                        {showcase?.type === ShowcaseType.Video && (
                          <VideoShowcase videoShowcase={showcase as GraphqlVideoShowcase} />
                        )}
                        {showcase?.type === ShowcaseType.Info && (
                          <InfoShowcase infoShowcase={showcase as GraphqlInfoShowcase} />
                        )}
                        {showcase?.type === ShowcaseType.Gallery && (
                          <GalleryShowcase galleryShowcase={showcase as GraphqlGalleryShowcase} />
                        )}
                        {showcase?.type === ShowcaseType.Panel && (
                          <PanelShowcase panelShowcase={showcase as GraphqlPanelShowcase} />
                        )}
                        {showcase?.type === ShowcaseType.Socials && (
                          <SocialsShowcase socialsShowcase={showcase as GraphqlSocialsShowcase} />
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="No-Showcases-Message">There are no showcases here yet</div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="Profile-Page-None-Found">Profile Not Found..</div>
          )}
        </>
      )}
    </>
  )
}
