import './styles/search.css'
import { useSearchUserLazyQuery, useSearchPostsByUserLazyQuery, User, Post } from '../gql/generated/graphql'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import Twemoji from 'react-twemoji'
import { FeedItem } from '../components/feed/feedItem'
import { useCardinalContext } from '../context/cardinal'
import { Loading } from '../components/uiKit/loading/loadingExports'
import { ListProfileItem } from '../components/profile/display/listProfileItem'
import { useScrollRestoration } from '../context/scrollRestorationContext'

export function Search() {
  const { navigateToPage } = useScrollRestoration()
  const { search_field } = useParams()
  const { user } = useCardinalContext()
  const [searchUser] = useSearchUserLazyQuery()
  const [searchPostsByUser, { data }] = useSearchPostsByUserLazyQuery()
  const [searchInput, setSearchInput] = useState('')
  const [userResults, setUserResults] = useState<User[]>([])
  const [postResults, setPostResults] = useState<Post[]>([])
  const [loading, setLoading] = useState(false)
  const [showNoResults, setShowNoResults] = useState(false)

  async function search() {
    let userData = await searchUser({
      variables: {
        searchInput: searchInput,
        currentUserHandle: user?.handle ?? '',
      },
    })
    if (userData.data?.user) {
      setUserResults(userData.data?.user as User[])
    }

    let postData = await searchPostsByUser({
      variables: {
        searchInput: searchInput,
        currentUserHandle: user?.handle ? user.handle : '',
      },
    })
    if (postData.data?.post) {
      setPostResults(postData.data.post as Post[])
    }

    if (postData.data?.post.length === 0 && userData.data?.user.length === 0) setShowNoResults(true)

    setLoading(false)
  }

  const runSearch = useDebouncedCallback(() => {
    if (searchInput.length > 0) {
      search()
      setLoading(true)
      setShowNoResults(false)
    } else {
      setUserResults([])
      setPostResults([])
    }
  }, 200)

  useEffect(() => {
    if (search_field) {
      setSearchInput(search_field)
      runSearch()
    }
  }, [search_field, runSearch])

  useEffect(() => {
    document.getElementById('Search-Input')?.focus()
  }, [])

  const searchPostItems = useMemo(() => {
    return data?.post.map(post => {
      return <FeedItem post={post as Post} key={post.id} />
    })
  }, [data])

  return (
    <div className="Search-Container">
      <div className="Search-Input-Container">
        <input
          value={searchInput}
          autoComplete="off"
          onChange={e => setSearchInput(e.target.value)}
          placeholder="Search.."
          className="Search-Input"
          id="Search-Input"
          type="search"
          onKeyDown={e => {
            let key = e.code || e.key
            if (key === 'Enter' || key === 'NumpadEnter')
              navigateToPage(searchInput.length > 0 ? `/search/${searchInput}` : '/search', { replace: true })
          }}
          spellCheck={false}
        />
      </div>
      {loading && <Loading />}
      {!loading && (
        <div className="Search-Output-Container">
          {showNoResults && (
            <div className="Search-Output-No-Results">
              Sorry, no results were found. Try searching for something else...
            </div>
          )}
          {userResults.length > 0 && (
            <div className="Search-Output-Users">
              <div className="Search-Output-Background">
                <div className="Search-Output-Users-Header">Users:</div>
                {userResults.map((item: User, key: number) => (
                  <div key={key} className="Search-Output-User-Item">
                    <ListProfileItem user={item} index={key} disableSocialLinks profileFrame />
                  </div>
                ))}
              </div>
            </div>
          )}
          {postResults.length > 0 && (
            <div className="Search-Output-Posts">
              <div className="Search-Output-Background">
                <div className="Search-Output-Users-Header">Related Posts:</div>
                {searchPostItems}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
