import { User } from '../../../gql/generated/graphql'
import { StreamProvider } from '../../../gql/scalars'
import { NavLink } from '../../../navigation/navLink'
import './styles/profileAvatar.css'

interface ProfileAvatarProps {
  size?: string
  profileFrame?: boolean
  customClass?: string
  disableStatus?: boolean
  disableMiniProfile?: boolean
  disableLink?: boolean
  customFunction?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  staticUser?: { profileImage: string; streamProvider: string; profileFrame?: string }
  user?: User
}

export function ProfileAvatar({
  size,
  profileFrame,
  customClass,
  disableStatus,
  disableMiniProfile,
  disableLink,
  customFunction,
  staticUser,
  user,
}: ProfileAvatarProps) {
  const profileImage = user?.profile_image
  const streamProvider = user?.stream_provider

  return (
    <>
      {user ? (
        disableLink ? (
          <div
            data-seiso-miniprofile={disableMiniProfile ? null : user.handle}
            className={`${customClass ? customClass : ''} ${size ? 'Avatar-' + size : ''} Avatar-Status-${
              disableStatus || profileFrame ? StreamProvider.None : streamProvider
            } Avatar`}
            draggable>
            <div
              className="Avatar-Image"
              style={{
                backgroundImage: `url(${profileImage})`,
              }}
            />
            {profileFrame && user.profile_image_frame && (
              <img src={user.profile_image_frame} loading="lazy" className="Avatar-Custom-Frame" alt="" />
            )}
            {streamProvider !== StreamProvider.None && !disableStatus && (
              <div className="Avatar-Status">
                <div className="Avatar-Status-Text">
                  <span>Live</span>
                </div>
              </div>
            )}
          </div>
        ) : (
          <NavLink
            data-seiso-miniprofile={disableMiniProfile ? null : user.handle}
            onClick={e => {
              customFunction && customFunction(e)
            }}
            to={`/profile/${user.handle}`}
            className={`${size ? 'Avatar-' + size : ''} Avatar-Status-${
              disableStatus || profileFrame ? StreamProvider.None : streamProvider
            } Avatar`}
            draggable>
            <div
              // data-seiso-miniprofile={disableMiniProfile ? null : user.handle}
              className="Avatar-Image"
              style={{
                backgroundImage: `url(${profileImage})`,
              }}
            />
            {profileFrame && user.profile_image_frame && (
              <img src={user.profile_image_frame} className="Avatar-Custom-Frame" alt="" />
            )}
            {streamProvider !== StreamProvider.None && !disableStatus && (
              <div className="Avatar-Status">
                <div className="Avatar-Status-Text">
                  <span>Live</span>
                </div>
              </div>
            )}
          </NavLink>
        )
      ) : (
        staticUser && (
          <NavLink
            onClick={e => {
              customFunction && customFunction(e)
            }}
            to={`#`}
            className={`${size ? 'Avatar-' + size : ''} Avatar-Status-${
              profileFrame ? 'None' : staticUser.streamProvider
            } Avatar`}
            draggable>
            <div
              className="Avatar-Image"
              style={{
                backgroundImage: `url(${staticUser.profileImage})`,
              }}
            />
            {profileFrame && staticUser.profileFrame && (
              <img src={staticUser.profileFrame} className="Avatar-Custom-Frame" alt="" />
            )}
            {staticUser.streamProvider != StreamProvider.None && (
              <div className="Avatar-Status">
                <div className="Avatar-Status-Text">
                  <span>Live</span>
                </div>
              </div>
            )}
          </NavLink>
        )
      )}
    </>
  )
}
