import { DataSet, RegExpMatcher, TextCensor, englishDataset, englishRecommendedTransformers } from 'obscenity'

/*Dont think this is the best way to implement this, revisit*/
const seisoDataset = new DataSet<{ originalWord: string }>()
  // addAll() adds all the data from the dataset passed.
  .addAll(englishDataset)
  // removePhrasesIf() removes phrases from the current dataset if the function provided
  // returns true.
  .removePhrasesIf(phrase => phrase?.metadata?.originalWord === 'fuck')

export const SeisoMatcher = new RegExpMatcher({
  ...seisoDataset.build(),
  ...englishRecommendedTransformers,
})
export const SeisoCensor = new TextCensor()
