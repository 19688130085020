import { useEffect } from 'react'
import './styles/navMobileProfileMenu.css'
import { useCardinalContext } from '../context/cardinal'
import { ProfileAvatar } from '../components/profile/display/profileAvatar'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import Twemoji from 'react-twemoji'
import { useLocation } from 'react-router-dom'
import { getProviderIcon } from '../utils/statusUtils'
import i18n from 'i18next'
import { useScrollRestoration } from '../context/scrollRestorationContext'
import { NavLink } from './navLink'

export function NavMobileProfileMenu() {
  const location = useLocation()
  const { navigateToPage } = useScrollRestoration()
  const { user } = useCardinalContext()

  useEffect(() => {
    if (location.state === null || location.state.background === null) {
      navigateToPage('/')
    }
  }, [])

  return (
    <div className="Nav-Mobile-Profile-Menu-Container">
      <div className="Nav-Mobile-Profile-Menu-Header">
        <video
          autoPlay
          loop
          playsInline
          muted
          disablePictureInPicture
          className="Nav-Mobile-Profile-Menu-Header-BG"
          src={user?.mini_profile_background + '#t=0.1' ?? ''}
        />
        <div className="Nav-Mobile-Profile-Menu-Header-Inner">
          <div className="Nav-Mobile-Profile-Menu-Header-Inner-Back" onClick={() => navigateToPage(-1)}>
            <ArrowBackIosNewIcon />
          </div>
          {user && <ProfileAvatar disableLink disableMiniProfile size="Medium" user={user} />}
          <div className="Nav-Mobile-Profile-Menu-Header-Inner-User-Info">
            <div className="Nav-Mobile-Profile-Menu-Header-Inner-User-Info-Top">
              <Twemoji options={{ className: 'Mini-Profile-Displayname-Emoji' }}>{user?.username}</Twemoji>
            </div>
            <div className="Nav-Mobile-Profile-Menu-Header-Inner-User-Info-Bottom">
              <NavLink className="Nav-Mobile-Profile-Menu-View-Profile-Button" to={`/profile/${user?.handle}`} replace>
                {i18n.t('view-my-profile')}
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="Nav-Mobile-Profile-Menu-User-Info">
        <div className="Nav-Mobile-Profile-Menu-User-Info-Aggregates">
          <div className="Nav-Mobile-Profile-Menu-User-Info-Aggregate">
            <NavLink
              to={`/profile/${user?.handle}/activity`}
              className="Nav-Mobile-Profile-Menu-User-Info-Aggregate-Top"
              replace>
              {user?.posts_aggregate.aggregate?.count ?? 0}
            </NavLink>
            <div className="Nav-Mobile-Profile-Menu-User-Info-Aggregate-Bottom">{i18n.t('posts')}</div>
          </div>
          <div className="Nav-Mobile-Profile-Menu-User-Info-Aggregate">
            <div className="Nav-Mobile-Profile-Menu-User-Info-Aggregate-Top">
              {user?.following_aggregate.aggregate?.count ?? 0}
            </div>
            <div className="Nav-Mobile-Profile-Menu-User-Info-Aggregate-Bottom">{i18n.t('following')}</div>
          </div>
          <div className="Nav-Mobile-Profile-Menu-User-Info-Aggregate">
            <div className="Nav-Mobile-Profile-Menu-User-Info-Aggregate-Top">
              {user?.followers_aggregate.aggregate?.count ?? 0}
            </div>
            <div className="Nav-Mobile-Profile-Menu-User-Info-Aggregate-Bottom">{i18n.t('followers')}</div>
          </div>
        </div>
        <div className="Nav-Mobile-Profile-Menu-User-Info-Socials">
          {user?.social_links.slice(0, 5).map((socialLink, key) => (
            <a
              href={socialLink?.url}
              target="_blank"
              rel="noreferrer"
              className="Social-Icon-Container Mini-Profile-Social-Icon-Container"
              key={`${socialLink?.url}-${key}`}>
              <div className={`Social-Icon ${socialLink?.provider}-Icon`}>
                <img src={getProviderIcon(socialLink?.provider) ?? socialLink?.icon} alt="" />
              </div>
            </a>
          ))}
        </div>
      </div>
      <div className="Nav-Mobile-Profile-Menu-Options">
        <NavLink className="Nav-Mobile-Profile-Menu-Option" to={'/notifications/all'} replace>
          {i18n.t('notifications')}
        </NavLink>
        <NavLink className="Nav-Mobile-Profile-Menu-Option" to={'/messages'} replace>
          {i18n.t('messages')}
        </NavLink>
        <NavLink className="Nav-Mobile-Profile-Menu-Option" to={'/bookmarks'} replace>
          {i18n.t('bookmarks')}
        </NavLink>
        <NavLink className="Nav-Mobile-Profile-Menu-Option" to={'/status'} replace>
          {i18n.t('set-status')}
        </NavLink>
        <NavLink className="Nav-Mobile-Profile-Menu-Option" to={'/settings/general'} replace>
          {i18n.t('settings')}
        </NavLink>
        <NavLink className="Nav-Mobile-Profile-Menu-Option" to={'#'} replace>
          {i18n.t('logout')}
        </NavLink>
      </div>
    </div>
  )
}
