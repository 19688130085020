import type {
  DOMConversionMap,
  DOMConversionOutput,
  EditorConfig,
  LexicalNode,
  NodeKey,
  SerializedTextNode,
  Spread,
} from 'lexical'

import { TextNode } from 'lexical'
import twemoji from '@twemoji/api'

export type SerializedTwemojiNode = Spread<
  {
    type: 'twemoji'
  },
  SerializedTextNode
>

export class TwemojiNode extends TextNode {
  static getType(): string {
    return 'twemoji'
  }

  static clone(node: TwemojiNode): TwemojiNode {
    return new TwemojiNode(node.__text, node.__key)
  }

  constructor(text: string, key?: NodeKey) {
    super(text, key)
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = document.createElement('span')
    dom.className = 'emoji'
    dom.innerText = this.__text
    twemoji.parse(dom, {
      className: 'Create-Post-Emoji',
    })
    return dom
  }

  static importJSON(serializedNode: SerializedTwemojiNode): TwemojiNode {
    const node = $createTwemojiNode(serializedNode.text)
    node.setFormat(serializedNode.format)
    node.setDetail(serializedNode.detail)
    node.setMode(serializedNode.mode)
    node.setStyle(serializedNode.style)
    return node
  }

  static importDOM(): DOMConversionMap | null {
    return {
      img: (node: Node) => {
        if (node instanceof HTMLImageElement && node.className === 'Create-Post-Emoji') {
          return { conversion: convertEmojiElement, priority: 3 }
        }
        return null
      },
    }
  }

  exportJSON(): SerializedTwemojiNode {
    return {
      ...super.exportJSON(),
      text: this.getEmojiText(),
      type: 'twemoji',
    }
  }

  getEmojiText(): string {
    const self = this.getLatest()
    return self.__text
  }
}

function convertEmojiElement(domNode: Node): DOMConversionOutput {
  let node = null
  if (domNode instanceof HTMLImageElement) {
    if (domNode.className === 'Create-Post-Emoji') node = $createTwemojiNode(domNode.getAttribute('alt') || '')
  }

  return { node }
}

export function $isTwemojiNode(node: LexicalNode | null | undefined): node is TwemojiNode {
  return node instanceof TwemojiNode
}

export function $createTwemojiNode(text: string): TwemojiNode {
  return new TwemojiNode(text).setMode('token')
}
