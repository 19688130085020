import { NavLink } from './navLink'
import './styles/navTabs.css'

export interface TabType {
  path: string
  tabName: string
  tabID: string
}
interface NavTabsProps {
  tabs: TabType[]
  activeTab: string
}

export function NavTabs({ tabs, activeTab }: NavTabsProps) {
  return (
    <div className="Nav-Tabs">
      {tabs.map((item, key) => (
        <NavLink
          replace
          key={`${item.tabID}-${key}`}
          to={item.path}
          className={`Nav-Tab ${activeTab === item.tabID && 'Nav-Tab-Active'}`}>
          {item.tabName}
        </NavLink>
      ))}
    </div>
  )
}
