import { useEffect } from 'react'
import { Outlet, Route, Routes, createBrowserRouter, createRoutesFromElements, useLocation } from 'react-router-dom'
import { useCardinalContext } from './context/cardinal'
import { MiniProfile } from './components/profile/display/miniProfile'
import { Nav } from './navigation/nav'
import { BackgroundManager } from './managers/backgroundManager/backgroundManager'
import { ConfirmedRoute } from './managers/redirectManager/confirmedRoute'
import { Home } from './pages/home'
import { AuthenticationModal } from './components/authentication/authenticationModal'
import { ModalPathNames, PathNameRoutes, PathNames } from './constants/pathNames'
import { TermsOfService } from './pages/termsOfService'
import { PrivacyPolicy } from './pages/privacyPolicy'
import { Profile } from './pages/profile'
import { FollowList } from './pages/followList'
import { DevTools } from './pages/devTools'
import { Post } from './pages/post'
import { RoadMap } from './pages/roadMap'
import Explore from '@mui/icons-material/Explore'
import { Bookmarks } from './pages/bookmarks'
import { Search } from './pages/search'
import { Hashtag } from './pages/hashtag'
import { Browse } from './pages/browse'
import { ProtectedRoute } from './managers/redirectManager/protectedRoute'
import { Messages } from './pages/messages'
import { ProfileSettings } from './pages/profileSettings'
import { Notifications } from './pages/notifications'
import { PostImagePreviewModal } from './components/feed/modal/postImagePreviewModal'
import { SetStatusRouteModal } from './navigation/setStatusRouteModal'
import { OpenImageModal } from './components/miscModals/openImageModal'
import { ImageEditorRouteModal } from './components/profile/editor/editorModals/imageEditorRouteModal'
import { VideoEditorRouteModal } from './components/profile/editor/editorModals/videoEditorRouteModal'
import { NavMobileProfileMenu } from './navigation/navMobileProfileMenu'
import { CreatePostRouteModal } from './components/feed/modal/createPostRouteModal'
import ScrollRestorationProvider from './context/scrollRestorationContext'

function RootRouteComponent() {
  const isDev = process.env.NODE_ENV === 'development' ? true : false
  const location = useLocation()
  const background = location.state && location.state.background
  const { isMobileView } = useCardinalContext()

  useEffect(() => {
    if (background) {
      document.documentElement.style.overflowY = 'hidden'
      if (isMobileView) document.documentElement.style.position = 'fixed'
    } else {
      document.documentElement.style.overflowY = 'scroll'
      //wonder if i should just make it unset so scroll thumb doesnt always show
      document.documentElement.style.position = 'unset'
    }
  }, [background])

  return (
    <ScrollRestorationProvider>
      <>
        <MiniProfile />
        <Nav />
        <BackgroundManager />

        <div className="App-Browser">
          <Routes location={background || location}>
            <Route
              index
              element={
                <ConfirmedRoute>
                  <Home />
                </ConfirmedRoute>
              }
            />
            <Route
              path={PathNames.TOS}
              element={
                <ConfirmedRoute>
                  <TermsOfService />
                </ConfirmedRoute>
              }
            />
            <Route
              path={PathNames.Privacy}
              element={
                <ConfirmedRoute>
                  <PrivacyPolicy />
                </ConfirmedRoute>
              }
            />
            <Route
              path={PathNames.Profile}
              element={
                <ConfirmedRoute>
                  <Profile />
                </ConfirmedRoute>
              }
            />
            <Route
              path={PathNames.ProfileTab}
              element={
                <ConfirmedRoute>
                  <Profile />
                </ConfirmedRoute>
              }
            />
            <Route
              path={PathNames.ProfileActivityTab}
              element={
                <ConfirmedRoute>
                  <Profile />
                </ConfirmedRoute>
              }
            />
            <Route
              path={PathNames.Followers}
              element={
                <ConfirmedRoute>
                  <FollowList />
                </ConfirmedRoute>
              }
            />
            <Route
              path={PathNames.Following}
              element={
                <ConfirmedRoute>
                  <FollowList />
                </ConfirmedRoute>
              }
            />
            <Route
              path={PathNames.Followers_You_Follow}
              element={
                <ConfirmedRoute>
                  <FollowList />
                </ConfirmedRoute>
              }
            />
            <Route
              path={PathNames.DevTools}
              element={
                isDev ? (
                  <ConfirmedRoute>
                    <DevTools />
                  </ConfirmedRoute>
                ) : (
                  <ConfirmedRoute>
                    <Home />
                  </ConfirmedRoute>
                )
              }
            />
            <Route
              path={PathNames.Post}
              element={
                <ConfirmedRoute>
                  <Post />
                </ConfirmedRoute>
              }
            />
            <Route
              path={PathNames.RoadMap}
              element={
                <ConfirmedRoute>
                  <RoadMap />
                </ConfirmedRoute>
              }
            />
            <Route
              path={PathNames.Explore}
              element={
                <ConfirmedRoute>
                  <Explore />
                </ConfirmedRoute>
              }
            />
            <Route
              path={PathNames.Bookmarks}
              element={
                <ConfirmedRoute>
                  <Bookmarks />
                </ConfirmedRoute>
              }
            />
            <Route
              path={PathNames.Search}
              element={
                <ConfirmedRoute>
                  <Search />
                </ConfirmedRoute>
              }
            />
            <Route
              path={PathNames.SearchId}
              element={
                <ConfirmedRoute>
                  <Search />
                </ConfirmedRoute>
              }
            />
            <Route
              path={PathNames.Hashtag}
              element={
                <ConfirmedRoute>
                  <Hashtag />
                </ConfirmedRoute>
              }
            />
            <Route
              path={PathNames.Browse}
              element={
                <ConfirmedRoute>
                  <Browse />
                </ConfirmedRoute>
              }
            />
            <Route
              path={PathNames.Messages}
              element={
                <ProtectedRoute>
                  <Messages />
                </ProtectedRoute>
              }
            />
            <Route
              path={PathNames.MessagesId}
              element={
                <ProtectedRoute>
                  <Messages />
                </ProtectedRoute>
              }
            />
            <Route
              path={PathNames.Settings}
              element={
                <ProtectedRoute>
                  <ProfileSettings />
                </ProtectedRoute>
              }
            />
            <Route
              path={PathNames.SettingsTab}
              element={
                <ProtectedRoute>
                  <ProfileSettings />
                </ProtectedRoute>
              }
            />
            <Route
              path={PathNames.Notifications}
              element={
                <ProtectedRoute>
                  <Notifications />
                </ProtectedRoute>
              }
            />
          </Routes>

          <Routes location={location}>
            <Route path={PathNames.Authentication} element={<AuthenticationModal />} />
            <Route
              path={ModalPathNames.PostImage}
              element={
                <ConfirmedRoute>
                  <PostImagePreviewModal />
                </ConfirmedRoute>
              }
            />
            <Route
              path={ModalPathNames.SetStatus}
              element={
                <ConfirmedRoute>
                  <SetStatusRouteModal />
                </ConfirmedRoute>
              }
            />
            <Route
              path={ModalPathNames.OpenImage}
              element={
                <ConfirmedRoute>
                  <OpenImageModal />
                </ConfirmedRoute>
              }
            />
            <Route
              path={ModalPathNames.ShowcaseImageEditor}
              element={
                <ConfirmedRoute>
                  <ImageEditorRouteModal />
                </ConfirmedRoute>
              }
            />
            <Route
              path={ModalPathNames.ShowcaseVideoEditor}
              element={
                <ConfirmedRoute>
                  <VideoEditorRouteModal />
                </ConfirmedRoute>
              }
            />
            <Route
              path={ModalPathNames.MobileProfileNav}
              element={
                <ConfirmedRoute>
                  <NavMobileProfileMenu />
                </ConfirmedRoute>
              }
            />
            <Route
              path={ModalPathNames.CreatePost}
              element={
                <ProtectedRoute>
                  <CreatePostRouteModal />
                </ProtectedRoute>
              }
            />
          </Routes>

          <Outlet />
        </div>
      </>
    </ScrollRestorationProvider>
  )
}

export const AppRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootRouteComponent />}>
      {PathNameRoutes.map((item, index) => (
        <Route key={index} path={item} element={null} />
      ))}
    </Route>
  )
)
