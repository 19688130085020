import { ProfileAvatar } from '../../profile/display/profileAvatar'
import BugReportSharpIcon from '@mui/icons-material/BugReportSharp'
import AddIcon from '@mui/icons-material/Add'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import { NavLink } from '../../../navigation/navLink'

import i18n from 'i18next'

export function WelcomeLego() {
  return (
    <div className="Sidebar-Container-Item">
      <h2 className="Sidebar-Container-Item-Header">{i18n.t('welcome')}</h2>
      <div className="Sidebar-Welcome-Container">
        <div className="Sidebar-Welcome-Item">
          <div className="Sidebar-Welcome-Item-Link-Container">
            <NavLink to="#" className="Sidebar-Welcome-Item-Link">
              <BugReportSharpIcon />
            </NavLink>
          </div>
          <div className="Sidebar-Welcome-Item-Info">
            <h2>Report a bug</h2>
            <p>Found something weird? Let us know about it!</p>
          </div>
        </div>
        <div className="Sidebar-Welcome-Item">
          <div className="Sidebar-Welcome-Item-Link-Container">
            <NavLink to="#" className="Sidebar-Welcome-Item-Link">
              <AddIcon />
            </NavLink>
          </div>
          <div className="Sidebar-Welcome-Item-Info">
            <h2>Request a feature</h2>
            <p>Think of something we're missing? Request a feature and we'll see if we can add it!</p>
          </div>
        </div>
        <div className="Sidebar-Welcome-Item">
          <div className="Sidebar-Welcome-Item-Link-Container">
            <NavLink to="#" className="Sidebar-Welcome-Item-Link">
              <QuestionMarkIcon />
            </NavLink>
          </div>
          <div className="Sidebar-Welcome-Item-Info">
            <h2>Help</h2>
            <p>Need help with something? Submit a ticket and we'll help when we get a chance!</p>
          </div>
        </div>
      </div>
    </div>
  )
}
