import './loading.css'

interface LoadingProps extends React.HTMLAttributes<HTMLDivElement> {
  disableText?: boolean
  className?: string
}

export function Loading({ disableText, className, style }: LoadingProps) {
  return (
    <div className={`Loading-Cubes-Container ${className}`} style={style}>
      <div className="Loading-Cubes">
        <div className="Loading-Cube Loading-Cube-1"></div>
        <div className="Loading-Cube Loading-Cube-2"></div>
      </div>
      {!disableText && <h2>Loading..</h2>}
    </div>
  )
}
