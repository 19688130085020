import { useLocation } from 'react-router-dom'
import { useCardinalContext } from '../context/cardinal'
import './styles/navProfileMenu.css'
import './styles/navQuickOptionsMenu.css'
import { ClickAwayListener } from '../components/modal/clickAwayListener'
import { getAuth } from 'firebase/auth'
import i18n from 'i18next'
import { NavLink } from './navLink'

interface NavProfileMenuProps {
  closeMenuFunction: () => void
}

export function NavProfileMenu({ closeMenuFunction }: NavProfileMenuProps) {
  const location = useLocation()
  const { user, isMobileView } = useCardinalContext()

  return (
    <ClickAwayListener onClickAway={() => closeMenuFunction()} id="Nav-Profile-Menu">
      <div className="Nav-Profile-Menu">
        <div className="Nav-Profile-Menu-Options">
          <NavLink
            to={`/profile/${user?.handle}`}
            className="Nav-Profile-Menu-Option"
            onClick={() => closeMenuFunction()}>
            {i18n.t('view-my-profile')} ({user?.handle})
          </NavLink>
          {isMobileView && (
            <>
              <NavLink
                to={`/notifications/all`}
                className="Nav-Notification-Menu-Option"
                onClick={() => closeMenuFunction()}>
                {i18n.t('notifications')}
              </NavLink>
              <NavLink to={`/messages`} className="Nav-Notification-Menu-Option" onClick={() => closeMenuFunction()}>
                {i18n.t('messages')}
              </NavLink>
            </>
          )}

          <NavLink
            to={'/status'}
            state={{ background: location }}
            className="Nav-Profile-Menu-Option"
            onClick={() => closeMenuFunction()}>
            {i18n.t('set-status')}
          </NavLink>
          <NavLink to="/bookmarks" className="Nav-Profile-Menu-Option" onClick={() => closeMenuFunction()}>
            {i18n.t('bookmarks')}
          </NavLink>
          <NavLink to="/settings/general" className="Nav-Profile-Menu-Option" onClick={() => closeMenuFunction()}>
            {i18n.t('settings')}
          </NavLink>
          <button
            className="Nav-Profile-Menu-Option Nav-Profile-Menu-Option-Logout"
            onClick={() => {
              closeMenuFunction()
              getAuth().signOut()
            }}>
            {i18n.t('logout')}
          </button>
        </div>
      </div>
    </ClickAwayListener>
  )
}
