import { useEffect, useMemo } from 'react'
import {
  ListTrendingPostsQuery,
  ListTrendingPostsQueryVariables,
  useListTrendingPostsLazyQuery,
} from '../../generated/graphql'
import { BrowsingState, useCardinalContext } from '../../../context/cardinal'
import { LazyQueryHookOptions } from '@apollo/client'
import { useScrollRestoration } from '../../../context/scrollRestorationContext'
import { POSTS_PER_PAGE } from '../../consts'
import { useLocation } from 'react-router-dom'

export function useListTrendingPosts() {
  const { user, browsingState } = useCardinalContext()
  const [listTrendingPosts, { data, loading, fetchMore }] = useListTrendingPostsLazyQuery()
  const { saveRestoredPostListOffset, postListOffsetRef } = useScrollRestoration()
  const location = useLocation()

  const queryOptions: LazyQueryHookOptions<ListTrendingPostsQuery, ListTrendingPostsQueryVariables> = useMemo(() => {
    const offset = postListOffsetRef.current[location.pathname]?.offset
    return {
      variables: {
        limit: (offset ?? 0) + POSTS_PER_PAGE,
        offset: 0,
        currentUserHandle: user?.handle ?? '',
      },
      fetchPolicy: offset !== undefined ? 'cache-first' : 'cache-and-network',
    }
  }, [user?.handle])

  useEffect(() => {
    if (browsingState !== BrowsingState.Loading) {
      listTrendingPosts(queryOptions)

      if (!postListOffsetRef.current[location.pathname]?.offset) {
        saveRestoredPostListOffset(0)
      }
    }
  }, [browsingState, listTrendingPosts, queryOptions])

  const handleFetchMore = () => {
    const offset = data?.trending_posts.length ?? 0
    saveRestoredPostListOffset(offset)
    return fetchMore({ variables: { ...queryOptions.variables, offset, limit: POSTS_PER_PAGE } })
  }

  const handleRefetch = () => {
    return listTrendingPosts({
      variables: { ...queryOptions.variables, limit: POSTS_PER_PAGE },
      fetchPolicy: 'network-only',
    })
  }

  return {
    data,
    loading,
    fetchMore: handleFetchMore,
    refetch: handleRefetch,
  }
}
