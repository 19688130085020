import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
  split,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition, offsetLimitPagination } from '@apollo/client/utilities'
import { createClient } from 'graphql-ws'
import { useRef } from 'react'
import { useAuthHeader } from './useAuthHeader'
import { getAuth } from 'firebase/auth'
import { toast } from 'react-toastify'

export function CardinalApolloProvider({ children }: any) {
  const authHeader = useAuthHeader()
  const client = useRef<ApolloClient<NormalizedCacheObject>>()
  const authHeaderRef = useRef(authHeader)
  authHeaderRef.current = authHeader

  if (!client.current) {
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          ...authHeaderRef.current,
        },
      }
    })

    const httpLink = createHttpLink({
      uri: 'https://project113.hasura.app/v1/graphql',
    })

    const wsLink = new GraphQLWsLink(
      createClient({
        url: 'wss://project113.hasura.app/v1/graphql',
        connectionParams: () => ({
          headers: {
            ...authHeaderRef.current,
          },
        }),
      })
    )

    const errorLink = onError(({ graphQLErrors }) => {
      if (graphQLErrors) {
        const auth = getAuth()
        graphQLErrors.some(async ({ extensions }) => {
          if (extensions?.code === 'invalid-jwt') {
            try {
              const newToken = await auth.currentUser?.getIdToken(true)
              authHeaderRef.current = { authorization: `Bearer ${newToken}` }
              toast('Something went wrong. Please try again. If the problem persists, refresh the page to continue.')
            } catch (error) {
              console.log('Error refreshing token:', error)
              toast('Your session has expired. Please refresh the page to continue.')
            }
            return true
          }
        })
      }
    })

    client.current = new ApolloClient({
      link: split(
        ({ query }) => {
          const definition = getMainDefinition(query)
          return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
        },
        errorLink.concat(wsLink),
        errorLink.concat(authLink.concat(httpLink))
      ),
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              post: offsetLimitPagination(['where']),
            },
          },
          bookmarked_posts: {
            keyFields: ['post_id', 'owner_handle'],
          },
          follower_content: {
            keyFields: ['owner_handle'],
          },
          emoji: {
            keyFields: ['key'],
          },
          followers: {
            keyFields: ['follower_handle', 'following_handle'],
          },
          hashtag: {
            keyFields: ['name'],
          },
          hashtag_followers: {
            keyFields: ['follower_handle', 'following_tag'],
          },
          hashtaged_posts: {
            keyFields: ['post_id', 'hashtag'],
          },
          message_group_member: {
            keyFields: ['message_group_id', 'member_handle'],
          },
          message_group: {
            keyFields: ['id'],
          },
          messages: {
            keyFields: ['id'],
          },
          post: {
            keyFields: ['id'],
            fields: {
              votes: {
                merge(_existing, incoming) {
                  return incoming
                },
              },
              bookmarked_posts: {
                merge(_existing, incoming) {
                  return incoming
                },
              },
            },
          },
          post_vote: {
            keyFields: ['owner_handle', 'post_id'],
          },
          subscriptions: {
            keyFields: ['subscribed_handle', 'subscribed_to_handle'],
          },
          user: {
            keyFields: ['handle'],
            fields: {
              followers: {
                merge(_existing, incoming) {
                  return incoming
                },
              },
            },
          },
          user_notifications: {
            keyFields: ['id'],
          },
        },
      }),
    })
  }

  return <ApolloProvider client={client.current}>{children}</ApolloProvider>
}
