import { Post_Insert_Input, User_Insert_Input } from '../gql/generated/graphql'
import {
  GalleryShowcase,
  ImageShowcase,
  InfoShowcase,
  ProfileTab,
  ShowcaseType,
  SocialLink,
  StreamProvider,
  VideoShowcase,
} from '../gql/scalars'
import { buildSocialLink } from '../utils/statusUtils'

export const MOCK_SHOWCASE_1: InfoShowcase = {
  type: ShowcaseType.Info,
  title: 'hithere',
  description: 'hello',
  text: " Hello? \n Is there anybody in there? \n Just nod if you can hear me. \n Is there anyone at home? \n Come on, now, \n I hear you're feeling down. \n Well I can ease your pain \n Get you on your feet again. \n Relax. \n I'll need some information first. \n Just the basic facts. \n Can you show me where it hurts? \n ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ \n Setup \n GPU: GeForce RTX 2070 Super \n CPU: AMD Ryzen 9 5900x \n RAM: 32 GB DDR4 3600Hz \n Motherboard: B550 Tomahawk \n Case: NZXT h710i \n Monitor: 1920 x 1080, 144hz \n Mouse: Logitech G502 Hero \n Keyboard: 80% Pandatek;Brown switches \n Mic: AT2035 XLR \n Headset: Logitech G633 \n~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~",
}

export const MOCK_SHOWCASE_2: ImageShowcase = {
  type: ShowcaseType.Images,
  title: 'hithere',
  description: 'hello',
  items: [
    {
      images: [
        'https://pbs.twimg.com/media/FUuFWMfaUAEHKWG?format=jpg&name=large',
        'https://pbs.twimg.com/media/FPHuS6ZVQAAAMl0?format=jpg&name=4096x4096',
        'https://images6.alphacoders.com/512/thumb-1920-512323.jpg',
      ],
      link_text: 'Character Sheet',
      link: 'https://www.youtube.com/watch?v=k2jON6ZHU_k',
      description: 'By Michi',
    },
    {
      images: [
        'https://pbs.twimg.com/media/FReVM_nXEAA4WCG?format=jpg&name=large',
        'https://images5.alphacoders.com/108/thumb-1920-1083796.png',
        //   'https://i.pinimg.com/originals/9a/c2/2b/9ac22bba95fcd4bb6ff2d5cf20cd8b8c.png',
      ],
      link_text: 'Commissions',
      link: 'https://www.youtube.com/watch?v=k2jON6ZHU_k',
      description: 'By Kaguya',
    },
  ],
}

export const MOCK_SHOWCASE_3: VideoShowcase = {
  type: ShowcaseType.Video,
  title: 'hithere',
  description: 'hello',
  items: [
    {
      link: 'https://www.youtube.com/watch?v=spi6yOS6zy4',
      video_id: 'spi6yOS6zy4',
      thumbnail: 'http://img.youtube.com/vi/spi6yOS6zy4/maxresdefault.jpg',
      embed_link: 'https://www.youtube.com/embed/spi6yOS6zy4?&autoplay=1',
      title: 'SEISO 『Doja Cat "Say So" parody cover』',
      description: 'Nyanners',
      type: 'Youtube',
    },
    {
      link: 'https://www.youtube.com/watch?v=0X7rw11LBdo',
      video_id: '0X7rw11LBdo',
      thumbnail: 'http://img.youtube.com/vi/0X7rw11LBdo/maxresdefault.jpg',
      embed_link: 'https://www.youtube.com/embed/0X7rw11LBdo?&autoplay=1',
      title: 'Nightcore - Kings & Queens (Lyrics)',
      description: 'NightcoreChase',
      type: 'Youtube',
    },
    {
      link: 'https://www.youtube.com/watch?v=k2jON6ZHU_k',
      video_id: 'k2jON6ZHU_k',
      thumbnail: 'http://img.youtube.com/vi/k2jON6ZHU_k/maxresdefault.jpg',
      embed_link: 'https://www.youtube.com/embed/k2jON6ZHU_k?&autoplay=1',
      title: 'Top 100 Songs Of NEFFEX - Best of NEFFEX',
      description: 'Kurumi',
      type: 'Youtube',
    },
    {
      link: 'https://www.youtube.com/watch?v=k2jON6ZHU_k',
      video_id: 'k2jON6ZHU_k',
      thumbnail: 'http://img.youtube.com/vi/k2jON6ZHU_k/maxresdefault.jpg',
      embed_link:
        'https://clips.twitch.tv/embed?autoplay=true&clip=CovertCalmPassionfruitBIRB-SRHmdacLCh6ePNz1&parent=localhost&player=popout&muted=false&playsinline=true&/',
      title: 'Singsing Twitch Clip',
      description: 'SingSing',
      type: 'Twitch',
    },
  ],
}

export const MOCK_SHOWCASE_4: GalleryShowcase = {
  type: ShowcaseType.Gallery,
  title: 'hithere',
  description: 'hello',
  images: [
    'https://pbs.twimg.com/media/FUuFWMfaUAEHKWG?format=jpg&name=large',
    'https://images-na.ssl-images-amazon.com/images/I/71WbcekHTbL.jpg',
    'https://wallpaperaccess.com/full/5707282.jpg',
    'https://cdnb.artstation.com/p/marketplace/presentation_assets/000/565/291/large/file.jpg?1603712584',
    'https://cdn.vox-cdn.com/thumbor/xBIBkXiGLcP-kph3pCX61U7RMPY=/0x0:1400x788/1200x800/filters:focal(588x282:812x506)/cdn.vox-cdn.com/uploads/chorus_image/image/70412073/0377c76083423a1414e4001161e0cdffb0b36e1f_760x400.0.png',
    'https://i.pinimg.com/originals/e3/69/9b/e3699bee9c7b6f8080bd2f69e7886cac.jpg',
    'https://i.pinimg.com/originals/10/91/94/1091948c6b80b65b9eef8c163f0ae42a.jpg',
    'https://i1.wp.com/anitrendz.net/news/wp-content/uploads/2022/04/My-Dress-Up-Darling-Wins.jpg?resize=700%2C476',
    'https://w0.peakpx.com/wallpaper/530/887/HD-wallpaper-anime-girl-anime-beautiful-anime-girl-cartoon-cute-anime-girl-cute-girl-thumbnail.jpg',
    'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/anime-1603495240.jpg?crop=0.502xw:1.00xh;0.249xw,0&resize=640:*',
  ],
}

export const MOCK_PROFILE_TAB: ProfileTab[] = [
  {
    name: 'Home',
    showcases: [MOCK_SHOWCASE_1, MOCK_SHOWCASE_2, MOCK_SHOWCASE_3, MOCK_SHOWCASE_4],
  },
]
export const MOCK_PROFILE_TAB_ALT: ProfileTab[] = [
  {
    name: 'Home',
    showcases: [MOCK_SHOWCASE_1, MOCK_SHOWCASE_2, MOCK_SHOWCASE_3, MOCK_SHOWCASE_4],
  },
  {
    name: 'Youtube',
    showcases: [MOCK_SHOWCASE_3, MOCK_SHOWCASE_4, MOCK_SHOWCASE_1, MOCK_SHOWCASE_2],
  },
]

export const MOCK_TEST_IMAGESHOWCASE = {
  type: 'image-Showcase',
  title: 'Syrex Uploads',
  desc: 'Playlist with all the nightcore songs uploaded on Syrex!',
  showcaseData: [
    {
      images: [
        'https://pbs.twimg.com/media/FUuFWMfaUAEHKWG?format=jpg&name=large',
        'https://pbs.twimg.com/media/FPHuS6ZVQAAAMl0?format=jpg&name=4096x4096',
        'https://images6.alphacoders.com/512/thumb-1920-512323.jpg',
      ],
      linkText: 'Character Sheet',
      link: 'https://www.youtube.com/watch?v=k2jON6ZHU_k',
      desc: 'By Michi',
    },
    {
      images: [
        'https://pbs.twimg.com/media/FReVM_nXEAA4WCG?format=jpg&name=large',
        'https://images5.alphacoders.com/108/thumb-1920-1083796.png',
      ],
      linkText: 'Commissions',
      link: 'https://www.youtube.com/watch?v=k2jON6ZHU_k',
      desc: 'By Kaguya',
    },
  ],
}
export const MOCK_TEST_IMAGESHOWCASE2 = {
  type: 'image-Showcase',
  title: 'Syrex Uploads',
  desc: 'Playlist with all the nightcore songs uploaded on Syrex!',
  showcaseData: [
    {
      images: [
        'https://pbs.twimg.com/media/FUuFWMfaUAEHKWG?format=jpg&name=large',
        'https://pbs.twimg.com/media/FPHuS6ZVQAAAMl0?format=jpg&name=4096x4096',
        'https://images6.alphacoders.com/512/thumb-1920-512323.jpg',
      ],
      linkText: 'Character Sheet',
      link: 'https://www.youtube.com/watch?v=k2jON6ZHU_k',
      desc: 'By Michi',
    },
  ],
}

export const MOCK_TEST_VIDEOSHOWCASE = {
  type: 'video-Showcase',
  title: 'hithere',
  desc: 'hello',
  showcaseData: [
    {
      link: 'https://www.youtube.com/watch?v=spi6yOS6zy4',
      videoId: 'spi6yOS6zy4',
      thumbnail: 'http://img.youtube.com/vi/spi6yOS6zy4/maxresdefault.jpg',
      embedLink: 'https://www.youtube.com/embed/spi6yOS6zy4?&autoplay=1',
      title: 'SEISO 『Doja Cat "Say So" parody cover』',
      desc: 'Nyanners',
      type: 'Youtube',
    },
    {
      link: 'https://www.youtube.com/watch?v=0X7rw11LBdo',
      videoId: '0X7rw11LBdo',
      thumbnail: 'http://img.youtube.com/vi/0X7rw11LBdo/maxresdefault.jpg',
      embedLink: 'https://www.youtube.com/embed/0X7rw11LBdo?&autoplay=1',
      title: 'Nightcore - Kings & Queens (Lyrics)',
      desc: 'NightcoreChase',
      type: 'Youtube',
    },
    {
      link: 'https://www.youtube.com/watch?v=k2jON6ZHU_k',
      videoId: 'k2jON6ZHU_k',
      thumbnail: 'http://img.youtube.com/vi/k2jON6ZHU_k/maxresdefault.jpg',
      embedLink: 'https://www.youtube.com/embed/k2jON6ZHU_k?&autoplay=1',
      title: 'Top 100 Songs Of NEFFEX - Best of NEFFEX',
      desc: 'Kurumi',
      type: 'Youtube',
    },
    {
      link: 'https://www.youtube.com/watch?v=k2jON6ZHU_k',
      videoId: 'k2jON6ZHU_k',
      thumbnail: 'http://img.youtube.com/vi/k2jON6ZHU_k/maxresdefault.jpg',
      embedLink:
        'https://clips.twitch.tv/embed?autoplay=true&clip=CovertCalmPassionfruitBIRB-SRHmdacLCh6ePNz1&parent=localhost&player=popout&muted=false&playsinline=true&/',
      title: 'Singsing Twitch Clip',
      desc: 'SingSing',
      type: 'Twitch',
    },
  ],
}

export const MOCK_TEST_INFOSHOWCASE = {
  type: 'info-Showcase',
  title: 'hithere',
  desc: 'hello',
  showcaseData: [
    {
      text: "# I am a header \n Yolo Hello? 🥳 \n Is there anybody in there? \n [Link to Youtube](https://www.youtube.com/watch?v=cnUwcwBkETs) Just nod if you can hear me. \n Is there anyone at home? \n Come on, now, \n I hear you're feeling down. \n Well I can ease your pain \n Get you on your feet again. \n Relax. \n I'll need some information first. \n Just the basic facts. \n Can you show me where it hurts? \n ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ \n Setup \n GPU: GeForce RTX 2070 Super \n CPU: AMD Ryzen 9 5900x \n RAM: 32 GB DDR4 3600Hz \n Motherboard: B550 Tomahawk \n Case: NZXT h710i \n Monitor: 1920 x 1080, 144hz \n Mouse: Logitech G502 Hero \n Keyboard: 80% Pandatek;Brown switches \n Mic: AT2035 XLR \n Headset: Logitech G633 \n~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~",
    },
  ],
}
export const MOCK_TEST_GALLERYSHOWCASE = {
  type: 'gallery-Showcase',
  title: 'My Amazing Artwork!',
  desc: 'Gallery',
  showcaseData: [
    {
      images: [
        'https://pbs.twimg.com/media/FUuFWMfaUAEHKWG?format=jpg&name=large',
        'https://images-na.ssl-images-amazon.com/images/I/71WbcekHTbL.jpg',
        'https://wallpaperaccess.com/full/5707282.jpg',
        'https://cdnb.artstation.com/p/marketplace/presentation_assets/000/565/291/large/file.jpg?1603712584',
        'https://cdn.vox-cdn.com/thumbor/xBIBkXiGLcP-kph3pCX61U7RMPY=/0x0:1400x788/1200x800/filters:focal(588x282:812x506)/cdn.vox-cdn.com/uploads/chorus_image/image/70412073/0377c76083423a1414e4001161e0cdffb0b36e1f_760x400.0.png',
        'https://i.pinimg.com/originals/e3/69/9b/e3699bee9c7b6f8080bd2f69e7886cac.jpg',
        'https://i.pinimg.com/originals/10/91/94/1091948c6b80b65b9eef8c163f0ae42a.jpg',
        'https://i1.wp.com/anitrendz.net/news/wp-content/uploads/2022/04/My-Dress-Up-Darling-Wins.jpg?resize=700%2C476',
        'https://w0.peakpx.com/wallpaper/530/887/HD-wallpaper-anime-girl-anime-beautiful-anime-girl-cartoon-cute-anime-girl-cute-girl-thumbnail.jpg',
        'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/anime-1603495240.jpg?crop=0.502xw:1.00xh;0.249xw,0&resize=640:*',
      ],
    },
  ],
}

export const ROADMAP_TEXT = {
  text: '# Roadmap Document \n ### Updated July, 31, 2022 \n ##### App Version 1.0\n --- \n ### Planned Features: \n - Polls \n - Custom Profile Frames \n - User Made & Moderated Hastags (Public Hashtags Still Exist) \n - Teams/Groups \n - Embed Posts \n - Game Dev Dedicated Showcases \n - Gif Profile Pictures \n - Game Update News From Steam \n - Site Rankings \n --- \n ### Community Suggested Features: \n - Please suggest features and we can look into what we can build! \n \n  (Select the + button at the top of this page and fill out the form to submit a feature request) \n --- \n --- \n ### Known Bugs: \n - None ATM \n ### Developers: \n - Ghouldura \n - Rejis',
}

const MOCK_SOCIAL_LINKS: SocialLink[] = [
  buildSocialLink('https://www.twitch.tv/ghouldura'),
  buildSocialLink('https://www.youtube.com/watch?v=NhndeYcXRHA&list=RDVUFLv7erBG8&index=2'),
  buildSocialLink('https://twitter.com/ghouldura'),
  buildSocialLink('https://www.tiktok.com/@ghouldura'),
  buildSocialLink('https://discord.gg/S3TMK2XE'),
]

export const MOCK_USERS: User_Insert_Input[] = [
  {
    auth_id: '30cb235f-cf33-4ff0-86e1-71409a5bf16a',
    username: 'rejis',
    handle: 'rejis',
    search_field: 'REJIS REJIS',
    profile_image: 'https://avatars.cloudflare.steamstatic.com/9af7f447a6f1d3567f1887efd75f49755155d4cb_full.jpg',
    social_links: MOCK_SOCIAL_LINKS,
    profile_tabs: MOCK_PROFILE_TAB,
    banner: 'https://project11301826-developer.s3.amazonaws.com/public/5a192aae-566d-427b-855c-71acc25b2d11.jpeg',
    twitch_connection_id: 'Zentreya',
    youtube_connection_id: 'iXdXBbJ-jtc',
    stream_provider: StreamProvider.None,
    mini_profile_background:
      'https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/619150/a7e5f47e2808e1e9fdfa0a2ca905dc2daeaaec87.webm',
    display_mini_profile_social_links: true,
  },
  {
    auth_id: '22ff752a-d37e-44b2-ba49-c83bf502e57b',
    username: '👾👾Ghouldura👾👾',
    handle: '88yj',
    search_field: '👾👾GHOULDURA👾👾 👾👾GHOULDURA👾👾',
    profile_image: 'https://pbs.twimg.com/media/FUEJRB1WAAA5Ghj?format=jpg&name=4096x4096',
    social_links: MOCK_SOCIAL_LINKS,
    profile_tabs: MOCK_PROFILE_TAB_ALT,
    banner: 'https://project11301826-developer.s3.amazonaws.com/public/09a47c72-e811-4c9a-a038-5cea6fdad96a.jpeg',
    twitch_connection_id: 'CionKeiasta',
    youtube_connection_id: 'iXdXBbJ-jtc',
    stream_provider: StreamProvider.None,
    mini_profile_background:
      'https://project11301826-developer.s3.amazonaws.com/public/cb352065-4c6d-4642-b0f6-5f47143a9105.mp4',
    display_mini_profile_social_links: true,
  },
]
// https://cdn.akamai.steamstatic.com/steamcommunity/public/images/items/1065970/c7623fc2f998f6b0579ccfa5a9e3b6623a9a66f1.webm
export const MOCK_POSTS: Post_Insert_Input[] = buildMockPosts(['rejis', '88yj'], 15)

function buildMockPosts(handles: string[], postCount: number): Post_Insert_Input[] {
  let posts: Post_Insert_Input[] = []
  handles.forEach(handle => {
    for (let i = 0; i < postCount; i++) {
      posts.push({
        owner_handle: handle,
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      })
    }
  })
  return posts
}
