import { useEffect, useMemo } from 'react'
import { ListPostsQuery, ListPostsQueryVariables, Order_By, useListPostsLazyQuery } from '../../generated/graphql'
import { BrowsingState, useCardinalContext } from '../../../context/cardinal'
import { LazyQueryHookOptions } from '@apollo/client'
import { PostFilterType } from '../../filter'
import { useScrollRestoration } from '../../../context/scrollRestorationContext'
import { POSTS_PER_PAGE } from '../../consts'
import { useLocation } from 'react-router-dom'

export function useListPosts(filterType: PostFilterType, filterValue?: string) {
  const { user, browsingState } = useCardinalContext()
  const [listPosts, { data, loading, fetchMore }] = useListPostsLazyQuery()
  const { saveRestoredPostListOffset, postListOffsetRef } = useScrollRestoration()
  const location = useLocation()

  const queryOptions: LazyQueryHookOptions<ListPostsQuery, ListPostsQueryVariables> = useMemo(() => {
    const offset = postListOffsetRef.current[location.pathname]?.offset
    const commonOptions: LazyQueryHookOptions<ListPostsQuery, ListPostsQueryVariables> = {
      variables: {
        limit: (offset ?? 0) + POSTS_PER_PAGE,
        offset: 0,
        order_by: { created_at: Order_By.Desc },
        currentUserHandle: user?.handle ?? '',
      },
      fetchPolicy: offset !== undefined ? 'cache-first' : 'cache-and-network',
    }
    switch (filterType) {
      case PostFilterType.Following:
        return {
          ...commonOptions,
          variables: {
            ...commonOptions.variables,
            postFilter: user?.handle
              ? {
                  _or: [
                    { owner: { followers: { follower_handle: { _eq: user?.handle } } }, type: { _eq: 'post' } },
                    {
                      hashtags: { hashtagName: { hashtag_followers: { follower_handle: { _eq: user?.handle } } } },
                      type: { _eq: 'post' },
                    },
                  ],
                }
              : {},
          },
        }
      case PostFilterType.PostCreatedByUser:
        return {
          ...commonOptions,
          variables: {
            ...commonOptions.variables,
            postFilter: { owner_handle: { _eq: filterValue ?? '' }, type: { _eq: 'post' } },
          },
        }
      case PostFilterType.ReplyCreatedByUser:
        return {
          ...commonOptions,
          variables: {
            ...commonOptions.variables,
            postFilter: { owner_handle: { _eq: filterValue ?? '' }, type: { _eq: 'reply' } },
          },
        }
      case PostFilterType.MediaPostCreatedByUser:
        return {
          ...commonOptions,
          variables: {
            ...commonOptions.variables,
            postFilter: {
              owner_handle: { _eq: filterValue ?? '' },
              _or: [
                { video_url: { _is_null: false } },
                { image_urls: { _is_null: false } },
                { gif_url: { _is_null: false } },
              ],
            },
          },
        }
      case PostFilterType.LikedByUser:
        return {
          ...commonOptions,
          variables: {
            ...commonOptions.variables,
            postFilter: { votes: { owner_handle: { _eq: filterValue ?? '' } } },
          },
        }
      case PostFilterType.ByHashtag:
        return {
          ...commonOptions,
          variables: {
            ...commonOptions.variables,
            postFilter: { hashtags: { hashtag: { _eq: filterValue } }, type: { _eq: 'post' } },
          },
        }
      case PostFilterType.Replies:
        return {
          ...commonOptions,
          variables: {
            ...commonOptions.variables,
            postFilter: { reply_to_id: { _eq: filterValue ?? '' }, type: { _eq: 'reply' } },
          },
        }
      default:
        return commonOptions
    }
  }, [user?.handle, filterType, filterValue])

  useEffect(() => {
    if (browsingState !== BrowsingState.Loading) {
      listPosts(queryOptions)

      if (!postListOffsetRef.current[location.pathname]?.offset) {
        saveRestoredPostListOffset(0)
      }
    }
  }, [browsingState, listPosts, queryOptions])

  const handleFetchMore = () => {
    const offset = data?.post.length ?? 0
    saveRestoredPostListOffset(offset)
    return fetchMore({ variables: { ...queryOptions.variables, offset, limit: POSTS_PER_PAGE } })
  }

  const handleRefetch = () => {
    return listPosts({ variables: { ...queryOptions.variables, limit: POSTS_PER_PAGE }, fetchPolicy: 'network-only' })
  }

  return {
    data,
    loading,
    fetchMore: handleFetchMore,
    refetch: handleRefetch,
    queryOptions,
  }
}
