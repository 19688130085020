import { useLocation, useParams, matchPath } from 'react-router-dom'
import { PathNames } from '../constants/pathNames'
import { useEffect, useMemo, useState } from 'react'
import { useCardinalContext } from '../context/cardinal'
import {
  useGetFollowersLazyQuery,
  useGetFollowingLazyQuery,
  useGetFollowersYouFollowLazyQuery,
  Followers,
} from '../gql/generated/graphql'
import { ListProfileItem } from '../components/profile/display/listProfileItem'

import './styles/followList.css'
import { NavBackButtonHeader } from '../navigation/navBackButtonHeader'
import { NavTabs } from '../navigation/navTabs'
import { TabType } from '../navigation/navTabs'
import { Loading } from '../components/uiKit/loading/loading'

export function FollowList() {
  const { user } = useCardinalContext()
  const [getFollowers] = useGetFollowersLazyQuery()
  const [getFollowersYouFollow] = useGetFollowersYouFollowLazyQuery()
  const [getFollowing] = useGetFollowingLazyQuery()

  const { profile_id } = useParams()
  const location = useLocation()

  const [followList, setFollowList] = useState<Followers[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const following = matchPath({ path: PathNames.Following }, location.pathname)
  const followers = matchPath({ path: PathNames.Followers }, location.pathname)
  const followers_you_follow = matchPath({ path: PathNames.Followers_You_Follow }, location.pathname)

  async function getFollowingUsers() {
    let userData: any = await getFollowing({
      variables: {
        userHandle: profile_id ?? '',
        currentUserHandle: user?.handle ?? '',
      },
    })

    setFollowList(userData.data.user[0].following)
    setLoading(false)
  }

  async function getFollowersUsers() {
    let userData: any = await getFollowers({
      variables: {
        userHandle: profile_id ?? '',
        currentUserHandle: user?.handle ?? '',
      },
    })

    setFollowList(userData.data.user[0].followers)
    setLoading(false)
  }
  async function getFollowersYouFollowUsers() {
    let userData: any = await getFollowersYouFollow({
      variables: {
        userHandle: profile_id ?? '',
        currentUserHandle: user?.handle ?? '',
      },
    })

    setFollowList(userData.data.user[0].followers)
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    if (following) {
      getFollowingUsers()
    } else if (followers) {
      getFollowersUsers()
    } else if (followers_you_follow) {
      getFollowersYouFollowUsers()
    }
  }, [location])

  const followerItems = useMemo(() => {
    return followList.map((item: Followers, key: number) => (
      <div className="Follow-List-Profile-List-Item-Container" key={key}>
        <ListProfileItem
          user={followers || followers_you_follow ? item.follower_user : item.following_user}
          index={key}
          disableSocialLinks
        />
      </div>
    ))
  }, [followList])

  const tabs: TabType[] = [
    {
      path: `/following/${profile_id}`,
      tabName: 'Following',
      tabID: 'following',
    },
    {
      path: `/followers/${profile_id}`,
      tabName: 'Followers',
      tabID: 'followers',
    },
    {
      path: `/followers_you_follow/${profile_id}`,
      tabName: 'Followers You Follow',
      tabID: 'followers_you_follow',
    },
  ]

  return (
    <div className="Follow-List-Container">
      <div className="Follow-List-Header">
        <NavBackButtonHeader
          title={followers ? 'Followers' : followers_you_follow ? 'Followers You Follow' : 'Following'}
        />
        <NavTabs
          tabs={tabs}
          activeTab={followers ? 'followers' : followers_you_follow ? 'followers_you_follow' : 'following'}
        />
      </div>
      <div className="Follow-List-Body">{loading ? <Loading /> : followList && followerItems}</div>
    </div>
  )
}
