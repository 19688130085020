import { ApolloQueryResult, LazyQueryHookOptions } from '@apollo/client'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'
import { useSettings } from '../../utils/lexical/context/SettingsContext'
import SeisoNodes from '../../utils/lexical/nodes/SeisoNodes'
import SeisoEditorTheme from '../../utils/lexical/themes/SeisoEditorTheme'
import { CreateFeedItemForm } from './createFeedItemForm'
import './styles/feed.css'
import { useCardinalContext } from '../../context/cardinal'

export enum FeedType {
  Reply = 'reply',
  Post = 'post',
}

interface FeedProps {
  disableCreateFeedItem?: boolean
  newPostsNotification?: JSX.Element | null
  focusedItem?: JSX.Element | null
  items?: JSX.Element[]
  loading?: boolean
  feedType: FeedType
  fetchMore?: () => Promise<ApolloQueryResult<any>> | undefined
  noItemsMessage?: string
  fullScreen?: boolean
  queryOptions?: LazyQueryHookOptions
}

export function Feed({
  disableCreateFeedItem,
  newPostsNotification,
  focusedItem,
  items,
  loading,
  fetchMore,
  feedType,
  noItemsMessage,
  fullScreen,
  queryOptions,
}: FeedProps) {
  const { isMobileView } = useCardinalContext()
  const { post_id } = useParams()
  const [prevIntersectionTarget, setPrevIntersectionTarget] = useState<String | undefined>()

  const observer = useRef<IntersectionObserver>()
  const intersectionElementRef = useCallback(
    (node: HTMLElement | null) => {
      if (observer.current) {
        observer.current.disconnect()
      }
      observer.current = new IntersectionObserver(async ([entry]) => {
        if (entry.isIntersecting && fetchMore && prevIntersectionTarget !== entry.target.id) {
          setPrevIntersectionTarget(entry.target.id)
          fetchMore()
        }
      })
      if (node) {
        observer.current.observe(node)
      }
    },
    [fetchMore, observer, prevIntersectionTarget]
  )

  const loadingItems = useMemo(() => {
    return Array(10)
      .fill(undefined)
      .map((_value, index) => {
        return (
          <div className="Post-Container" key={index}>
            <div className="Post-Loading-Placeholder" />
          </div>
        )
      })
  }, [])

  // useEffect(() => {
  //   window.addEventListener('load', videoScroll)
  //   window.addEventListener('scroll', videoScroll)

  //   return () => {
  //     window.removeEventListener('load', videoScroll)
  //     window.removeEventListener('scroll', videoScroll)
  //   }
  // }, [])

  function videoScroll() {
    let videoEl: any = document.getElementsByClassName('Post-Video')
    if (videoEl.length > 0) {
      let windowHeight = window.innerHeight

      for (let i = 0; i < videoEl.length; i++) {
        let thisVideoEl = videoEl[i],
          videoHeight = thisVideoEl.clientHeight,
          videoClientRect = thisVideoEl.getBoundingClientRect().top

        if (
          videoClientRect <= windowHeight - videoHeight * 0.5 &&
          videoClientRect >= 0 - videoHeight * 0.5 &&
          thisVideoEl.readyState === 4
        ) {
          thisVideoEl.play()
        } else if (thisVideoEl.readyState === 4) {
          thisVideoEl.pause()
        }
      }
    }
  }

  useEffect(() => {
    // Intersection Observer callback
    const callback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          // This post is now visible in the viewport
          // You can handle this event as needed (e.g., mark it as read)
          // console.log('Post is visible:', entry.target)
          // if (entry.target.readyState === 4)
          entry.target.play()
        } else {
          entry.target.pause()
        }
      })
    }

    // Create the Intersection Observer
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: '0px', // No margin
      threshold: 0.1, // Trigger when 50% of the element is visible
    }

    const observer = new IntersectionObserver(callback, options)

    // Find and observe all post videos in the feed
    const postVideos = document.querySelectorAll('.Post-Video')
    postVideos.forEach(video => {
      observer.observe(video)
    })

    // Find and observe all post gif videos in the feed
    const postGifVideos = document.querySelectorAll('.Post-Gif-Video')
    postGifVideos.forEach(video => {
      observer.observe(video)
    })

    // Clean up the observer when the component unmounts
    return () => {
      observer.disconnect()
    }
  }, [items])

  const {
    settings: { emptyEditor },
  } = useSettings()

  const initialConfig = {
    editorState: emptyEditor ? undefined : undefined,
    namespace: 'Seiso',
    nodes: [...SeisoNodes],
    onError: (error: Error) => {
      throw error
    },
    theme: SeisoEditorTheme,
    editable: true,
  }

  return (
    <div
      className={`Feed-Container ${feedType === FeedType.Reply ? 'Feed-Container-Thread' : ''} ${
        fullScreen ? 'Feed-Container-Full-Screen' : ''
      }`}>
      {focusedItem}
      {!disableCreateFeedItem && !isMobileView && (
        <LexicalComposer initialConfig={initialConfig}>
          <CreateFeedItemForm feedType={feedType} threadId={post_id} queryOptions={queryOptions} />
        </LexicalComposer>
      )}
      {newPostsNotification}
      {(items?.length ?? 0) > 0 &&
        items?.map((item, index) => (
          <div
            id={item.key?.toString()} // DO NOT REMOVE: We use to determine if we should fetch more
            key={item.key}
            ref={
              (items.length >= 20 && index === items.length - 10) || index === items.length - 1
                ? intersectionElementRef
                : undefined
            }>
            {item}
          </div>
        ))}
      {loading
        ? loadingItems
        : items &&
          items.length === 0 &&
          noItemsMessage && <div className="No-Feed-Items-Message">{noItemsMessage}</div>}
    </div>
  )
}

export default Feed
