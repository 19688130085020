import { useEffect, useState, useRef } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { PathNames } from '../../constants/pathNames'
import { useCardinalContext } from '../../context/cardinal'
import { checkMediaType, tags } from '../../utils/converterUtils'
import './backgroundManager.css'

export function BackgroundManager() {
  const { currentProfile, user, isMobileView } = useCardinalContext()
  const location = useLocation()

  const [backgroundSrc, setBackgroundSrc] = useState<string>('')
  const [profileBackgroundSrc, setProfileBackgroundSrc] = useState<string>('')
  const [tagType, setTagType] = useState<tags | undefined>(undefined)
  const [tagTypeProfile, setTagTypeProfile] = useState<tags | undefined>(undefined)
  const background = location.state && location.state.background
  const isProfilePage =
    matchPath({ path: PathNames.Profile }, location.pathname) ||
    matchPath({ path: PathNames.ProfileTab }, location.pathname) ||
    matchPath({ path: PathNames.ProfileActivityTab }, location.pathname) ||
    (background && matchPath({ path: PathNames.ProfileTab }, background.pathname)) ||
    (background && matchPath({ path: PathNames.Profile }, background.pathname)) ||
    (background && matchPath({ path: PathNames.ProfileActivityTab }, background.pathname))

  useEffect(() => {
    if (user && user.app_background) {
      setBackgroundSrc(user.app_background)
    } else {
      setBackgroundSrc('')
    }
  }, [location, user])

  useEffect(() => {
    let videoEl: any = document.getElementById('App-Background-Video')
    setProfileBackgroundSrc('')
    if (currentProfile && currentProfile.profile_background && isProfilePage) {
      setProfileBackgroundSrc(currentProfile.profile_background)

      if (videoEl) videoEl.pause()
    } else if (videoEl) videoEl.play()
  }, [currentProfile, isProfilePage, location])

  useEffect(() => {
    setTagTypeProfile(checkMediaType(profileBackgroundSrc))
  }, [profileBackgroundSrc])

  useEffect(() => {
    setTagType(checkMediaType(backgroundSrc))
  }, [backgroundSrc])

  return (
    <>
      {backgroundSrc.length > 0 || profileBackgroundSrc.length > 0 ? (
        <div className="App-BG-Container" id="App-BG-Container">
          <div className="App-BG-Film" />
          {backgroundSrc.length > 0 &&
            !isMobileView &&
            (tagType === tags.video ? (
              <video
                id="App-Background-Video"
                autoPlay
                loop
                muted
                disablePictureInPicture
                playsInline
                src={backgroundSrc}
              />
            ) : (
              <img src={backgroundSrc} />
            ))}
          {isProfilePage &&
            profileBackgroundSrc.length > 0 &&
            !isMobileView &&
            (tagTypeProfile === tags.video ? (
              <video
                className="Profile-Background-Video Fade_In"
                autoPlay
                loop
                muted
                disablePictureInPicture
                playsInline
                src={profileBackgroundSrc}
              />
            ) : (
              <img className="Profile-Background-Image Fade_In" src={profileBackgroundSrc} />
            ))}
        </div>
      ) : null}
    </>
  )
}
