import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined'
import EditIcon from '@mui/icons-material/Edit'
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined'
import GifBoxOutlinedIcon from '@mui/icons-material/GifBoxOutlined'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import ListAltIcon from '@mui/icons-material/ListAlt'
import imageCompression from 'browser-image-compression'
import { ChangeEvent, SyntheticEvent, useEffect, useRef, useState } from 'react'
import sanitizeHTML from 'sanitize-html'
import { useCardinalContext } from '../../context/cardinal'
import {
  Post_Insert_Input,
  Hashtag_Insert_Input,
  User_Mentions_Insert_Input,
  User_Notifications_Insert_Input,
  useCreateNotificationMutation,
} from '../../gql/generated/graphql'
import { ProfileAvatar } from '../profile/display/profileAvatar'
import { sanitizeText, saveSelection, restoreSelection } from '../../utils/lexical/helper/lexicalHelper'
import { uploadToStorage } from '../../utils/cloudflareR2'
import { EmojiPicker } from '../uiKit/mediaModals/emojiPicker'
import { GifModal } from '../uiKit/mediaModals/gifModal'
import { CropImageModal } from '../uiKit/mediaModals/cropImageModal'
import { FeedType } from './feed'
import './styles/createPost.css'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  CLEAR_EDITOR_COMMAND,
  PASTE_COMMAND,
  COMMAND_PRIORITY_EDITOR,
  $getSelection,
  $isRangeSelection,
  $createTextNode,
  BLUR_COMMAND,
} from 'lexical'
import Editor from '../../utils/lexical/Editor'
import { toast } from 'react-toastify'
import { imageCompressionOptions } from '../../utils/constants'
import SendIcon from '@mui/icons-material/Send'
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined'
import i18n from 'i18next'
import { checkMediaType, tags } from '../../utils/converterUtils'
import { Button } from '../uiKit/button'
import { useCreatePost } from '../../gql/hooks/mutations/useCreatePost'
import { LazyQueryHookOptions } from '@apollo/client'
import { Loading } from '../uiKit/loading/loading'

interface CreateFeedItemFormProps {
  feedType: FeedType
  closeFormModal?: () => void
  threadId?: string
  modal?: boolean
  quoteRepostModalItem?: JSX.Element | null
  quoteRepostId?: string | null
  queryOptions?: LazyQueryHookOptions
}

export function CreateFeedItemForm({
  feedType,
  closeFormModal,
  threadId,
  modal,
  quoteRepostModalItem,
  quoteRepostId,
  queryOptions,
}: CreateFeedItemFormProps) {
  const [editor] = useLexicalComposerContext()

  const { isMobileView, user } = useCardinalContext()
  const [disableComponent, setDisableComponent] = useState<boolean>(false)
  const [postText, setPostText] = useState<string>('')
  const [mentions, setMentions] = useState<{ [key: string]: string }>({})
  const [hashtags, setHashtags] = useState<string[]>([])
  const [postImages, setPostImages] = useState<string[]>([])
  const [imagesOriginal, setImagesOriginal] = useState<string[]>([])
  const [postGif, setPostGif] = useState<string>('')
  const [postVideo, setPostVideo] = useState<string>()
  const [cropModal, setCropModal] = useState<boolean>(false)
  const [gifModal, setGifModal] = useState<boolean>(false)
  const [emojiModal, setEmojiModal] = useState<boolean>(false)
  const [modalPosition, setModalPosition] = useState<object>({})
  const [cropImageKey, setCropImageKey] = useState<number>(0)
  const [imagesBlobs, setImagesBlobs] = useState<File[]>([])
  const [contentWarning, setContentWarning] = useState<boolean>(false)
  const [videoBlob, setVideoBlob] = useState<File[]>([])
  const createPostMutation = useCreatePost(queryOptions)
  const [selection, setSelection] = useState()
  const [createNotificationMutation] = useCreateNotificationMutation()
  const loadedMedia = useRef<{ [key: string]: { height: number; width: number } }>({})

  function clearEditor() {
    editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined)
  }

  // const removeBlurListener = editor.registerCommand<FocusEvent>(
  //   BLUR_COMMAND,
  //   () => {
  //     setSelection(saveSelection())
  //     return false
  //   },
  //   COMMAND_PRIORITY_EDITOR
  // )

  const removePasteListener = editor.registerCommand<ClipboardEvent>(
    PASTE_COMMAND,
    (event: ClipboardEvent) => {
      event.preventDefault()
      if (event?.clipboardData?.files[0] != null) {
        pasteUpload(event.clipboardData?.files[0])
        return true
      }
      return false
    },
    COMMAND_PRIORITY_EDITOR
  )

  useEffect(() => {
    return () => {
      removePasteListener()
      // removeBlurListener()
    }
  }, [])

  function addEmojiLexical(selectedOption: any) {
    if (modal) document.getElementById('Post-Input-Modal')?.focus()
    else document.getElementById('Post-Input')?.focus()

    // restoreSelection(selection, modal ? 'Post-Input-Alt-Modal' : 'Post-Input-Alt')
    editor.update(() => {
      const selection = $getSelection()

      if (!$isRangeSelection(selection) || selectedOption == null) {
        return
      }

      selection.insertNodes([
        $createTextNode(
          selectedOption.native
            ? selectedOption.native
            : selectedOption.shortcodes
            ? selectedOption.shortcodes
            : selectedOption.emoji
        ),
      ])
    })

    if (isMobileView) {
      setEmojiModal(false)
    }
  }

  async function createPost() {
    const sanitizeConf = {
      allowedTags: ['img'],
    }
    const stringLengthSanitize = sanitizeHTML(postText, sanitizeConf)
    if (
      disableComponent ||
      (imagesBlobs.length === 0 && videoBlob.length === 0 && !postGif && stringLengthSanitize.length === 0)
    ) {
      return
    }

    setDisableComponent(true)
    let imageLinks = imagesBlobs ? await uploadToStorage(imagesBlobs) : null
    let videoLink = videoBlob ? await uploadToStorage(videoBlob) : null
    let sanitizedText = sanitizeText(postText)

    const userHandle = user?.handle

    if (userHandle) {
      const hashTagInput: Hashtag_Insert_Input[] = hashtags.map(item => ({
        name: item,
      }))

      const input: Post_Insert_Input = {
        reply_to_id: feedType === FeedType.Reply ? threadId : null,
        quote_repost_id: quoteRepostId ?? null,
        type: feedType,
        owner_handle: userHandle,
        text: stringLengthSanitize.length === 0 ? '' : sanitizedText,
        image_urls: imageLinks?.map((link, key) => ({
          url: link,
          height: loadedMedia.current[key].height,
          width: loadedMedia.current[key].width,
        })),
        video_url: videoLink![0],
        gif_url: postGif
          ? { url: postGif, height: loadedMedia.current[0].height, width: loadedMedia.current[0].width }
          : null,
        mature_content: contentWarning,
        hashtags: {
          data: hashtags.map(item => ({
            hashtag: item,
          })),
        },
        post_mentions: {
          data: Object.entries(mentions).map(([key, value]) => ({
            mentioned_handle: key,
            id: value,
          })),
        },
      }

      createPostMutation(input, hashTagInput)
        .then(id => {
          if (id) {
            loadedMedia.current = {}
            setPostText('')
            clearEditor()
            setPostGif('')
            setPostVideo('')
            setPostImages([])
            setImagesOriginal([])
            setImagesBlobs([])
            setVideoBlob([])
            setHashtags([])
            setMentions({})
            setContentWarning(false)
            closeFormModal && closeFormModal()
          }
        })
        .finally(() => {
          setDisableComponent(false)
        })
    }
  }

  function onImageLoad(ev: SyntheticEvent<HTMLImageElement, Event>, key: number) {
    const target = ev.target as HTMLImageElement
    loadedMedia.current[key] = { height: target.naturalHeight, width: target.naturalWidth }
  }
  function onVideoLoad(ev: SyntheticEvent<HTMLVideoElement, Event>, key: number) {
    const target = ev.target as HTMLVideoElement
    loadedMedia.current[key] = { height: target.videoHeight, width: target.videoWidth }
  }

  return (
    <>
      <div className="Create-Post-Modal-Mobile-Send" onClick={createPost}>
        <SendIcon />
      </div>
      <input
        id={modal ? 'Post-Upload-Input-Modal' : 'Post-Upload-Input'}
        type="file"
        accept={postImages.length > 0 ? 'image/*' : 'image/* video/*'}
        onChange={e => postUpload(e)}
        hidden
      />
      {user && (
        <>
          {emojiModal && (
            <EmojiPicker closeModal={() => closeModal()} addEmoji={addEmojiLexical} modalPosition={modalPosition} />
          )}
          {cropModal && (
            <CropImageModal
              closeModal={() => closeModal()}
              updateFunction={updateCroppedImage}
              imageKey={cropImageKey}
              image={imagesOriginal[cropImageKey]}
            />
          )}
          {gifModal && (
            <GifModal closeModal={() => closeModal()} modalFunction={addGif} modalPosition={modalPosition} />
          )}
          <div className="Create-Post-Container" id="Create-Post-Container">
            {disableComponent && (
              <div className="Create-Post-Loading-Film">
                <Loading />
              </div>
            )}
            <div className="Create-Post-Container-Inner">
              <div className="Create-Post-Left">
                <ProfileAvatar disableStatus disableMiniProfile user={user} />
              </div>
              <div className="Create-Post-Right">
                <Editor
                  className="Post-Input"
                  id={modal ? 'Post-Input-Modal' : 'Post-Input'}
                  placeHolderText={`Whats going on ${user.handle}?`}
                  editorOnChange={change => setPostText(change)}
                  editorHashtagChange={change => setHashtags(change)}
                  editorMentionChange={change => setMentions(change)}
                />

                {(postVideo || postImages.length > 0 || postGif) && (
                  <div className="Create-Post-Extra-Content">
                    {postVideo && (
                      <div className="Create-Post-Extra-Video-Container">
                        <DeleteIcon className="Create-Post-Upload-Close-Icon" onClick={() => removeVideoUpload()} />
                        <video controls loop playsInline autoPlay muted className="Create-Post-Video" src={postVideo} />
                      </div>
                    )}
                    {postGif && (
                      <div className="Create-Post-Single-Image-Container">
                        <DeleteIcon className="Create-Post-Upload-Close-Icon" onClick={() => setPostGif('')} />
                        {checkMediaType(postGif) === tags.video ? (
                          <video
                            controls={false}
                            loop
                            playsInline
                            autoPlay
                            muted
                            className="Create-Post-Video"
                            onLoadedData={ev => onVideoLoad(ev, 0)}
                            src={postGif}
                          />
                        ) : (
                          <img
                            className="Create-Post-Image"
                            src={postGif ?? ''}
                            onLoad={ev => onImageLoad(ev, 0)}
                            alt=""
                          />
                        )}
                      </div>
                    )}

                    {postImages.length === 1
                      ? postImages.map((image, key) => (
                          <div className="Create-Post-Single-Image-Container" key={key}>
                            <DeleteIcon
                              className="Create-Post-Upload-Close-Icon"
                              onClick={() => removeImageUpload(key)}
                            />
                            <EditIcon className="Create-Post-Upload-Edit-Icon" onClick={() => cropImage(key)} />

                            <img
                              className="Create-Post-Image"
                              src={image ?? ''}
                              onLoad={ev => onImageLoad(ev, key)}
                              alt=""
                            />
                          </div>
                        ))
                      : postImages.length > 1 && (
                          <div className="Create-Post-Images-Grid">
                            {postImages.slice(0, 4).map((image, key) => (
                              <div
                                className={
                                  postImages.length === 2
                                    ? 'Create-Post-Images-Grid-Item'
                                    : postImages.length === 3 && key === 0
                                    ? 'Create-Post-Images-Grid-Item'
                                    : postImages.length === 3 && key > 0
                                    ? 'Create-Post-Images-Grid-Item-Alt'
                                    : 'Create-Post-Images-Grid-Item-Alt'
                                }
                                key={key}>
                                <DeleteIcon
                                  className="Create-Post-Upload-Close-Icon"
                                  onClick={() => removeImageUpload(key)}
                                />
                                <EditIcon className="Create-Post-Upload-Edit-Icon" onClick={() => cropImage(key)} />

                                <img
                                  className="Create-Post-Image-Alt"
                                  src={image ?? ''}
                                  onLoad={ev => onImageLoad(ev, key)}
                                  alt=""
                                />
                              </div>
                            ))}
                          </div>
                        )}
                  </div>
                )}
                {quoteRepostModalItem && quoteRepostModalItem}
                <div className="Create-Post-Options-Container">
                  <div className="Create-Post-Options">
                    <button
                      className={`Create-Post-Option ${
                        (postVideo || postImages.length > 3 || postGif) && 'Create-Post-Option-Unactive'
                      }`}
                      onClick={() => {
                        if (postVideo || postImages.length > 3 || postGif) {
                          return
                        } else document.getElementById(modal ? 'Post-Upload-Input-Modal' : 'Post-Upload-Input')?.click()
                      }}>
                      <ImageOutlinedIcon style={{ fontSize: '2.5rem' }} />
                      <span className="Create-Post-Tooltip">{i18n.t('media')}</span>
                    </button>
                    <button
                      className={`Create-Post-Option ${
                        (postVideo || postImages.length > 0 || postGif) && 'Create-Post-Option-Unactive'
                      }`}
                      onClick={e => {
                        if (postVideo || postImages.length > 0 || postGif) {
                          return
                        } else openGifModal(e)
                      }}>
                      <GifBoxOutlinedIcon style={{ fontSize: '2.5rem' }} />
                      <span className="Create-Post-Tooltip">{i18n.t('gif')}</span>
                    </button>
                    <button
                      className="Create-Post-Option"
                      onClick={e => {
                        openEmojiModal(e)
                      }}>
                      <EmojiEmotionsOutlinedIcon style={{ fontSize: '2.5rem' }} />
                      <span className="Create-Post-Tooltip">{i18n.t('emotes')}</span>
                    </button>
                    <button
                      className={`Create-Post-Option ${
                        contentWarning ? 'Create-Post-Option-Content-Warning-Active' : ''
                      }`}
                      onClick={() => setContentWarning(!contentWarning)}>
                      <FlagOutlinedIcon style={{ fontSize: '2.5rem' }} />
                      <span className="Create-Post-Tooltip">{i18n.t('content-warning')}</span>
                    </button>
                    {/* <button className="Create-Post-Option">
                      <ListAltIcon style={{ fontSize: '2.5rem' }} />
                      <span className="Create-Post-Tooltip">Embed</span>
                    </button> */}
                  </div>
                  {!isMobileView && (
                    <Button
                      appearance="Secondary"
                      className="Create-Post-Button"
                      onClick={createPost}
                      disabled={disableComponent}>
                      {threadId ? i18n.t('reply') : i18n.t('post')}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )

  async function pasteUpload(file: File) {
    if (file != null && postImages.length < 4 && !postVideo) {
      if (file.type.includes('image')) {
        let blob = file
        const compressedFile = await imageCompression(blob, imageCompressionOptions)
        let output = URL.createObjectURL(compressedFile)
        setImagesBlobs(current => [...current, compressedFile])
        setPostImages(current => [...current, output])
        setImagesOriginal(current => [...current, output])
      } else {
        let blob = file
        let output = URL.createObjectURL(file)
        setVideoBlob(current => [...current, blob])
        setPostVideo(output)
      }
    }
    return
  }

  async function postUpload(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files != null && postImages.length < 4 && !postVideo) {
      if (e.target.files[0].type.includes('image')) {
        let blob = e.target.files[0]
        const compressedFile = e.target.files[0].type.includes('gif')
          ? blob
          : await imageCompression(blob, imageCompressionOptions)
        let output = URL.createObjectURL(compressedFile)
        setImagesBlobs(current => [...current, compressedFile])
        setPostImages(current => [...current, output])
        setImagesOriginal(current => [...current, output])

        e.target.value = ''
      } else if (e.target.files[0].type.includes('video')) {
        let blob = e.target.files[0]
        let output = URL.createObjectURL(e.target.files[0])
        setVideoBlob(current => [...current, blob])
        setPostVideo(output)
        e.target.value = ''
      } else {
        toast('This is an unsupported file type.')
        e.target.value = ''
      }
    }
    return
  }

  function removeImageUpload(keyToRemove: number) {
    loadedMedia.current = {}
    setPostImages(
      postImages.filter(function (item, key) {
        return key !== keyToRemove
      })
    )
    setImagesOriginal(
      imagesOriginal.filter(function (item, key) {
        return key !== keyToRemove
      })
    )
    setImagesBlobs(
      imagesBlobs.filter(function (item, key) {
        return key !== keyToRemove
      })
    )
    return
  }

  function removeVideoUpload() {
    setPostVideo(undefined)
    setVideoBlob([])
  }

  async function cropImage(key: number) {
    setCropImageKey(key)
    setCropModal(true)
  }

  function updateCroppedImage(image: string, key: number, blob: File) {
    let targetItem = (item: string, itemKey: number) => {
      if (itemKey === key) {
        item = image
      }
      return item
    }

    let targetBlob = (item: File, itemKey: number) => {
      if (itemKey === key) {
        item = blob
      }
      return item
    }

    setImagesBlobs(imagesBlobs.map(targetBlob))
    setPostImages(postImages.map(targetItem))
    setCropModal(false)
  }

  function addGif(url: string) {
    setPostGif(url)
  }

  function openGifModal(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    let currentTargetRect: DOMRect = e.currentTarget.getBoundingClientRect()
    setModalPosition({
      height: modal ? currentTargetRect.y - 50 : currentTargetRect.y,
      width: currentTargetRect.x,
    })
    setGifModal(!gifModal)
  }
  function openEmojiModal(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    let currentTargetRect: DOMRect = e.currentTarget.getBoundingClientRect()
    setModalPosition({
      height: modal ? currentTargetRect.y - 50 : currentTargetRect.y,
      width: currentTargetRect.x,
    })
    setEmojiModal(!emojiModal)
  }
  function closeModal() {
    setCropModal(false)
    setGifModal(false)
    setEmojiModal(false)
  }
}
