import { User } from '../../../gql/generated/graphql'
import { ListProfileItem } from '../../profile/display/listProfileItem'

interface PostRelevantPeopleProps {
  users: User[]
}

export function PostRelevantPeopleLego({ users }: PostRelevantPeopleProps) {
  return (
    <div className="Sidebar-Container-Item">
      <h2 className="Sidebar-Container-Item-Header">Relevant Users</h2>
      {users.length > 0 && (
        <div>
          {users.map(
            (item: User, key: number) =>
              item !== null && (
                <div key={key}>
                  <ListProfileItem
                    avatarSize="XSmall"
                    user={item}
                    index={key}
                    disableSocialLinks
                    disableFollowButton
                    disableMessageButton
                  />
                </div>
              )
          )}
        </div>
      )}
    </div>
  )
}
