import './loading.css'
import { loadingQuotes } from './loadingQuotes'

interface LoadingModalProps {
  header?: string
  subheader?: string
  disableBackground?: boolean
  zIndex?: number
}

export function LoadingModal({ header, subheader, disableBackground, zIndex }: LoadingModalProps) {
  const quoteIndex = Math.floor(Math.random() * loadingQuotes.length)

  return (
    <>
      {disableBackground ? (
        <div className="Loading-Modal-Container" style={zIndex ? { zIndex: zIndex } : {}}>
          <div className="Loading-Cubes">
            <div className="Loading-Cube Loading-Cube-1"></div>
            <div className="Loading-Cube Loading-Cube-2"></div>
          </div>
          <h2>{header ?? 'Loading..'}</h2>
          <h3>{subheader ?? loadingQuotes[quoteIndex]}</h3>
        </div>
      ) : (
        <div className="Loading-Modal-Container-BG" style={zIndex ? { zIndex: zIndex } : {}}>
          <div className="Loading-Modal-Container">
            <div className="Loading-Cubes">
              <div className="Loading-Cube Loading-Cube-1"></div>
              <div className="Loading-Cube Loading-Cube-2"></div>
            </div>
            <h2>{header ?? 'Loading..'}</h2>
            <h3>{subheader ?? loadingQuotes[quoteIndex]}</h3>
          </div>
        </div>
      )}
    </>
  )
}
