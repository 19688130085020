import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AvatarEditor } from '../components/profile/editor/avatarEditor'
import { BannerEditor } from '../components/profile/editor/bannerEditor'
import { GeneralEditor } from '../components/profile/editor/generalEditor'
import { MiniProfileEditor } from '../components/profile/editor/miniProfileEditor'
import { ProfileShowcaseEditor } from '../components/profile/editor/profileShowcaseEditor'
import { SaveIndicator } from '../components/profile/editor/saveIndicator'
import { SocialsEditor } from '../components/profile/editor/socialsEditor'
import { ProfileBackgroundEditor } from '../components/profile/editor/profileBackgroundEditor'
import { EmojiEditor } from '../components/profile/editor/emojiEditor'
import { PrivacyEditor } from '../components/profile/editor/privacyEditor'
import { ThemeEditor } from '../components/profile/editor/themeEditor'
import { useCardinalContext } from '../context/cardinal'
import { uploadToStorage } from '../utils/cloudflareR2'
import { LoadingModal, Loading } from '../components/uiKit/loading/loadingExports'
import {
  ActionType as EditorActionType,
  EditorDispatchContext,
  EditorState,
  EditorStateContext,
  ShowcaseContext,
} from '../context/editorContext'
import { User, User_Set_Input, useUpdateUserMutation } from '../gql/generated/graphql'
import {
  GalleryShowcase,
  ImageShowcase,
  PanelShowcase,
  ProfileMedia,
  ProfileTab,
  Showcase,
  ShowcaseType,
} from '../gql/scalars'
// import usePrompt from '../utils/blockPath'
import MenuIcon from '@mui/icons-material/Menu'
import './styles/profile.css'
import './styles/profileSettings.css'
import { ClickAwayListener } from '../components/modal/clickAwayListener'
import { NavLink } from '../navigation/navLink'
import _ from 'lodash'

export function ProfileSettings() {
  const { settings_type } = useParams()
  const editorDispatch = useContext(EditorDispatchContext)
  const editorState = useContext(EditorStateContext)
  const { user } = useCardinalContext()
  const [originState, setOriginState] = useState<EditorState | undefined>(undefined)
  const [changes, setChanges] = useState<boolean>(false)
  const [loading, setLoading] = useState(true)
  const [savingProfile, setSavingProfile] = useState(false)
  const [updateUserMutation] = useUpdateUserMutation()
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false)

  useEffect(() => {
    if (user) {
      let update: EditorState = {
        displayname: user.username,
        handle: user.handle,
        profileDesc: user.bio ?? '',
        avatar: user.profile_image ?? '',
        avatarFrame: user.profile_image_frame ?? '',
        banner: user.banner ?? '',
        profileBackground: user.profile_background ?? '',
        appBackground: user.app_background ?? '',
        miniProfileBackground: user.mini_profile_background ?? '',
        profileTabs: user.profile_tabs,
        socialLinks: user.social_links,
        profileAvatarBlob: undefined,
        bannerBlob: undefined,
        miniProfileBackgroundBlob: undefined,
        appBackgroundBlob: undefined,
        profileBackgroundBlob: undefined,
      }
      setOriginState(update)
      editorDispatch({
        type: EditorActionType.updateState,
        payload: update,
      })
    }
    // eslint-disable-next-line
  }, [user])

  useEffect(() => {
    if (!_.isEqual(originState, editorState)) {
      setChanges(true)
    } else {
      setChanges(false)
    }

    if (originState && editorState && user) {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [editorState, originState, user])

  async function UpdateDB(
    updatedTabs: (ProfileTab | null)[] | null | undefined,
    profileAvatar: string,
    banner: string,
    miniProfileBackground: string,
    profileBackground: string,
    appBackground: string
  ) {
    if (!editorState.handle) {
      throw new Error('Notice - Handle field cannot be empty!')
    }

    const pastAvatars = user?.profile_media?.past_avatars ?? []
    const pastBanners = user?.profile_media?.past_banners ?? []
    const pastMiniBackgrounds = user?.profile_media?.past_mini_backgrounds ?? []
    const pastProfileBackgrounds = user?.profile_media?.past_profile_backgrounds ?? []
    const pastAppBackgrounds = user?.profile_media?.past_app_backgrounds ?? []

    function addToPast(array: string[], item: string): string[] {
      return array.includes(item) ? array : [...array, item]
    }

    const profileMedia: ProfileMedia = {
      past_avatars: addToPast(pastAvatars, profileAvatar),
      past_banners: addToPast(pastBanners, banner),
      past_mini_backgrounds: addToPast(pastMiniBackgrounds, miniProfileBackground),
      past_profile_backgrounds: addToPast(pastProfileBackgrounds, profileBackground),
      past_app_backgrounds: addToPast(pastAppBackgrounds, appBackground),
    }

    function getUpdatedValue<T>(newValue: T, oldValue: T | undefined): T | undefined {
      return newValue !== oldValue ? newValue : undefined
    }

    const input: User_Set_Input = {
      handle: getUpdatedValue(editorState.handle, user?.handle),
      username: getUpdatedValue(editorState.displayname, user?.username),
      bio: getUpdatedValue(editorState.profileDesc, user?.bio),
      profile_image: getUpdatedValue(profileAvatar, user?.profile_image),
      profile_image_frame: getUpdatedValue(editorState.avatarFrame, user?.profile_image_frame),
      mini_profile_background: getUpdatedValue(miniProfileBackground, user?.mini_profile_background),
      display_mini_profile_social_links: true,
      banner: getUpdatedValue(banner, user?.banner),
      profile_media: getUpdatedValue(profileMedia, user?.profile_media),
      profile_tabs: getUpdatedValue(updatedTabs, user?.profile_tabs),
      social_links: getUpdatedValue(editorState.socialLinks, user?.social_links),
      app_background: getUpdatedValue(appBackground, user?.account_type),
      profile_background: getUpdatedValue(profileBackground, user?.profile_background),
    }

    if (user?.handle) {
      return updateUserMutation({
        variables: { handle: user?.handle, object: input },
        update: (cache, { data }) => {
          cache.modify({
            id: cache.identify(user!),
            fields: {
              handle: () => data?.update_user_by_pk?.handle,
            },
          })
        },
      }).then(response => {
        if (response?.data?.update_user_by_pk) {
          setOriginState(editorState)
          editorDispatch({
            type: EditorActionType.updateState,
            payload: editorState,
          })
        } else if (response.errors?.length && response.errors.length > 0) {
          throw new Error(response.errors[0].message)
        }
      })
    }
  }

  async function Save(): Promise<void> {
    try {
      setSavingProfile(true)

      const {
        profileTabs: tabs,
        avatar: profileAvatar,
        banner,
        miniProfileBackground,
        profileBackground,
        appBackground,
        profileAvatarBlob,
        bannerBlob,
        miniProfileBackgroundBlob,
        profileBackgroundBlob,
        appBackgroundBlob,
      } = editorState

      const uploadAndUpdateLink = async (
        blob: File | undefined,
        currentLink: string | undefined
      ): Promise<string | undefined> => {
        if (blob) {
          const updatedLinks = await uploadToStorage([blob])
          if (updatedLinks && updatedLinks.length > 0) {
            return updatedLinks[0]
          }
        }
        return currentLink
      }

      const updateShowcaseImages = async (showcase: (Showcase & ShowcaseContext) | null): Promise<void> => {
        if (showcase && showcase.blobs) {
          const updatedLinks = await uploadToStorage(showcase.blobs.map(blob => blob.blob))
          if (updatedLinks) {
            for (let i = 0; i < updatedLinks.length; i++) {
              const updatedLink = updatedLinks[i]
              const blob = showcase.blobs[i]
              if (
                showcase.type === ShowcaseType.Images &&
                blob &&
                blob.imagePosition !== undefined &&
                blob.itemKey !== undefined
              ) {
                ;(showcase as ImageShowcase).items![blob.itemKey]!.images![blob.imagePosition] = updatedLink
              } else if (showcase.type === ShowcaseType.Gallery && blob && blob.imagePosition !== undefined) {
                ;(showcase as GalleryShowcase).images![blob.imagePosition] = updatedLink
              } else if (showcase.type === ShowcaseType.Panel && blob && blob.imagePosition !== undefined) {
                ;(showcase as PanelShowcase).items![blob.imagePosition]!.image = updatedLink
              }
            }
            delete showcase.blobs
          }
        }
      }

      if (tabs) {
        for (const tab of tabs) {
          if (tab?.showcases) {
            for (const showcase of tab.showcases) {
              await updateShowcaseImages(showcase)
            }
          }
        }
      }

      const updatedAvatarLink = await uploadAndUpdateLink(profileAvatarBlob, profileAvatar)
      const updatedBannerLink = await uploadAndUpdateLink(bannerBlob, banner)
      const updatedMiniProfileBackgroundLink = await uploadAndUpdateLink(
        miniProfileBackgroundBlob,
        miniProfileBackground
      )
      const updatedProfileBackgroundLink = await uploadAndUpdateLink(profileBackgroundBlob, profileBackground)
      const updatedAppBackgroundLink = await uploadAndUpdateLink(appBackgroundBlob, appBackground)

      await UpdateDB(
        tabs,
        updatedAvatarLink ?? profileAvatar,
        updatedBannerLink ?? banner,
        updatedMiniProfileBackgroundLink ?? miniProfileBackground,
        updatedProfileBackgroundLink ?? profileBackground,
        updatedAppBackgroundLink ?? appBackground
      )
    } finally {
      setSavingProfile(false)
    }
  }

  function HardReset() {
    if (originState)
      editorDispatch({
        type: EditorActionType.updateState,
        payload: originState,
      })
  }

  return (
    <>
      {savingProfile && (
        <LoadingModal header="Updating.." subheader="Updating your profile. Please wait." zIndex={10} />
      )}
      <div className="Profile-Settings-Container">
        <div onClick={() => setMobileMenuOpen(true)} className="Profile-Settings-Mobile-Menu-Button">
          <MenuIcon />
        </div>
        <div className="Profile-Settings-Flex">
          <ClickAwayListener onClickAway={() => setMobileMenuOpen(false)} id="Profile-Settings-Left-Mobile-Menu">
            <div className={`Profile-Settings-Left ${mobileMenuOpen ? 'Profile-Settings-Left-Mobile-Menu-Open' : ''}`}>
              <div className="Profile-Settings-Spacer">Profile</div>
              <NavLink
                onClick={() => setMobileMenuOpen(false)}
                to="/settings/general"
                className={settings_type === 'general' ? 'Setting-Link-Active' : ''}>
                General
              </NavLink>
              <NavLink
                onClick={() => setMobileMenuOpen(false)}
                to="/settings/avatar"
                className={settings_type === 'avatar' ? 'Setting-Link-Active' : ''}>
                Avatar
              </NavLink>
              <NavLink
                onClick={() => setMobileMenuOpen(false)}
                to="/settings/profile-background"
                className={settings_type === 'profile-background' ? 'Setting-Link-Active' : ''}>
                Profile Background
              </NavLink>
              <NavLink
                onClick={() => setMobileMenuOpen(false)}
                to="/settings/banner"
                className={settings_type === 'banner' ? 'Setting-Link-Active' : ''}>
                Banner
              </NavLink>
              <NavLink
                onClick={() => setMobileMenuOpen(false)}
                to="/settings/socials"
                className={settings_type === 'socials' ? 'Setting-Link-Active' : ''}>
                Socials
              </NavLink>
              <NavLink
                onClick={() => setMobileMenuOpen(false)}
                to="/settings/mini-profile"
                className={settings_type === 'mini-profile' ? 'Setting-Link-Active' : ''}>
                Mini Profile
              </NavLink>
              <NavLink
                onClick={() => setMobileMenuOpen(false)}
                to="/settings/emoji"
                className={settings_type === 'emoji' ? 'Setting-Link-Active' : ''}>
                Emoji
              </NavLink>
              <NavLink
                onClick={() => setMobileMenuOpen(false)}
                to="/settings/showcases"
                className={settings_type === 'showcases' ? 'Setting-Link-Active' : ''}>
                Showcases
              </NavLink>
              <div className="Profile-Settings-Spacer">App & Privacy</div>
              <NavLink
                onClick={() => setMobileMenuOpen(false)}
                to="/settings/app-theme"
                className={settings_type === 'app-theme' ? 'Setting-Link-Active' : ''}>
                Theme
              </NavLink>
              <NavLink
                onClick={() => setMobileMenuOpen(false)}
                to="/settings/filters"
                className={settings_type === 'filters' ? 'Setting-Link-Active' : ''}>
                Filters
              </NavLink>
              <NavLink
                onClick={() => setMobileMenuOpen(false)}
                to="/settings/privacy"
                className={settings_type === 'privacy' ? 'Setting-Link-Active' : ''}>
                Privacy
              </NavLink>
            </div>
          </ClickAwayListener>
          <div className="Profile-Settings-Right">
            {loading ? (
              <>
                <Loading />
              </>
            ) : (
              <>
                <SaveIndicator show={changes} OnResetClick={HardReset} OnSaveClick={Save} />
                {settings_type === 'general' && <GeneralEditor />}
                {settings_type === 'showcases' && <ProfileShowcaseEditor />}
                {settings_type === 'avatar' && <AvatarEditor />}
                {settings_type === 'banner' && <BannerEditor />}
                {settings_type === 'mini-profile' && <MiniProfileEditor />}
                {settings_type === 'socials' && <SocialsEditor />}
                {settings_type === 'profile-background' && <ProfileBackgroundEditor />}
                {settings_type === 'emoji' && <EmojiEditor />}
                {settings_type === 'app-theme' && <ThemeEditor />}
                {settings_type === 'privacy' && <PrivacyEditor />}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
