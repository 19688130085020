import { LexicalComposer } from '@lexical/react/LexicalComposer'
import AddIcon from '@mui/icons-material/Add'
import moment from 'moment'
import { Suspense, useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import sanitizeHTML from 'sanitize-html'
import { MessageInput } from '../components/messages/messageInput'
import { ProfileAvatar } from '../components/profile/display/profileAvatar'
import { useCardinalContext } from '../context/cardinal'
import {
  MessagesByGroupDocument,
  useGetMessageGroupListLazyQuery,
  useGetMessagesLazyQuery,
  useUpdateReadStatusMutation,
  useMessageGroupListSubscription,
  User,
} from '../gql/generated/graphql'
import { NewMessageModal } from '../components/messages/newMessageModal'
import { useSettings } from '../utils/lexical/context/SettingsContext'
import SeisoNodes from '../utils/lexical/nodes/SeisoNodes'
import SeisoEditorTheme from '../utils/lexical/themes/SeisoEditorTheme'
import './styles/messages.css'
import { sanitizeHtmlConf } from '../utils/constants'
import PeopleIcon from '@mui/icons-material/People'
import { ClickAwayListener } from '../components/modal/clickAwayListener'
import Twemoji from 'react-twemoji'
import AutoScrollingDiv from '../components/uiKit/autoScrollingDiv'
import { useScrollRestoration } from '../context/scrollRestorationContext'
import { NavLink } from '../navigation/navLink'

export function Messages() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [chatUserDictionary, setChatUserDictionary] = useState<{ [key: string]: any }>({})
  const { navigateToPage } = useScrollRestoration()
  const { chat_id } = useParams()
  const [newMessageModalOpen, setNewMessageModalOpen] = useState<boolean>(false)
  const { user, lastDirectChat, setLastDirectChat } = useCardinalContext()
  const [getMessageGroupList, { data: messageGroupListData, loading: messageListLoading, error: messageListErr }] =
    useGetMessageGroupListLazyQuery()
  const [updateReadStatus] = useUpdateReadStatusMutation()
  const [getMessages, { data: messageData, loading: messageLoading, error: messageError, subscribeToMore }] =
    useGetMessagesLazyQuery()
  const { data: messageListData, loading } = useMessageGroupListSubscription({
    variables: { currentUserHandle: user?.handle ?? '' },
  })

  useEffect(() => {
    setLastDirectChat(chat_id ?? '')
  }, [chat_id])

  useEffect(() => {
    if (lastDirectChat.length > 0 && !chat_id) navigateToPage(`/messages/${lastDirectChat}`, { replace: true })
  }, [])

  useEffect(() => {
    if (user) {
      getMessageGroupList({
        variables: {
          currentUserHandle: user.handle,
        },
      })
    }
  }, [user])

  useEffect(() => {
    // Convert the array into a dictionary
    const convertArrayToDictionary = (array: any) => {
      const result: { [key: string]: typeof user } = {}
      for (const item of array) {
        for (const member of item.message_group.message_group_members) {
          result[member.member_handle] = member.user
        }
      }
      return result
    }

    // Call the conversion function and update the state
    const updateDictionary = () => {
      const newDictionary = convertArrayToDictionary(messageListData?.message_group_member)
      setChatUserDictionary(newDictionary)
    }

    // if (!messageData) {
    //   navigate('/messages')
    // }

    if (messageListData && messageListData.message_group_member && messageListData.message_group_member.length > 0) {
      updateDictionary()
    }
  }, [messageListData])

  useEffect(() => {
    if (messageListData && user && chat_id) {
      let currentGroup = messageListData.message_group_member.find(item => item.message_group_id === chat_id)
      if (currentGroup?.member_read === false && currentGroup.message_group_id === chat_id) {
        try {
          updateReadStatus({
            variables: {
              currentUserHandle: user.handle,
              groupId: chat_id,
            },
          })
        } catch (err) {
          console.log(err)
        }
      }
    }
  }, [chat_id, messageListData])

  useEffect(() => {
    if (chat_id) {
      let unsubscribe: () => void
      getMessages({
        variables: {
          groupId: chat_id,
        },
      }).then(() => {
        unsubscribe = subscribeToMore({
          document: MessagesByGroupDocument,
          variables: { groupId: chat_id },
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) return prev
            const newMessage = subscriptionData.data.messages[0]
            if (newMessage && prev.messages[0] && newMessage.id === prev.messages[0].id) return prev

            return Object.assign({}, prev, {
              messages: [newMessage, ...prev.messages],
            })
          },
        })
      })

      return () => {
        try {
          unsubscribe()
        } catch (err) {
          console.log(err)
        }
      }
    }
  }, [chat_id, getMessages, user])

  moment.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s',
      s: '%ds',
      m: '%dm',
      mm: '%dm',
      h: '%dh',
      hh: '%dh',
      d: '%dd',
      dd: '%dd',
    },
  })

  const {
    settings: { emptyEditor },
  } = useSettings()

  const initialConfig = {
    editorState: undefined,
    namespace: 'Seiso-Message-Chatbox',
    nodes: [...SeisoNodes],
    onError: (error: Error) => {
      throw error
    },
    theme: SeisoEditorTheme,
    editable: true,
  }

  const groupItems = useMemo(() => {
    return messageListData?.message_group_member.map((item, key) => {
      let members = item.message_group.message_group_members.map(member => chatUserDictionary[member?.user.handle])
      let membersWithoutCurrentUser = members.filter(member => member?.handle !== user?.handle)
      let read = item.member_read
      let id = item.message_group.id

      return (
        <NavLink
          to={`/messages/${id}`}
          onClick={() => setMobileMenuOpen(false)}
          className={`Message-List-Item ${id === chat_id && 'Message-List-Item-Active'}`}
          key={`${item.message_group_id}-${key}`}>
          <div className="Message-List-Item-PFP">
            <ProfileAvatar
              disableLink
              user={
                membersWithoutCurrentUser.length === 1
                  ? (membersWithoutCurrentUser[0] as User)
                  : (membersWithoutCurrentUser[0] as User)
              }
              size="Small"
              profileFrame
            />
          </div>
          <div className="Message-List-Item-Info">
            <div
              className={`Message-List-Item-Displayname ${
                (id === chat_id || !read) && 'Message-List-Item-Displayname-Active'
              }`}>
              <Twemoji options={{ className: 'Post-Displayname-Emoji' }}>
                {membersWithoutCurrentUser
                  ?.sort()
                  .map(
                    (member, index) => member?.username + (index === membersWithoutCurrentUser.length - 1 ? '' : ', ')
                  )}
              </Twemoji>
              {(membersWithoutCurrentUser.length > 1 || membersWithoutCurrentUser[0]?.status_message?.length) > 0 && (
                <div
                  className={`Message-List-Item-Subtext  ${
                    (id === chat_id || !read) && 'Message-List-Item-Subtext-Active'
                  }`}
                  dangerouslySetInnerHTML={{
                    __html:
                      membersWithoutCurrentUser.length > 1
                        ? `${membersWithoutCurrentUser.length + 1} Members`
                        : `${sanitizeHTML(membersWithoutCurrentUser[0]?.status_message, sanitizeHtmlConf)}`,
                  }}
                />
              )}
            </div>
          </div>
        </NavLink>
      )
    })
  }, [messageListData, chat_id, chatUserDictionary])

  const messageItems = useMemo(() => {
    return messageData?.messages.map((item, key) => {
      let user = chatUserDictionary[item.handle_from]
      let lastMessage = messageData.messages[key + 1]
      let timeDiff = moment(item?.created_at).diff(moment(lastMessage?.created_at), 'minutes')

      if (user && user.handle === lastMessage?.handle_from && timeDiff < 10) {
        return (
          <div
            className="Message-Chatbox-List-Item-Lower Message-Chatbox-List-Item-Lower-Alt"
            key={`${item.id}-${key}`}>
            <div dangerouslySetInnerHTML={{ __html: item.text ? sanitizeHTML(item.text, sanitizeHtmlConf) : '' }} />
            {((item.message_images && item.message_images.length > 0) || item.message_video || item.message_gif) && (
              <div className="Post-Non-Text">
                {item.message_images && item.message_images?.length > 0 && (
                  <>
                    {item.message_images.length === 1 ? (
                      item.message_images?.map((image: any, key: number) => (
                        <img key={key} className="Message-Image" src={image ?? ''} alt="" />
                      ))
                    ) : (
                      <div className="Post-Images-Grid">
                        {item.message_images?.slice(0, 4).map((image: any, key: number) => (
                          <div
                            className={
                              item.message_images?.length === 2
                                ? 'Post-Images-Grid-Item'
                                : item.message_images?.length === 3 && key === 0
                                ? 'Post-Images-Grid-Item'
                                : item.message_images?.length === 3 && key > 0
                                ? 'Post-Images-Grid-Item-Alt'
                                : 'Post-Images-Grid-Item-Alt'
                            }
                            key={key}>
                            <img className="Post-Image-Alt" src={image ?? ''} alt="" />
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                )}

                {item.message_video && (
                  <div className="Post-Video-Container">
                    <video
                      className="Post-Video"
                      controls
                      autoPlay
                      playsInline
                      muted
                      loop
                      src={item.message_video}
                      onClick={e => e.stopPropagation()}
                    />
                  </div>
                )}
                {item.message_gif && <img className="Post-Image" src={item.message_gif} draggable />}
              </div>
            )}
          </div>
        )
      } else if (user) {
        return (
          <Suspense key={key} fallback={<div className="Loading"></div>}>
            <div className="Message-Chatbox-List-Item">
              <div className="Message-Chatbox-List-Item-Upper">
                <ProfileAvatar user={user as User} disableStatus size="XSmall" />
                <div className="Message-Chatbox-List-Item-Username">
                  <Twemoji options={{ className: 'Post-Displayname-Emoji' }}>{user?.username}</Twemoji>
                  <span className="Message-Chatbox-List-Item-Date">
                    {moment().diff(moment(item.updated_at), 'hours') >= 24
                      ? moment(item.updated_at).format('lll')
                      : moment(item.updated_at).calendar()}
                  </span>
                </div>
              </div>

              <div className="Message-Chatbox-List-Item-Lower">
                <div dangerouslySetInnerHTML={{ __html: item.text ? sanitizeHTML(item.text, sanitizeHtmlConf) : '' }} />
                {((item.message_images && item.message_images.length > 0) ||
                  item.message_video ||
                  item.message_gif) && (
                  <div className="Post-Non-Text">
                    {item.message_images && item.message_images?.length > 0 && (
                      <>
                        {item.message_images.length === 1 ? (
                          item.message_images?.map((image: any, key: number) => (
                            <img key={key} className="Message-Image" src={image ?? ''} alt="" />
                          ))
                        ) : (
                          <div className="Post-Images-Grid">
                            {item.message_images?.slice(0, 4).map((image: any, key: number) => (
                              <div
                                className={
                                  item.message_images?.length === 2
                                    ? 'Post-Images-Grid-Item'
                                    : item.message_images?.length === 3 && key === 0
                                    ? 'Post-Images-Grid-Item'
                                    : item.message_images?.length === 3 && key > 0
                                    ? 'Post-Images-Grid-Item-Alt'
                                    : 'Post-Images-Grid-Item-Alt'
                                }
                                key={key}>
                                <img className="Post-Image-Alt" src={image ?? ''} alt="" />
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    )}

                    {item.message_video && (
                      <div className="Post-Video-Container">
                        <video
                          className="Post-Video"
                          controls
                          autoPlay
                          playsInline
                          muted
                          loop
                          src={item.message_video}
                          onClick={e => e.stopPropagation()}
                        />
                      </div>
                    )}
                    {item.message_gif && <img className="Post-Image" src={item.message_gif} draggable />}
                  </div>
                )}
              </div>
            </div>
          </Suspense>
        )
      }
    })
  }, [messageData, chatUserDictionary])

  return (
    <div className="Messages-Container">
      <div onClick={() => setMobileMenuOpen(true)} className="Messages-Mobile-Menu-Button">
        <PeopleIcon />
      </div>
      {newMessageModalOpen && <NewMessageModal closeModal={() => setNewMessageModalOpen(false)} />}
      <ClickAwayListener onClickAway={() => setMobileMenuOpen(false)} id="Message-List-Mobile-Menu">
        <div className={`Message-List Active-Scrollbar ${mobileMenuOpen ? 'Message-List-Mobile-Menu-Open' : ''}`}>
          <div className="Message-List-Header">
            Direct Messages <AddIcon onClick={() => setNewMessageModalOpen(true)} />
          </div>
          {groupItems}
        </div>
      </ClickAwayListener>
      <div className={`Message-Chatbox ${mobileMenuOpen ? 'Message-Chatbox-Mobile-Menu-Open' : ''}`}>
        {!chat_id && (
          <div className="Select-Chat-Notification">
            Select a chat!{' '}
            <div className="New-Message-Button" onClick={() => setNewMessageModalOpen(true)}>
              New Chat
            </div>
          </div>
        )}
        {chat_id && user && (
          <>
            <AutoScrollingDiv>{messageData !== null && messageItems}</AutoScrollingDiv>
            {/* <div className="Message-Chatbox-List Active-Scrollbar">
              {data?.message_group[0] !== null && messageItems}
            </div> */}
            <LexicalComposer initialConfig={initialConfig}>
              <MessageInput chat_id={chat_id} />
            </LexicalComposer>
          </>
        )}
      </div>
    </div>
  )
}
