import { useCardinalContext } from '../../context/cardinal'

type ThemeManagerProps = {
  children: JSX.Element
}

export function ThemeManager({ children }: ThemeManagerProps) {
  const { user } = useCardinalContext()
  return (
    <div
      className={`Theme-Default Theme-Global-Vars ${
        user?.app_theme_accent ? 'Theme-Accent-' + user?.app_theme_accent : ''
      }`}>
      {children}
    </div>
  )
}
