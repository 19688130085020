import { useContext } from 'react'
import { EditorDispatchContext, EditorStateContext } from '../../../context/editorContext'

import './styles/privacyEditor.css'
import { SliderToggle } from '../../uiKit/sliderToggle'

export function PrivacyEditor() {
  return (
    <div className="Privacy-Editor-Container">
      <h1 className="Tab-Header-Mobile">PRIVACY</h1>
      <h1>PRIVACY</h1>
      <div className="Toggle-Setting">
        <h2>Private Profile</h2>
        <SliderToggle status={false} />
      </div>
    </div>
  )
}
