import './styles/sidebar.css'
import { TrendingLego } from './legos/trendingLego'
import { WelcomeLego } from './legos/welcomeLego'
import { LiveProfilesLego } from './legos/liveProfilesLego'
import { PostRelevantPeopleLego } from './legos/postRelevantPeopleLego'
import { HashtagLego } from './legos/hashtagLego'
import { User, Hashtag } from '../../gql/generated/graphql'

export enum SidebarType {
  Hashtag,
  Post,
  Home,
}
interface SidebarProps {
  sidebarType: SidebarType
  postMentions?: User[]
  hashtag?: Hashtag
}

export function Sidebar({ sidebarType, postMentions, hashtag }: SidebarProps) {
  return (
    <div className="Sidebar-Container">
      <div className="Sidebar-Container-Inner">
        {sidebarType === SidebarType.Home && <WelcomeLego />}
        {postMentions && sidebarType === SidebarType.Post && <PostRelevantPeopleLego users={postMentions} />}
        {hashtag && sidebarType === SidebarType.Hashtag && <HashtagLego hashtag={hashtag} />}
        {sidebarType !== SidebarType.Hashtag && <LiveProfilesLego />}
        <TrendingLego />
      </div>
    </div>
  )
}
