import { CategoryConfig } from '../../config/categoryConfig'
import { useEmojiStyleConfig, useGetEmojiUrlConfig, useSuggestedEmojisModeConfig } from '../../config/useConfig'
import { emojiByUnified } from '../../dataUtils/emojiSelectors'
import { getsuggested } from '../../dataUtils/suggested'
import { useUpdateSuggested } from '../context/PickerContext'
import { ClickableEmoji, CustomClickableEmoji } from '../emoji/Emoji'
import { EmojiCategory } from './EmojiCategory'
import { useCardinalContext } from '../../../../context/cardinal'
import { useMemo } from 'react'

type Props = Readonly<{
  categoryConfig: CategoryConfig
}>

export function Suggested({ categoryConfig }: Props) {
  const { customEmoteCategories } = useCardinalContext()
  const [suggestedUpdated] = useUpdateSuggested()
  const suggestedEmojisModeConfig = useSuggestedEmojisModeConfig()
  const getEmojiUrl = useGetEmojiUrlConfig()
  const suggested = useMemo(
    () => getsuggested(suggestedEmojisModeConfig) ?? [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [suggestedUpdated, suggestedEmojisModeConfig]
  )
  const emojiStyle = useEmojiStyleConfig()

  return (
    <EmojiCategory categoryConfig={categoryConfig} hiddenOnSearch hidden={suggested.length === 0}>
      {suggested.map(suggestedItem => {
        const customCategory = customEmoteCategories.find(item =>
          item.images.find(item => item.name === suggestedItem.original)
        )
        const customEmoji = customCategory?.images.find(item => item?.name === suggestedItem.original)
        const emoji = emojiByUnified(suggestedItem.original)

        if (!emoji && !customEmoji) {
          return null
        }

        if (emoji) {
          return (
            <ClickableEmoji
              showVariations={false}
              unified={suggestedItem.unified}
              emojiStyle={emojiStyle}
              emoji={emoji}
              key={suggestedItem.unified}
              getEmojiUrl={getEmojiUrl}
            />
          )
        } else if (customEmoji) {
          return <CustomClickableEmoji emoji={customEmoji} key={suggestedItem.unified} />
        }
      })}
    </EmojiCategory>
  )
}
