export interface Showcase {
  title: string
  description: string
  type: ShowcaseType
}

export interface GalleryShowcase extends Showcase {
  images: string[]
}

export interface InfoShowcase extends Showcase {
  text: string
}

export interface ImageShowcase extends Showcase {
  items: {
    images: (string | null)[]
    link_text: string
    link: string
    description: string
  }[]
}

export interface VideoShowcase extends Showcase {
  items: {
    title: string
    description: string
    type: string
    video_id: string
    thumbnail: string
    embed_link: string
    video?: string
    link?: string
  }[]
}

export interface PanelShowcase extends Showcase {
  items: {
    image: string
    image_link: string
    text: string
  }[]
}

export interface SocialsShowcase extends Showcase {
  items: {
    url: string
    customName: string
    icon: string
    provider: SocialProvider
  }[]
}

export type ProfileTab = {
  name: string
  showcases: Showcase[]
}

export type SocialLink = {
  url: string
  icon: string
  provider: SocialProvider
}

export type ProfileMedia = {
  past_avatars: string[]
  past_banners: string[]
  past_mini_backgrounds: string[]
  past_profile_backgrounds: string[]
  past_app_backgrounds: string[]
}

export type MediaUrl = {
  url: string
  height: number
  width: number
}

export enum ShowcaseType {
  Gallery = 'Gallery',
  Info = 'Info',
  Images = 'Images',
  Video = 'Video',
  Panel = 'Panel',
  Socials = 'Socials',
}

export enum AccountState {
  Active = 'Active',
  Banned = 'Banned',
  Blacklist = 'Blacklist',
  Inactive = 'Inactive',
  New = 'New',
  Shadowbanned = 'Shadowbanned',
}

export enum AccountType {
  Admin = 'Admin',
  Basic = 'Basic',
  Moderator = 'Moderator',
}

export enum StreamProvider {
  Twitch = 'Twitch',
  Youtube = 'Youtube',
  None = 'None',
}

export enum SocialProvider {
  Twitch = 'Twitch',
  Youtube = 'Youtube',
  Twitter = 'Twitter',
  Discord = 'Discord',
  TikTok = 'TikTok',
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  KoFi = 'KoFi',
  Patreon = 'Patreon',
  Spotify = 'Spotify',
  StreamElements = 'StreamElements',
  Other = 'Other',
}
