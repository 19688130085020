import { useContext } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import './gifModal.css'
import GifPicker, { Theme } from '../../../utils/gifPicker/index'
import { useCardinalContext } from '../../../context/cardinal'
import { ClickAwayListener } from '../../modal/clickAwayListener'

interface GifModalProps {
  modalPosition?: any
  modalFunction?: any
  closeModal: () => void
}

export function GifModal({ modalFunction, modalPosition, closeModal }: GifModalProps) {
  const { isMobileView } = useCardinalContext()
  let apikey = 'AIzaSyCT2_Gl6UrnU1cUTQbflDX7a_vApzwEU2g'
  let height: number = window.innerHeight
  let width: number = window.innerWidth
  let modalTop =
    height / 2 > modalPosition?.height ? `${modalPosition?.height + 40}px` : `${modalPosition?.height - 501}px`
  let modalLeft = width / 2 > modalPosition?.width ? `${modalPosition?.width}px` : `${modalPosition?.width - 461}px`

  function setModalGif(gif: string) {
    modalFunction(gif)
    closeModal()
  }

  return (
    <>
      <ClickAwayListener onClickAway={() => closeModal()} id="Gif-Picker">
        <div
          className="Gif-Modal-Container"
          style={{
            position: 'fixed',
            top: isMobileView ? '50px' : modalTop,
            left: isMobileView ? '0px' : modalLeft,
          }}>
          <>
            <div className="Exit-Gif-Modal" onClick={() => closeModal()}>
              <CloseIcon />
            </div>
            <GifPicker
              height={'100%'}
              width={'100%'}
              onGifClick={gif => {
                setModalGif(gif.videoUrl ?? gif.url)
              }}
              tenorApiKey={apikey}
              theme={Theme.DARK}
              autoFocusSearch={!isMobileView}
            />
          </>
        </div>
      </ClickAwayListener>
    </>
  )
}
